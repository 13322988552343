import { css } from "styled-components";

export const toastAnimation = css`
  @keyframes fadein {
    from {
      left: 0;
      opacity: 0;
    }
    to {
      left: 56px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadein {
    from {
      left: 0;
      opacity: 0;
    }
    to {
      left: 56px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      left: 56px;
      opacity: 1;
    }
    to {
      left: 0;
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      left: 56px;
      opacity: 1;
    }
    to {
      left: 0;
      opacity: 0;
    }
  }
`;
