import loadable from "@loadable/component";

export const mainView = loadable(() => import("views/MainView"));
export const authView = loadable(() => import("views/auth"));
export const dashboardView = loadable(() => import("views/dashboard"));
export const ruleView = loadable(() => import("views/rule"));
export const curriculumRuleView = loadable(() => import("views/rule/curriculum"));
export const questionView = loadable(() => import("views/question"));
export const curriculumQuestionView = loadable(() => import("views/question/curriculum"));
export const inspectionView = loadable(() => import("views/inspection"));
export const inspectionCurriculumView = loadable(() => import("views/inspection/curriculum"));

export const ruleUnitView = loadable(() => import("views/rule/unit"));
export const curriculumRuleUnitView = loadable(() => import("views/rule/curriculum/unit"));

export const inspectQuestionIdView = loadable(() => import("views/inspection/questionId"));
export const inspectCurriculumQuestionIdView = loadable(() => import("views/inspection/curriculum/questionId"));
export const inspectQuestionView = loadable(() => import("views/inspection/question"));
export const inspectCurriculumQuestionView = loadable(() => import("views/inspection/curriculum/question"));

export const questionStatsView = loadable(() => import("views/stats/question"));
export const textbookStatsView = loadable(() => import("views/stats/textbook"));
export const autoClassificationStatsView = loadable(() => import("views/stats/autoClassification"));
export const inspectionStatsView = loadable(() => import("views/stats/inspection"));
export const autoClassificationQuestionStatsView = loadable(() => import("views/stats/autoClassification/question"));
export const autoClassificationQuestionIdStatsView = loadable(() => import("views/stats/autoClassification/questionId"));
export const autoClassificationCurriculumQuestionStatsView = loadable(() => import("views/stats/autoClassification/curriculum/question"));
export const autoClassificationCurriculumQuestionIdStatsView = loadable(() => import("views/stats/autoClassification/curriculum/questionId"));

export const searchSimulationView = loadable(() => import("views/searchSimulation"));
export const bulkTestView = loadable(() => import("views/searchSimulation/bulk"));

export const error404View = loadable(() => import("views/Error404View"));
export const error500View = loadable(() => import("views/Error500View"));
