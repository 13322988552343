import Base from "repositories";
import { UserLogin, UserCreate } from "types/user/schema";

class Authentication extends Base {
  constructor() {
    super({ prefix: "/auth" });
  }

  async proofUser() {
    return this.axiosInstance.post(`/user/proof`);
  }

  async login(payload: UserLogin) {
    return this.axiosInstance.post(`/login`, payload);
  }

  async logout() {
    return this.axiosInstance.post(`/logout`)
  }

  async join(payload: UserCreate) {
    return this.axiosInstance.post('/user/join', payload)
  }

  async readUserMany() {
    return this.axiosInstance.get("/users")
  }
}

export default new Authentication();
