import {useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";

import {
  CLASSIFICATION_TYPE_ENG_KOR_MAPPING,
  ROUTERS,
  INSPECTION_STATUS_ENG_KOR_MAPPING,
  CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING
} from "constant";

import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";

import {engToKor, makePage, scoreToPercent} from "utils";


const QuestionItemModal = observer(() => {
  const {questionSearch, questionList} = useStores();
  const modalId = "questionItemModal";
  const [mouseOver, setMouseOver] = useState(false);

  const handleOkayClick = () => {};

  const question = useMemo(() => {
    return questionSearch.detail
  }, [questionSearch.detail])

  const unit = useMemo(() => {
    return question?._parent;
  }, [question?._parent])

  const handleQuestionIdClick = () => {
    window.location.replace(ROUTERS.inspection.textbookQuestionId(question!._id));
  }

  return (
    <MaruModal
      id={modalId}
      size="xl"
      customWidth="1184px"
      onOkayClick={handleOkayClick}
    >
      <Heading className="block-select" id="draggable-handle">
        <b>검수완료 기출문항 자세히 보기</b>
      </Heading>

      <Content>
        <Row>
          <Info>
            <Field>문항 ID</Field>
            <Value
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              onClick={handleQuestionIdClick}
              mouseOver={mouseOver}
              style={{ fontWeight: "500" }}
            >{question!._id}</Value>
          </Info>

          <Info>
            <Field>상태</Field>
            <Value>
              <InspectionStatusText status={question!.inspection_status}>
                {engToKor(INSPECTION_STATUS_ENG_KOR_MAPPING, question!.inspection_status)}
              </InspectionStatusText>
              {
                question?.classification_consistency &&
                <Badge>{engToKor(CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING, question!.classification_consistency)}</Badge>
              }
              {
                (unit && unit.checker_email) &&
                <em style={{ color: "#484848", opacity: "0.8" }}>{`(${unit.checker_email})`}</em>
              }
            </Value>
          </Info>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Info>
            <Field>시험지 ID</Field>
            <Value>
              {question!.pocCode}
            </Value>
          </Info>

          {
            question?.checker_type &&
            <Info>
              <Field>최종분류근거</Field>
              <Value>
                <em className="text-info" style={{ marginRight: "4px" }}>
                  {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
                </em>
                <em style={{ color: "#484848", opacity: "0.8" }}>
                  {question.score && `(${scoreToPercent(question.score)})`}
                </em>
              </Value>
            </Info>
          }
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Info>
            <Field>시험정보</Field>
            <Value>
              {questionList.questionInfo(question)}
            </Value>
          </Info>

          <Info>
            <Field>페이지</Field>
            <Value style={{ fontWeight: "500" }}>
              {makePage(question?.page)}
            </Value>
          </Info>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Info style={{ margin: "0" }}>
            <Field>단원 ID</Field>
            <Value>
              {question!.unitCode}
            </Value>
          </Info>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Info style={{ margin: "0" }}>
            <Field>단원명</Field>
            <Value>
              {`${question?.textbookSb} `}{`${question!.unit_name}`}
            </Value>
          </Info>
        </Row>

        <LevelsWrapper className="scrollbar">
          <Levels level1={question!.level1} level2={question!.level2} level3={question!.level3} />
        </LevelsWrapper>
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > b {
    margin-left: 8px;
    font-weight: bold;
  }
`;

const Content = styled.div`
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  padding: 0 16px;
  min-height: 19px;
`;
const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: 19px;
  
  flex: 1;
  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  display: flex;
  align-items: center;

  flex-shrink: 0;
  width: 96px;
  min-height: 19px;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div<{ mouseOver?: boolean }>`
  min-height: 19px;
  
  color: ${(props) => props.theme.text.BLACK};

  text-decoration: ${(props) => (props.mouseOver ? "underline" : "none")};
  line-height: normal;
  cursor: ${(props) => (props.mouseOver ? "pointer" : "default")};

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }
`;
const Badge = styled.em`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const LevelsWrapper = styled.div`
  margin-top: 16px;
  max-height: 600px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.MAIN};
`;

export default QuestionItemModal;
