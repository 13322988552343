import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";

import InfoItem from "components/InfoItem";

const UnitModalContent = observer(() => {
  const { classificationResult } = useStores();

  const unit = useMemo(() => {
    return classificationResult.detail?.model;
  }, [classificationResult.detail?.model]);

  const main = useMemo(() => {
    return unit && unit.main_content;
  }, [unit]);

  const sub = useMemo(() => {
    return unit && unit.sub_content;
  }, [unit]);

  const exam = useMemo(() => {
    return unit && unit.exam_content;
  }, [unit]);

  return (
    <UnitModalContentStyle>
      {
        unit &&
        <>
          <Row>
            <InfoItem field="출판사명" value={unit!.publisher} />
          </Row>

          <Row>
            <InfoItem field="교과서명" value={unit!.textbook} />
          </Row>

          <Row>
            <InfoItem field="단원 ID" value={unit!.unitCode} />
          </Row>

          <Row>
            <InfoItem field="단원명" value={unit!.unit_name} />
          </Row>

          <Row>
            <InfoItem field="페이지" value={`P${unit!.page}`} bold />
          </Row>
        </>
      }

      <InnerPanel className="scrollbar">
        {main && (
          <>
            <Heading>본문</Heading>
            <MainContentList>
              <MainContent>{main.map((item: string) => <ContentItem>{item}</ContentItem>)}</MainContent>
            </MainContentList>
          </>
        )}
        {sub && (
          <>
            <Heading>부가 설명글</Heading>
            <SubContentList>
              <SubContent>{sub.map((item: string) => <ContentItem>{item}</ContentItem>)}</SubContent>
            </SubContentList>
          </>
        )}
        {exam && (
          <>
            <Heading>예제</Heading>
            <ExamContentList>
              <ExamContent>{exam.map((item: string) => <ContentItem>{item}</ContentItem>)}</ExamContent>
            </ExamContentList>
          </>
        )}
      </InnerPanel>
    </UnitModalContentStyle>
  );
});

const UnitModalContentStyle = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 16px;
  line-height: 1rem;
`;

const InnerPanel = styled.div`
  height: 420px;
  margin-top: 16px;

  font-size: 13px;
  color: ${(props) => props.theme.text.BLACK};
  line-height: 1.4rem;
`;

const Heading = styled.h1`
  height: 32px;
  padding-top: 4px;

  border-bottom: 2px solid ${(props) => props.theme.border.DIVIDER};

  font-size: 14px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
  line-height: normal;
`;
const MainContentList = styled.ul``;
const MainContent = styled.li`
  margin-bottom: 8px;
  padding: 16px;

  border: 1px solid ${(props) => props.theme.border.MAIN};
  background: ${(props) => props.theme.background.HOVER};

  font-size: 13px;
  color: ${(props) => props.theme.text.MAIN};
  text-align: justify;
  line-height: 1.4rem;
`;
const SubContentList = styled.ul``;
const SubContent = styled(MainContent)``;
const ContentItem = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin: 0;
  }
`;

const ExamContentList = styled.ul``;
const ExamContent = styled(MainContent)``;

export default UnitModalContent;
