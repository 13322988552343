import styled from 'styled-components'

interface RadioProps {
  checked: boolean;
  disabled?: boolean;
  onClick?: () => void;
  size?: "sm" | "md";
}

const Radio = ({ checked, disabled = false, onClick, size = "md" }: RadioProps) => {
  //* Event methods
  const handleRadioClick = () => {
    if (onClick) onClick();
  }

  return (
    <RadioStyle checked={checked} onClick={handleRadioClick} size={size} disabled={disabled} >
      {!disabled && checked && <Fill size={size} />}
    </RadioStyle>
  )
}

const RadioStyle = styled.div<{ checked: boolean, size: "sm" | "md", disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.size === "md" ? "24px" : "20px" };
  height: ${(props) => props.size === "md" ? "24px" : "20px" };

  border-radius: 9999px;
  border: 1px solid ${(props) => !props.disabled && props.checked ? props.theme.border.FOCUS : "#b2b2b2"};

  cursor: ${(props) => props.disabled ? "not-allowed" : "pointer"};

  &:hover {
    background: ${(props) => props.disabled ? "none" : "#f8f8f8"};
    box-shadow: ${(props) => props.disabled ? "none" : "0 0 2px 4px #f8f8f8"};
  }
`;
const Fill = styled.div<{ size: "sm" | "md" }>`
  width: ${(props) => props.size === "md" ? "16px" : "12px" };
  height: ${(props) => props.size === "md" ? "16px" : "12px" };

  border-radius: 9999px;
  background: ${(props) => props.theme.button.INFO};
`;

export default Radio