import { css } from "styled-components";

export const datepickerStyle = css`
  .react-datepicker__header {
    border: none;
    background: none;
  }

  .react-datepicker {
    border: none;
    background: none;
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 9999px;
    }
  }

  .react-datepicker__day--selected {
    border-radius: 9999px;
    background: rgb(220, 220, 220);
    color: black;

    &:hover {
      background: rgb(240, 240, 240);
    }
  }

  .react-datepicker__day--keyboard-selected {
    background: none;
    color: black;

    &:hover {
      background: rgb(240, 240, 240);
      color: black;
    }
  }
`;
