import moment from "moment";
import {EngKorMapping} from "types/common";

export const toISOStringCustom = (date: Date) => {
  const offset = date.getTimezoneOffset() * 60000;
  const dateOffset = new Date(date.getTime() - offset);
  return dateOffset.toISOString().substring(0, 10).replaceAll("-", ".");
};

export const isInRightRange = (number: number, start: number, end: number) => {
  return start !== end && start <= number && number <= end;
}

export const isInPages = (number: number, pages: number[]) => {
  return pages.includes(number);
}

export const makeSelectOptions = (data: string[]) => {
  if (!data.length) return [];
  return data.map((s) => {
    return { value: s, label: s };
  });
};

export const getCookie = (name: string): string | null => {
  const nameLenPlus = name.length + 1;
  return (
    document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((cookie) => {
        return cookie.substring(0, nameLenPlus) === `${name}=`;
      })
      .map((cookie) => {
        return decodeURIComponent(cookie.substring(nameLenPlus));
      })[0] || null
  );
};

export const utcToLocal = (date?: string, format?: string) => {
  if (!date) return "";
  return moment
    .utc(date)
    .local()
    .format(format || "yyyy.MM.DD");
};

export const removeDot = (data: any, remove_terms: string[]) => {
  const result = Object()
  Object.entries(data).map(([key, value]: [string, any]) => {
    let k = key
    remove_terms.forEach(term => {
      if (key.includes(term))
        k = k.replace(term, '')
    })
    return result[k] = value;
  })
  return result;
}

export const engToKor = (mappings: EngKorMapping[], key?: string, engToKor: boolean = true) => {
  if (!key) return undefined;
  const mapping = mappings.filter(m => {
    return engToKor ? m.eng === key : m.kor === key
  })
  if (!mapping.length) return undefined;
  return engToKor? mapping[0].kor: mapping[0].eng;
}

export const removeNone = (object: any) => {
  Object.entries(object).forEach(([k, v]) => {
    if (!v) delete object[k]
  })
}

export const makeSelectOption = (data: any) => {
  if (!data) return undefined;
  return { value: data, label: String(data) };
};

export const removeHTMLTagsWithHighlights = (string?: string) => {
  if (!string) return "";
  return string
    .replace(/(?!<em>|<\/em>)<\/?[^>]+(>|$)/g, "")
    .replace(/^"+|"+$/g, "")
    .replaceAll("&lt;", "<")
    .replaceAll("&gt;", ">")
    .replaceAll("&nbsp;", "");
};

export const removeHTMLTags = (string?: string) => {
  if (!string) return "";
  let myHTML = new DOMParser()
    .parseFromString(string, 'text/html');
  return myHTML.body.textContent? myHTML.body.textContent.replace(/^"+|"+$/g, "") :'';
}



export const makePage = (page: number | number[] | undefined) => {
  if (!page) return ""
  if (typeof page === "number") return `P${page}`
  else if (page.length === 1) return `P${page[0]}`
  else return `P${page[0]} ~ P${page[page.length - 1]}`
}

export const validateRegex = (regex: string) => {
  try {
    new RegExp(regex);
  } catch(e) {
    return false;
  }
  return true
}

export const fixForMathJax = (text: string) => {
  return text.replaceAll("\\\\", "\\").replaceAll("\\n", " ");
}

export const subString = (content: string) => {
  return content.length > 300? content.substring(0, 300) + "...": content;
}

export const scoreToPercent = (score?: number, toFixed: number = 2) => {
  if (score !== 0 && !score) return undefined
  return (score * 100 ).toFixed(toFixed)
}

export const stringToDate = (str: string) => {
  return str.split(" ")[0];
}