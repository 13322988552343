import {makeAutoObservable} from "mobx";
import repository from "repositories/Question"
import {StoreState} from "types/common";
import {Question} from "types/question/model";


export default class ActiveQuestion {
  state: StoreState = "none";
  storageId: string = "questionId";
  question?: Question;

  constructor() {
    makeAutoObservable(this);
  }

  async getQuestionId() {
    return localStorage.getItem(this.storageId);
  }

  async set(question?: Question, keep?: boolean) {
    this.state = "pending";
    if (question) {
      localStorage.setItem(this.storageId, question._id);
      if (keep) {
        this.question = question;
      } else {
        const response = await repository.read(question._id);
        this.question = response.data;
      }
    } else {
      localStorage.removeItem(this.storageId);
      this.question = undefined;
    }
    this.state = "done";
  }

  getRank1() {
    if (!this.question!.classification_result) return undefined;
    const _rank = [];
    _rank.push(...this.question!.classification_result.rule);
    _rank.push(...this.question!.classification_result.question);
    _rank.push(...this.question!.classification_result.unit);
    return _rank[0];
  }

  async clear() {
    this.state = "none";
    this.question = undefined;
  }
}