export const warning = {
  notExistEmail: "존재하지 않는 이메일입니다.",
  notCorrectPassword: "비밀번호가 맞지 않습니다.",
  policyAccessDenied: "정책에 의해 거부되었습니다.",
  duplicateEmail: "이미 존재하는 이메일입니다.",
  required: "필수 입력값을 입력해 주세요.",
}

export const error = {
  notExistUser: "User is not exist",
  wrongPassword: "Wrong password",
  duplicateUser: "User is already exist",
  invalidEmail: "Invalid user email",
  invalidUser: "Invalid user",
  policyAccessDenied: "Access is denied by policy",
  jobProcess: "The \"PUT, CREATE\" method cannot be processed while an operation is in progress.",
  regexTooComplex: "Failed to create query: Determinizing query would require more than 10000 effort."
}

export const clientError = {
  pageRange: "조회 중인 단원의 범위를 넘어서는 페이지 값은 입력할 수 없습니다.",
  wrongPageInput: "페이지를 다시 입력해주세요.",
  invalidRegex: "유효하지 않은 정규표현식입니다.",
}

export const toastMessage = {
  success: {
    requestInspection: "검수요청이 완료되었습니다.",
    checkerIsMe: "할당이 완료되었습니다.",
    saveInspection: "검수 결과가 저장되었습니다.",
    saveErrorReport: "문항 오류 상태가 저장되었습니다.",
    copyQuestionId: "문항 ID값이 클립보드에 복사되었습니다.",
    copyMainQuestionId: "기준 문항 ID값이 클립보드에 복사되었습니다.",
    copyUnitId: "단원 ID값이 클립보드에 복사되었습니다.",
    copyCurriculumId: "표준커리큘럼 ID값이 클립보드에 복사되었습니다.",
    applyRule: "선택한 문항에 분류규칙을 적용한 후 분류규칙을 저장했습니다.",
    saveRule: "분류규칙이 저장되었습니다.",
    refreshAutoClassificationList: "자동분류 결과 문항 목록으로 새로고침 되었습니다.",
    downloadExcel: "엑셀 파일이 다운로드 되었습니다.",
    downloadJson: "JSON 파일이 다운로드 되었습니다.",
    saveTest: "테스트가 등록되었습니다."
  },
  fail: {
    featureIsUnderDevelop: "개발중인 기능입니다.",
    cancelInspection: "검수가 취소되었습니다.",
    cancelRule: "분류규칙 저장이 취소되었습니다.",
    deleteRule: "분류규칙이 삭제되었습니다.",
    onlyCheckerEnterUnit: "검수자만 진입할 수 있습니다. 검수 할당을 해주세요.",
    noSimulatedResult: "선택된 문항이 없습니다. 다른 문제를 대상으로 분류규칙을 적용해 보세요.",
    jobProcessError: "문항 분류 혹은 문항 상태 변경중입니다. 잠시 후에 시도해주세요.",
    required: "필수 입력값을 입력해 주세요.",
  },
  postpone: {
    saveInspection: "검수 결과가 저장되었습니다.",
  },
  info: {
  }
}

export const fileMessage = {
  idle: "테스트 케이스 엑셀 파일을 끌어다 놓거나 클릭하여 업로드하세요.",
  rejectFile: "올바른 파일 형식이 아닙니다. .xls과 .xlsx 파일을 지원합니다.",
  acceptFile: "여기에 놓으세요.",
  tooLargeSize: "파일의 크기가 너무 큽니다. 200kb 이하의 파일만 업로드가 가능합니다."
}
