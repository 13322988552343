import React, { useState, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { MathJax } from "better-react-mathjax";

import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useStores from "hooks/useStores";

import { Question } from "types/question/model";
import { engToKor, removeHTMLTags, fixForMathJax, subString } from "utils";
import { toastMessage } from "messages";
import { INSPECTION_STATUS_ENG_KOR_MAPPING } from "constant";

import InspectionStatusText from "components/InspectionStatusText";

interface SimulationItemProps {
  question: Question;
}

const SimulationItem = observer(({ question }: SimulationItemProps) => {
  const { mathRuleSimulation } = useStores();
  const [mouseOver, setMouseOver] = useState(false);
  const modal = useModal();
  const toast = useToast();

  const level1 = useMemo(() => removeHTMLTags(question?.level1), [question]);
  const level2 = useMemo(() => removeHTMLTags(question?.level2), [question]);
  const level3 = useMemo(() => removeHTMLTags(question?.level3), [question]);

  const snippet = useMemo(() => {
    const _result = [];
    if (level1) _result.push(...["(대발문)", level1, "</br>"]);
    if (level3) _result.push(...["(문제)", level3, "</br>"]);
    if (level2) _result.push(...["(지문)", level2]);
    const result = fixForMathJax(_result.join(" "));
    return subString(result);
  }, [level1, level2, level3]);

  const inspectionStatus = useMemo(() => {
    return question.classification_status === "completed" ? engToKor(INSPECTION_STATUS_ENG_KOR_MAPPING, question.inspection_status) : "미분류";
  }, [question.classification_status, question.inspection_status]);

  const handleSimulationItemClick = async () => {
    await mathRuleSimulation.setDetail(question);
    modal.open("ruleAppliedCurriculumQuestionModal");
  };

  const handleQuestionIdClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(question._id);
    toast.fireToast({
      type: "success",
      content: toastMessage.success.copyQuestionId,
      duration: 5000,
    });
  };

  return (
    <SimulationItemStyle onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)} onClick={handleSimulationItemClick}>
      <MathJax>
        <Row>
          <Cell
            className="ellipsis"
            data-tip
            data-for={question._id}
            style={{
              display: "inline-block",
              width: "120px",
              fontWeight: "500",
              textDecoration: mouseOver ? "underline" : "none",
              cursor: "pointer",
              marginRight: "8px",
            }}
            onClick={handleQuestionIdClick}
          >
            {question._id}
          </Cell>
          <IdTooltip delayShow={600} id={question._id} effect="solid">
            <TooltipContent>{question._id}</TooltipContent>
          </IdTooltip>
          <Cell style={{ flex: "1", marginRight: "8px" }}>{fixForMathJax(removeHTMLTags(question.body))}</Cell>
          <Cell style={{ width: "80px" }}>
            <InspectionStatusText status={question.inspection_status}>{inspectionStatus}</InspectionStatusText>
          </Cell>
        </Row>

        <Unit>
          <Cell style={{ width: "120px" }}></Cell>
          <Cell style={{ display: "block", flex: "1" }}>
            {question.unit_name}
          </Cell>
          <Cell style={{ width: "80px" }}></Cell>
        </Unit>

        <Content dangerouslySetInnerHTML={{ __html: snippet }} />
      </MathJax>
    </SimulationItemStyle>
  );
});

const SimulationItemStyle = styled.div`
  margin-bottom: 4px;
  padding: 16px 24px;

  border: 1px solid ${(props) => props.theme.border.DIM};
  background: ${(props) => props.theme.background.PANEL_WHITE};

  line-height: normal;
  font-size: 13px;
  color: ${(props) => props.theme.text.BALCK};

  &:hover {
    border: 1px solid ${(props) => props.theme.border.DIVIDER};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const IdTooltip = styled(ReactTooltip)`
  padding: 8px !important;

  border-radius: 4px !important;
  background: #333 !important;
`;
const TooltipContent = styled.div`
  padding: 4px 0;

  line-height: normal;
  font-size: 12px;
  color: ${(props) => props.theme.text.PLACEHOLDER};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Cell = styled.div`
  flex-shrink: 0;
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: 8px;

  color: ${(props) => props.theme.text.GREY};
`;

const Content = styled.div`
  margin-top: 8px;
  padding: 16px;

  border: 1px solid #f2f2f2;
  background: #fbfbfb;

  text-align: justify;
  line-height: 1.4rem;
  font-size: 13px;
  color: ${(props) => props.theme.text.MAIN};
`;

export default SimulationItem;
