import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

import ModalAddon from "components/ModalAddon";

const PostponeEachInspectionModal = observer(() => {
  const {
    similarQuestionList,
    unitSelect,
    questionList,
    activeUnit,
    activeQuestion
  } = useStores();

  const modalId = "postponeEachInspectionModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async () => {
    if (mode === 3) return;
    if (!unitSelect.selected) return;
    if (
      await handler.handle(
        questionList.updateMany(
          {
            question_ids: similarQuestionList.questionStorage.map(q => q._id),
            values: {
              inspection_status: "hold"
            }
          }
        )
      )
    ) return;
    toast.fireToast({
      type: "postpone",
      content: toastMessage.postpone.saveInspection,
      duration: 5000,
    });
    await activeUnit.setUnitCode(unitSelect.selected!.unitCode)
    await unitSelect.clear();
    await similarQuestionList.clear();
    await activeQuestion.clear();
    await activeUnit.clear();
  };

  const mode = useMemo(() => {
    if (similarQuestionList.questionStorage.every(u => u.inspection_status === "waiting"))
      return 1
    if (similarQuestionList.questionStorage.every(u => u.inspection_status === "hold"))
      return 3
    return 2
    // eslint-disable-next-line
  }, [similarQuestionList.questionStorage.length])

  return (
    <MaruModal
      id={modalId}
      title="검수보류"
      buttonText="확인"
      warning={
        mode === 2 && (
          <>
            보류 후에는 결과
            <em style={{ color: "#e9397a" }}> 복원이 불가</em>합니다.
          </>
        )
      }
      withCancel={mode !== 3}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {mode === 1 && <OnlyUnchecked count={similarQuestionList.questionStorage.length}/>}
        {mode === 2 && <Mixed count={similarQuestionList.questionStorage.length}/>}
        {mode === 3 && <OnlyChecked />}
      </ModalInnerPanel>

      <ModalAddon mode="postpone" />
    </MaruModal>
  );
});

interface PostInspectionProps {
  count: number;
}

const OnlyUnchecked = ({count}: PostInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b>을 <b>검수보류</b> 처리합니다.
  </Paragraph>
);
const Mixed = ({count}: PostInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b> 중 <em className="text-success">검수완료</em> 처리한
    문항이 있습니다. <br />
    이대로 검수보류로 상태를 변경하시겠습니까?
  </Paragraph>
);
const OnlyChecked = () => (
  <Paragraph>
    이미 <b>검수보류</b> 처리된 문항입니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO};
  }
`;

export default PostponeEachInspectionModal;
