import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";

import QuestionModalContent from "views/inspection/curriculum/questionId/QuestionModalContent";

const AutoClassifiedCurriculumModal = observer(() => {
  const modalId = "autoClassifiedCurriculumModal";

  return (
    <MaruModal id={modalId} size="xl" customWidth="1184px">
      <Heading className="block-select" id="draggable-handle">
        자동분류 결과
        <em>검수완료 기출문항 자세히 보기</em>
      </Heading>

      <Content>
        <QuestionModalContent />
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Content = styled.div`
  border-radius: 4px;
`;

export default AutoClassifiedCurriculumModal;
