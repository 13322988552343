import { makeAutoObservable } from "mobx";

import repository from "repositories/Stats";
import { StoreState } from "types/common";
import { ClassificationStatusResponse } from "types/stats/schema";

export default class ClassificationStatus {
  state: StoreState = "none";
  data?: ClassificationStatusResponse;

  constructor() {
    makeAutoObservable(this);
  }

  get totalStats() {
    if (this.data) {
      const completed = this.data.total.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.total.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.total.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.total.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.total.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.total.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      const total = completedCount + failedCount + noneCount;
      const newTotal = completedCount + inspectedCount + holdCount + waitingCount;

      return {
        total,
        completedCount,
        completeRate: ((completedCount / total) * 100).toFixed(2),
        failedCount,
        failedRate: ((failedCount / total) * 100).toFixed(2),
        noneCount,
        noneRate: ((noneCount / total) * 100).toFixed(2),
        inspectedCount,
        inspectedRate: ((inspectedCount / newTotal) * 100).toFixed(2),
        holdCount,
        holdRate: ((holdCount / newTotal) * 100).toFixed(2),
        waitingCount,
        waitingRate: ((waitingCount / newTotal) * 100).toFixed(2),
      };
    }

    return {
      total: 0,
      completedCount: 0,
      completeRate: 0,
      failedCount: 0,
      failedRate: 0,
      noneCount: 0,
      noneRate: 0,
      inspectedCount: 0,
      inspectedRate: 0,
      holdCount: 0,
      holdRate: 0,
      waitingCount: 0,
      waitingRate: 0,
    };
  }

  get koreanStats() {
    if (this.data) {
      const completed = this.data.국어.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.국어.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.국어.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.국어.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.국어.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.국어.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      return {
        total: completedCount + failedCount + noneCount,
        completedCount,
        failedCount,
        noneCount,
        inspectedCount,
        holdCount,
        waitingCount,
      };
    }

    return {
      total: 0,
      completedCount: 0,
      failedCount: 0,
      noneCount: 0,
      inspectedCount: 0,
      holdCount: 0,
      waitingCount: 0,
    };
  }

  get englishStats() {
    if (this.data) {
      const completed = this.data.영어.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.영어.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.영어.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.영어.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.영어.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.영어.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      return {
        total: completedCount + failedCount + noneCount,
        completedCount,
        failedCount,
        noneCount,
        inspectedCount,
        holdCount,
        waitingCount,
      };
    }

    return {
      total: 0,
      completedCount: 0,
      failedCount: 0,
      noneCount: 0,
      inspectedCount: 0,
      holdCount: 0,
      waitingCount: 0,
    };
  }

  get mathStats() {
    if (this.data) {
      const completed = this.data.수학.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.수학.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.수학.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.수학.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.수학.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.수학.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      return {
        total: completedCount + failedCount + noneCount,
        completedCount,
        failedCount,
        noneCount,
        inspectedCount,
        holdCount,
        waitingCount,
      };
    }

    return {
      total: 0,
      completedCount: 0,
      failedCount: 0,
      noneCount: 0,
      inspectedCount: 0,
      holdCount: 0,
      waitingCount: 0,
    };
  }

  get scienceStats() {
    if (this.data) {
      const completed = this.data.과학.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.과학.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.과학.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.과학.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.과학.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.과학.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      return {
        total: completedCount + failedCount + noneCount,
        completedCount,
        failedCount,
        noneCount,
        inspectedCount,
        holdCount,
        waitingCount,
      };
    }

    return {
      total: 0,
      completedCount: 0,
      failedCount: 0,
      noneCount: 0,
      inspectedCount: 0,
      holdCount: 0,
      waitingCount: 0,
    };
  }

  get societyStats() {
    if (this.data) {
      const completed = this.data.사회.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.사회.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.사회.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.사회.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.사회.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.사회.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      return {
        total: completedCount + failedCount + noneCount,
        completedCount,
        failedCount,
        noneCount,
        inspectedCount,
        holdCount,
        waitingCount,
      };
    }

    return {
      total: 0,
      completedCount: 0,
      failedCount: 0,
      noneCount: 0,
      inspectedCount: 0,
      holdCount: 0,
      waitingCount: 0,
    };
  }

  get historyStats() {
    if (this.data) {
      const completed = this.data.역사.classification.find((item) => item.key === "completed");
      const completedCount = completed ? completed.doc_count : 0;

      const failed = this.data.역사.classification.find((item) => item.key === "failed");
      const failedCount = failed ? failed.doc_count : 0;

      const none = this.data.역사.classification.find((item) => item.key === "waiting");
      const noneCount = none ? none.doc_count : 0;

      const inspected = this.data.역사.inspection.find((item) => item.key === "inspected");
      const inspectedCount = inspected ? inspected.doc_count : 0;

      const hold = this.data.역사.inspection.find((item) => item.key === "hold");
      const holdCount = hold ? hold.doc_count : 0;

      const waiting = this.data.역사.inspection.find((item) => item.key === "waiting");
      const waitingCount = waiting ? waiting.doc_count : 0;

      return {
        total: completedCount + failedCount + noneCount,
        completedCount,
        failedCount,
        noneCount,
        inspectedCount,
        holdCount,
        waitingCount,
      };
    }

    return {
      total: 0,
      completedCount: 0,
      failedCount: 0,
      noneCount: 0,
      inspectedCount: 0,
      holdCount: 0,
      waitingCount: 0,
    };
  }

  async getClassificationStatus() {
    this.state = "pending";
    const response = await repository.getClassificationStatus();
    this.data = response.data.response;
    this.state = "done";
  }

  clear() {
    this.data = undefined;
    this.state = "none";
  }
}
