import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";

import {makePage} from "utils";

const RuleModalContent = observer(() => {
  const { classificationResult } = useStores();

  const rule = useMemo(() => {
    return classificationResult.detail?.model;
  }, [classificationResult.detail?.model]);

  return (
    <RuleModalContentStyle>
      <Row>
        <Item>
          <Field style={{ width: "120px", color: "#666" }}>분류규칙 ID</Field>
          <Value>{rule.id}</Value>
        </Item>
      </Row>

      <Row style={{ alignItems: "flex-start", marginTop: "8px" }}>
        <Item style={{ alignItems: "flex-start" }}>
          <Field style={{ width: "120px", color: "#666" }}>단원명</Field>
          <Value style={{ maxWidth: "500px" }}>
            {rule.unit_name}
          </Value>
        </Item>

        <Item style={{ marginLeft: "32px" }}>
          {
            classificationResult.detail!.page &&
            <>
              <Field style={{ color: "#666", width: "auto", marginRight: "4px" }}>
                페이지
              </Field>
              <Value style={{ fontWeight: "500" }}>{makePage(classificationResult.detail!.page)}</Value>
            </>
          }
        </Item>
      </Row>

      <InnerPanel>
        {
          rule.include_and &&
          <Item style={{ marginBottom: "8px" }}>
            <Field>
              키워드 <em className="text-info">모두</em> 포함
            </Field>
            <Value>{rule.include_and}</Value>
          </Item>
        }
        {
          rule.include_strict &&
          <Item style={{ marginBottom: "8px" }}>
            <Field>
              키워드 <em className="text-info">그대로</em> 포함
            </Field>
            <Value>{rule.include_strict}</Value>
          </Item>
        }
        {
          rule.include_or &&
          <Item style={{ marginBottom: "8px" }}>
            <Field>
              키워드 <em className="text-info">한 개 이상</em> 포함
            </Field>
            <Value>{rule.include_or}</Value>
          </Item>
        }
        {
          rule.exclude &&
          <Item>
            <Field>
              키워드 <em className="text-danger">제외</em>
            </Field>
            <Value>{rule.exclude}</Value>
          </Item>

        }
        {
          rule.regex &&
          <Item>
            <Field>
              정규표현식
            </Field>
            <Value>{rule.regex}</Value>
          </Item>
        }
      </InnerPanel>
    </RuleModalContentStyle>
  );
});

const RuleModalContentStyle = styled.div``;
const Row = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;

  font-size: 13px;
  color: ${(props) => props.theme.text.BLACK};
  line-height: 1rem;
`;
const Field = styled.div`
  width: 148px;
  flex-shrink: 0;

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }
`;
const Value = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InnerPanel = styled.div`
  margin-top: 16px;
  padding: 16px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

export default RuleModalContent;
