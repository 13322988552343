import {makeAutoObservable} from "mobx";
import repository from "repositories/BlukTest"
import {StoreState} from "types/common";
import {
  BulkTestSortingOptions,
} from "types/bulk-test/schema";
import {BulkTest} from "types/bulk-test/model";


export default class RuleList {
  state: StoreState = "none";
  downloadState: StoreState = "none";
  tests: BulkTest[] = [];
  test?: BulkTest;
  sorting: BulkTestSortingOptions = {
    id: "desc"
  };
  page: number = 1;
  size: number = 20;
  total: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany()
  {
    this.state = "pending";
    try {
      const response = await repository.readMany(
        {
          page: this.page,
          size: this.size,
          sorting: this.sorting
        }
      )
      this.total = response.data.total;
      this.tests = response.data.tests;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(testName: string, fromData: FormData) {
    try {
      const response = await repository.create(testName, fromData);
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async reTest(){
    try {
      const response = await repository.test(this.test!.id);
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async download() {
    this.downloadState = "pending";
    try {
      const response = await repository.download(this.test!.id);
      this.downloadState = "none"
      return response;
    } catch (e) {
      this.downloadState = "error";
      throw e;
    }
  }

  async setSorting(field: string) {
    this.sorting[field] = this.sorting[field]? this.sorting[field] === "desc" ? "asc" : undefined: "desc"
  }

  getSorting(field: string) {
    return this.sorting[field];
  }

  async setTest(test?: BulkTest) {
    this.test = test;
  }

  async clearTest() {
    this.test = undefined;
  }

  async setPage(page: number) {
    this.page = page;
  }

  async setSize(size: number) {
    this.page = 1;
    this.size = size;
  }

  async clear() {
    this.state = "none";
    this.tests = [];
    this.sorting = {
      id: "desc"
    }
    this.downloadState = "none";
    this.test = undefined;
    this.page = 1;
    this.size = 20;
    this.total = 0;
  }

  async search() {
    this.state = "none";
  }
}
