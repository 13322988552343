import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";
import {useErrorHandler} from "hooks/useErrorHandler";

import {QuestionReadManyRequest} from "types/question/schema";

const AutoClassificationModal = observer(() => {
  const {job, questionList} = useStores();
  const handler = useErrorHandler();
  const modalId = "autoClassificationModal";

  const isSelected = useMemo(() => {
    return !!questionList.questionStorage.length
  }, [questionList.questionStorage.length])

  const handleOkayClick = async () => {
    await job.setType("CFA")
    const condition: QuestionReadManyRequest = questionList.jobPayload()
    await job.setCondition(condition)
    if (
      await handler.handle(job.create())
    ) return;
    await questionList.clearQuestionStorage();
    await clear();
  };

  const clear = async () => {
    await job.clearCreatePayload()
  }

  return (
    <MaruModal
      id={modalId}
      title="자동분류"
      withCancel
      onClose={clear}
      onOkayClick={handleOkayClick}
      warning={
        <>
          {"그대로 진행하시겠습니까?"}
        </>
      }
    >
      <ModalInnerPanel>
        <Paragraph>
          자동분류가 진행되는 동안에는 <em className="text-danger">다음 작업이 불가</em>합니다.
          <div>
            · 다른 문항의 자동분류 <br />
            · 검수요청 및 검수처리 <br />
            · 분류규칙 적용 <br />
          </div>
        </Paragraph>
      </ModalInnerPanel>

      <Description>
        {isSelected?`선택한 ${questionList.questionStorage.length}`:`조회된 ${questionList.total}`}건의 문항을 자동분류합니다.<br />
        검수대기 문항이 포함되어 있는 경우, <br />
        검수자가 작업 중인 리스트가 변경될 수 있습니다.
      </Description>
    </MaruModal>
  );
});

const Description = styled.div`
  margin-top: 16px;

  line-height: 1.125rem;
  font-size: 12px;
  color: ${(props) => props.theme.text.BLACK};
`;

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 12px;
  color: #666666;
  text-align: center;
  line-height: 1.25rem;

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > div {
    margin-top: 8px;
    color: ${(props) => props.theme.text.FOCUS};
  }
`;

export default AutoClassificationModal;