import { useState, useRef, useCallback, useMemo } from "react";
import styled from "styled-components";

import { useOutsideClick } from "hooks/useOutsideClick";
import { useClientRect } from "hooks/useClientRect";

import { ReactComponent as ListUpIcon } from "assets/icons/icon_list_up.svg";
import { ReactComponent as ListDownIcon } from "assets/icons/icon_list_down.svg";

import { DropdownItemType } from "types/common";

const MARGIN_TOP = 2;

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  options: DropdownItemType[];
  includeAllOption?: boolean;
  value?: DropdownItemType;
  handleSelect?: (selectedItem?: DropdownItemType) => void;
  maxHeight?: number;
  listItemLengthLimit?: number;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  parentElement?: string;
  dropTop?: boolean;
  wideMode?: boolean;
}

const Dropdown = ({
  options,
  includeAllOption,
  value,
  handleSelect,
  maxHeight = 200,
  listItemLengthLimit,
  placeholder = "선택해주세요.",
  className,
  error,
  errorMessage,
  parentElement,
  dropTop = false,
  wideMode = false,
  ...props
}: DropdownProps) => {
  const [showList, setShowList] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);
  const [offsetLeft, setOffsetLeft] = useState(0);

  const [dropdownRect, dropdownRef] = useClientRect();
  const ref = useRef(null);
  const listRef = useCallback((node: HTMLUListElement) => {
    if (node && node.parentElement && node.parentElement.parentElement) {
      setOffsetTop(node.parentElement.parentElement.offsetTop);
      setOffsetLeft(node.parentElement.parentElement.offsetLeft);
    }
    // eslint-disable-next-line
  }, []);

  useOutsideClick(ref, () => setShowList(false));

  const isListOverflow = useMemo(() => {
    const parent = parentElement ? document.querySelector(parentElement) : null;
    
    if (parent) {
      return dropdownRect && offsetTop + MARGIN_TOP + dropdownRect.height + maxHeight > parent.scrollHeight;
    } else {
      return dropdownRect && offsetTop + MARGIN_TOP + dropdownRect.height + maxHeight > window.innerHeight;
    }
  }, [offsetTop, dropdownRect, parentElement, maxHeight]);

  const isListOverflowX = useMemo(() => offsetLeft + 320 > window.innerWidth, [offsetLeft]);

  const handleSelectedItemClick = () => {
    setShowList(!showList);
  };

  const handleItemClick = (item?: DropdownItemType) => {
    setShowList(false);
    handleSelect && handleSelect(item);
  };

  return (
    <DropdownStyle {...props} ref={dropdownRef} onClick={handleSelectedItemClick} className={`${className} block-select`}>
      <Wrapper ref={ref}>
        <SelectedItem selected={value ? value.value : undefined} showList={showList}>
          <Text className="ellipsis">{value ? value.label : placeholder}</Text>

          {showList ? <ListUpIcon style={{ flexShrink: "0" }} /> : <ListDownIcon style={{ flexShrink: "0" }} />}
        </SelectedItem>

        <List show={showList} ref={listRef} windowOverflow={isListOverflow} windowOverflowX={isListOverflowX} dropTop={dropTop} className="scrollbar">
          {includeAllOption && !!options.length && <Item onClick={() => handleItemClick(undefined)} wideMode={wideMode}>전체선택</Item>}
          {options.map((option) => (
            <Item key={option.label} onClick={() => handleItemClick(option)} wideMode={wideMode}>
              {listItemLengthLimit && option.label.length > listItemLengthLimit
                ? `${option.label.substring(0, listItemLengthLimit)}...`
                : option.label}
            </Item>
          ))}

          {options.length === 0 && <Item style={{ color: "#999", cursor: "default" }} wideMode={wideMode}>옵션 없음</Item>}
        </List>
      </Wrapper>

      {error && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </DropdownStyle>
  );
};

const DropdownStyle = styled.div`
  position: relative;

  width: 144px;
  font-size: 13px;
  cursor: pointer;
`;
interface SelectedItemProps {
  selected: boolean;
  showList: boolean;
}
const SelectedItem = styled.div<SelectedItemProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 40px;
  padding: 0 16px;
  padding-right: 12px;

  border-radius: 4px;
  border: 1px solid #f2f2f2;
  background: white;

  color: ${(props) => (props.selected ? "#484848" : "#c0c0c0")};

  &:hover {
    background: #f7f7f7;
  }

  > svg {
    font-size: 16px;
    color: #484848;
  }
`;
const Text = styled.div`
  line-height: normal;
`;
const Wrapper = styled.div`
  width: 100%;
`;
const ErrorMessage = styled.div`
  position: absolute;
  top: 44px;
  left: 4px;

  font-size: 12px;
  color: ${(props) => props.theme.text.DANGER};
`;

interface ListProps {
  show: boolean;
  windowOverflow: boolean | null;
  windowOverflowX: boolean | null;
  dropTop: boolean;
}
const List = styled.ul<ListProps>`
  display: ${(props) => (props.show ? "block" : "none")};

  position: absolute;
  bottom: ${(props) => props.dropTop ? "42px" : props.windowOverflow ? "42px" : "none"};
  top: ${(props) => props.dropTop ? "none" : props.windowOverflow ? "none" : "42px"};
  ${(props) => (props.dropTop === false && props.windowOverflowX ? "right: 0" : "left: 0")};

  min-width: 100%;
  max-height: 200px;

  border-radius: 4px;
  background: white;
  box-shadow: 0 3px 6px ${(props) => props.theme.shadow.MAIN};

  z-index: 100;
`;

const Item = styled.li<{wideMode: boolean}>`
  display: flex;
  align-items: center;
  max-width: ${(props) => props.wideMode ? "100%" : "320px"};
  height: 40px;
  padding: 0 16px;

  white-space: nowrap;

  &:hover {
    background: #f7f7f7;
  }
`;

export default Dropdown;
