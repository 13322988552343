import Base from "repositories";
import {
  JobCreate
} from "types/job/schema";

class Job extends Base {
  constructor() {
    super({ prefix: "/job" , websocket: true});
  }

  async create(jobType: string, payload: JobCreate) {
    return this.axiosInstance.post(`/${jobType}`, payload);
  }

  async jobStatusWebsocket() {
    return new WebSocket(`${this.webSocketHost}/job/status`)
  }

}

export default new Job();
