import { Spinner as ReactSpinner } from 'react-bootstrap';
import styled from 'styled-components';

interface SpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
  line?: boolean;
}

const Spinner = ({ line, ...props }: SpinnerProps) => {
  return (
    <SpinnerWrapper line={line} {...props}>
      <ReactSpinner animation="border">
        <span className="visually-hidden">불러오는중...</span>
      </ReactSpinner>
    </SpinnerWrapper>
  )
}

const SpinnerWrapper = styled.div<{ line?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-grow: 1;
  height: ${(props) => props.line ? "auto" : "100%" };
`;

export default Spinner