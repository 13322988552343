import {makeAutoObservable} from "mobx";
import repository from "repositories/MathQuestion";
import {StoreState} from "types/common";
import {Question} from "types/question/model";

export default class SimilarMathQuestionList {
  state: StoreState = "none";
  questions: Question[] = [];
  detailQuestion?: Question;
  waitingOnlyQuestions: Question[] = [];
  questionStorage: Question[] = [];
  waitingOnly: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(question: Question) {
    this.state = "pending";
    try {
      const response = await repository.readSimilarMany({question_id: question._id});
      this.questions = response.data.questions;
      this.waitingOnlyQuestions = this.questions.filter(q => q.inspection_status === "waiting");
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async mainQuestion(question: Question) {
    if (this.questionStorage.some(q => q._id === question._id)) return;
    this.questionStorage.push(question);
  }

  async pushAndPopQuestion(question: Question) {
    if (this.questionStorage.some((q) => q._id === question._id)) {
      this.questionStorage = this.questionStorage.filter(q => q._id !== question._id);
    } else {
      this.questionStorage.push(question)
    }
  }

  async setDetailQuestion(question: Question) {
    this.detailQuestion = question;
  }

  async waitingOnlySwitch() {
    this.waitingOnly = !this.waitingOnly;
  }


  async clear() {
    this.state = "none";
    this.questions = [];
    this.detailQuestion = undefined;
    this.questionStorage = [];
    this.waitingOnly = false;
    this.waitingOnlyQuestions = [];
  }
}
