import _ from "lodash";
import {makeAutoObservable} from "mobx";

import repository from "repositories/MathCurriculumUnit";

import {StoreState} from "types/common";
import {MathCurriculumUnit} from "types/unit/math/model"

export default class ActiveMathCurriculumUnit {
  state: StoreState = "none";
  storageId: string = "curriculumDetailCode";
  unit?: MathCurriculumUnit;
  rank1CurriculumDetailCode?: string;
  unitsByDetail: MathCurriculumUnit[] = [];
  unitByDetail?: MathCurriculumUnit;
  difficultyCd?: string;
  repQuestionId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  async getCurriculumDetailCode() {
    return localStorage.getItem(this.storageId);
  }

  async readySet() {
    this.state = "pending"
  }

  async set(unit?: MathCurriculumUnit) {
    this.state = "pending";
    if (unit) {
      localStorage.setItem(this.storageId, unit.curriculumDetailCode);
      this.unit = _.clone(unit);
      const response = await repository.readByDetail(unit.curriculumDetailCode);
      this.unitsByDetail = response.data.units;
    } else {
      localStorage.removeItem(this.storageId);
      this.unit = undefined;
    }
    this.state = "done";
  }

  async setRank1CurriculumDetailCode(curriculumDetailCode?: string) {
    if (!curriculumDetailCode) return;
    this.rank1CurriculumDetailCode = curriculumDetailCode;
  }

  async setCurriculumDetailCode(setCurriculumDetailCode: string) {
    if (setCurriculumDetailCode) localStorage.setItem(this.storageId, setCurriculumDetailCode);
    else localStorage.removeItem(this.storageId);
  }

  async setUnitByDetail(unit?: MathCurriculumUnit) {
    this.unitByDetail = unit;
  }

  setRepQuestionId(repQuestionId?: string) {
    this.repQuestionId = repQuestionId;
  }

  async clearUnitByDetail() {
    this.unitByDetail = undefined;
  }

  getUnitByDetail() {
    const unit = this.unitsByDetail.filter(u => u.difficultyCd === this.difficultyCd!);
    return !!unit.length? unit[0]: this.unitsByDetail[0];
  }

  async reSet() {
    this.state = "none";
  }

  async clear() {
    this.state = "none";
    this.unit = undefined;
    this.rank1CurriculumDetailCode = undefined;
    this.unitsByDetail = [];
    this.unitByDetail = undefined;
    this.difficultyCd = undefined;
  }
}