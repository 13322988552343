import React, { useState, useMemo } from "react";
import styled from "styled-components";

import { ReactComponent as FirstPageIcon } from "assets/icons/icon_page_first.svg";
import { ReactComponent as PrevPageIcon } from "assets/icons/icon_page_prev.svg";
import { ReactComponent as NextPageIcon } from "assets/icons/icon_page_next.svg";
import { ReactComponent as LastPageIcon } from "assets/icons/icon_page_last.svg";

import { isInPages, isInRightRange } from "utils";

const QUESTION_LIMIT_COUNT = 1000000;

interface PaginationProps {
  currentPage?: number;
  pages?: number[];
  total?: number;
  size?: number;
  startPage?: number;
  lastPage?: number
  inputMode?: boolean;
  simpleMode?: boolean;
  oneStepMode?: boolean;
  withText?: boolean;
  onPageChange?: (page: number) => void;
  onEnterDown?: (page: number) => void;
}

const Pagination = ({
  currentPage,
  pages,
  total,
  size,
  startPage,
  lastPage,
  inputMode,
  simpleMode,
  oneStepMode = false,
  withText,
  onPageChange,
  onEnterDown,
}: PaginationProps) => {
  const endPage = useMemo(
    () => pages ? Math.max(...pages)
        : lastPage ? lastPage
        : Math.ceil(total! / size!) <= Math.floor(QUESTION_LIMIT_COUNT / size!) ? Math.ceil(total! / size!)
        : Math.floor(QUESTION_LIMIT_COUNT / size!),
    [pages, lastPage, total, size]
  );
  const firstPage = pages? Math.min(...pages): startPage? startPage: 1
  const [focus, setFocus] = useState(false);
  const [input, setInput] = useState(String(firstPage));


  //* Event methods
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;

    if (text.includes(".")) return;

    const number = Number(text);

    if (text.length === 0) {
      setInput("");
      return;
    }

    if (isNaN(number)) {
      setInput("");
      return;
    }

    if (1 <= number && number <= endPage) {
      setInput(text);
    } else {
      setInput("1");
    }
  };

  const goFirstPage = () => {
    if (currentPage && currentPage === firstPage) return;

    setInput(String(firstPage));
    onPageChange && onPageChange(firstPage);
  };

  const goPrevPage = () => {
    if (pages) {
      if (!isInPages(Number(input), pages)) return;
      const idx = pages.findIndex( (p) => p === Number(input));
      if (idx - 1 < 0) return;
      setInput(String(pages[idx-1]));
      onPageChange && onPageChange(pages[idx-1]);
    }
    else {
      if (currentPage) {
        if (!isInRightRange(currentPage - 1, firstPage, endPage)) return;
        setInput(`${currentPage - 1}`);
        onPageChange && onPageChange(currentPage - 1);
      } else {
        if (!isInRightRange(Number(input) - 1, firstPage, endPage)) return;
        setInput(`${Number(input) - 1}`);
        onPageChange && onPageChange(Number(input) - 1);
      }
    }
  };

  const goNextPage = () => {
    if (pages) {
      if (!isInPages(Number(input), pages)) return;
      const idx = pages.findIndex( (p) => p === Number(input));
      if (idx + 1 > pages.length - 1) return;
      setInput(String(pages[idx + 1]));
      onPageChange && onPageChange(pages[idx + 1]);
    } else {
      if (currentPage) {
        if (!isInRightRange(currentPage + 1, firstPage, endPage)) return;
        setInput(`${currentPage + 1}`);
        onPageChange && onPageChange(currentPage + 1);
      } else {
        if (!isInRightRange(Number(input) + 1, firstPage, endPage)) return;
        setInput(`${Number(input) + 1}`);
        onPageChange && onPageChange(Number(input) + 1);
      }
    }
  };

  const goLastPage = () => {
    if (currentPage && currentPage === endPage) return;

    setInput(String(endPage));
    onPageChange && onPageChange(endPage);
  };

  const handleEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const pageNumber = Number(input);
      if (isNaN(pageNumber)) {
        setInput(String(firstPage));
        return;
      } else {
        if (isInRightRange(pageNumber, firstPage, endPage) || (pages && isInPages(pageNumber, pages)))
          onEnterDown && onEnterDown(pageNumber);
        else {
          setInput(String(firstPage));
        }
      }
    }
  };

  return (
    <PaginationStyle className="block-select">
      {!simpleMode && (
        <PageIconWrapper onClick={goFirstPage}>
          <FirstPageIcon />
        </PageIconWrapper>
      )}
      <PageIconWrapper onClick={goPrevPage}>
        <PrevPageIcon />
      </PageIconWrapper>

      <Wrapper>
        {inputMode ? (
          <PageInput
            value={input}
            inputFocus={focus}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={handleChange}
            onKeyDown={handleEnterDown}
            min={firstPage}
            max={endPage}
          />
        ) : (
          <PageText>{currentPage ? currentPage : input}</PageText>
        )}
        {withText && <TotalPage>{oneStepMode ? "page" : `of ${endPage} pages`}</TotalPage>}
      </Wrapper>

      <PageIconWrapper onClick={goNextPage}>
        <NextPageIcon />
      </PageIconWrapper>

      {!(oneStepMode || simpleMode) && (
        <PageIconWrapper onClick={goLastPage}>
          <LastPageIcon />
        </PageIconWrapper>
      )}
    </PaginationStyle>
  );
};

const PaginationStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 4px;
`;
const PageInput = styled.input<{ inputFocus: boolean }>`
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 64px;
  height: 32px;
  padding: 0 16px;
  padding-right: 8px;

  border-radius: 4px;
  border: 1px solid ${(props) => (props.inputFocus ? "#484848" : "#f2f2f2")};
  background: white;

  font-size: 13px;
  color: #484848;

  outline: none;

  &:hover {
    background: #f7f7f7;
  }

  &::placeholder {
    color: #c0c0c0;
  }
`;
const PageText = styled.div`
  margin-right: 4px;
  font-size: 12px;
  color: ${(props) => props.theme.text.GREY};
`;
const TotalPage = styled.div`
  margin-left: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.text.GREY};
`;

const PageIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  > svg {
    fill: ${(props) => props.theme.text.IDLE};
    &:hover {
      fill: ${(props) => props.theme.text.HOVER};
    }
  }
`;

export default Pagination;
