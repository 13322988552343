import { useState, useEffect } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { v4 as uuid } from "uuid";
import styled from "styled-components";

import { AiOutlineCheck } from "react-icons/ai";
import { ReactComponent as DeleteIcon } from "assets/icons/icon_alert_delete.svg";
import { ReactComponent as CloseIcon } from "assets/icons/btn_alert_close_w.svg";
import { ReactComponent as AlertIcon } from "assets/icons/icon_alert_standby.svg";
import ReturnIcon from "assets/icons/ReturnIcon";

interface Toast {
  id?: string;
  type: "info" | "success" | "fail" | "postpone";
  content: string;
  duration?: number;
  index?: number;
  infinite?: boolean;
  innerButton?: boolean;
  innerButtonText?: string;
  handleInnerButtonClick?: () => void;
}

const toastState = atom<Toast[]>({
  key: "toastState",
  default: [],
});

export const useToast = () => {
  const [toasts, setToasts] = useRecoilState(toastState);

  const removeToast = (toastID: Toast["id"]) => setToasts((prev) => prev.filter((toast) => toast.id !== toastID));

  const fireToast = (toast: Toast) => {
    const id = uuid();
    setToasts((prev) => [...prev, { ...toast, id }]);
    setTimeout(() => removeToast(id), 600 + (toast.duration ?? 1000));
  };

  return { toasts, fireToast, removeToast };
};

export const ToastItem = ({ id, type, content, duration, index, innerButton, innerButtonText = "결과확인", handleInnerButtonClick }: Toast) => {
  const [isClosing, setIsClosing] = useState(false);
  const { removeToast } = useToast();

  useEffect(() => {
    const setExistTimeout = setTimeout(() => {
      setIsClosing(true);
      clearTimeout(setExistTimeout);
    }, duration ?? 1000);
  });

  //* Event methods
  const handleCloseClick = () => {
    removeToast(id);
  };

  return (
    <ToastItemStyle type={type} index={index} isClosing={isClosing}>
      <IconBox>
        {type === "info" ? (
          <AlertIcon />
        ) : type === "success" ? (
          <AiOutlineCheck />
        ) : type === "postpone" ? (
          <ReturnIcon size={20} color="#666" />
        ) : (
          <DeleteIcon />
        )}
      </IconBox>
      {content}

      {innerButton && 
        <InnerButton onClick={handleInnerButtonClick}>{innerButtonText}</InnerButton>
      }

      <CloseButton onClick={handleCloseClick}>
        <CloseIcon />
      </CloseButton>
    </ToastItemStyle>
  );
};

const ToastItemStyle = styled.div<{
  type: string;
  isClosing: boolean;
  index?: number;
}>`
  position: absolute;
  left: 56px;
  bottom: ${({ index }) => 56 + 60 * (index ?? 0)}px;

  display: flex;
  align-items: center;
  min-width: 300px;
  max-width: 800px;
  height: 56px;
  padding: 0 52px 0 16px;

  border-radius: 4px;
  background: ${({ type, theme }) =>
    type === "info"
      ? theme.background.INFO
      : type === "success"
      ? theme.background.SUCCESS
      : type === "postpone"
      ? theme.background.FOCUS
      : theme.background.DANGER};
  box-shadow: 3px 5px 6px rgba(0, 0, 0, 0.16);

  font-size: 14px;
  color: white;
  transition: 0.3s all ease-out;

  svg {
    color: ${({ type, theme }) =>
      type === "info"
        ? theme.text.INFO
        : type === "success"
        ? theme.text.SUCCESS
        : type === "postpone"
        ? theme.text.POSTPONE
        : theme.text.DANGER};
  }

  animation: 0.3s forwards ${({ isClosing }) => (isClosing ? "fadeout" : "fadein")};
`;

const InnerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 28px;
  margin-left: 16px;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.6);

  color: #FFFFFF;
  cursor: pointer;
`;

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  margin-right: 8px;

  border-radius: 9999px;
  background: ${(props) => props.theme.background.PANEL_WHITE};
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;

  cursor: pointer;
`;

export const ToastList = () => {
  const toasts = useRecoilValue(toastState);

  return (
    <ToastListStyle>
      {toasts.map((toast, index) => (
        <ToastItem key={toast.id} index={index} {...toast} />
      ))}
    </ToastListStyle>
  );
};

const ToastListStyle = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
`;
