import {useToast} from "hooks/useToast";
import {toastMessage, error} from "messages";


export const useErrorHandler = () => {
    const toast = useToast();

    const handle = async (p: Promise<any>, returnData?: boolean): Promise<any> => {
      let errored = false;
      let data: any = false;
      await p
        .then(res => {
          if (res && returnData)
            data = res;
        })
        .catch(e => {
        errored = true;
        const m = typeof e.response.data === "string"
          ? e.response.data
          : e.response.data.message
        let errorMessage = ""
        switch (m) {
          case error.jobProcess:
            errorMessage = toastMessage.fail.jobProcessError
        }
        toast.fireToast({
              type: "fail",
              content: errorMessage,
              duration: 5000,
          })
      })
      return returnData? data: errored;
    }

    return { handle };
}