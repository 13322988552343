import React from "react";
import styled from "styled-components";

import { BsCheck } from "react-icons/bs";

interface CheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
  checked: boolean;
  disabled?: boolean;
  handleDisabledClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  handleClick?: (e: React.MouseEvent<HTMLDivElement>, checked: boolean) => void;
  checkColor?: string;
}

const Checkbox = ({ checked, disabled, handleDisabledClick, handleClick, checkColor, ...props }: CheckboxProps) => {

  //* Event methods
  const handleCheckboxClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    
    if (disabled) {
      handleDisabledClick && handleDisabledClick(e);
    } else {
      handleClick && handleClick(e, !checked);
    }
  };

  return (
    <CheckboxStyle
      {...props}
      checked={checked}
      disabled={disabled}
      onClick={handleCheckboxClick}
      checkColor={checkColor}
    >
      {checked && <BsCheck />}
    </CheckboxStyle>
  );
};

const CheckboxStyle = styled.div<{ checked?: boolean, disabled?: boolean, checkColor?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  

  width: 20px;
  height: 20px;

  border: 1px solid
    ${(props) => (props.checked ? "#666" : "rgba(0, 0, 0, 0.3)")};
  border-radius: 2px;
  background: ${(props) => (props.disabled? "#32344E1A": "white")};

  font-size: 18px;
  color: ${(props) => props.checkColor ? props.checkColor : props.theme.text.SUCCESS};

  &:hover {
    background: #f8f8f8;
  }

  &:active {
    background: white;
  }
`;

export default Checkbox;
