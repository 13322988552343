import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";
import {useErrorHandler} from "hooks/useErrorHandler";

import {QuestionReadManyRequest} from "types/question/schema";

import ModalAddon from "components/ModalAddon";

const CancelInspectionModalInQuestionView = observer(() => {
  const {job, questionList} = useStores();
  const handler = useErrorHandler();
  const modalId = "cancelInspectionModalInQuestionView";

  const isSelected = useMemo(() => {
    return questionList.questionStorage.length
  }, [questionList.questionStorage.length])

  const handleOkayClick = async () => {
    await job.setType("UISW")
    const condition: QuestionReadManyRequest = questionList.jobPayload()
    await job.setCondition(condition)
    if (
      await handler.handle(job.create())
    ) return;
    await questionList.clearQuestionStorage();
    await clear();
  };

  const clear = async () => {
    await job.clearCreatePayload();
  }

  return (
    <MaruModal
      id={modalId}
      title="검수취소"
      buttonColor="#e9397a"
      buttonText="검수취소"
      withCancel
      onClose={clear}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        <Paragraph>
          {isSelected?"선택한":"조회된"} <b>{isSelected?isSelected:questionList.total}개 문항</b>을 <em className="text-danger">검수취소</em>{" "}
          처리합니다.
        </Paragraph>
      </ModalInnerPanel>

      <ModalAddon mode="fail" />
    </MaruModal>
  );
});


const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }
`;

export default CancelInspectionModalInQuestionView;