import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/MathCurriculumUnit";

import {StoreState, Facet} from "types/common";
import {MathCurriculumUnit} from "types/unit/math/model";
import {
  MathCurriculumUnitFilterOptions,
  MathCurriculumUnitSortingOptions,
  MathCurriculumUnitUpdateRequest
} from "types/unit/math/schema"

import {removeDot} from "utils";

const MathCurriculumUnitStorage = {
  MATH_CURRICULUM_UNIT: "mathCurriculumUnit",
  RULE_MATH_CURRICULUM_UNIT: "ruleMathCurriculumUnit"
} as const;

type MATH_CURRICULUM_UNIT_STORAGE = typeof MathCurriculumUnitStorage[keyof typeof MathCurriculumUnitStorage]

export default class MathCurriculumUnitList {
  facetsFields: string[] = ["course_name.keyword" , "grade_name.keyword", "semesterNm.keyword", "checker_name.keyword", "modifier_name.keyword"];
  state: StoreState = "none";
  searchState: StoreState = "none";
  storage?: MATH_CURRICULUM_UNIT_STORAGE;
  storageState: StoreState = "none";
  units: MathCurriculumUnit[] = [];
  unitStorage: MathCurriculumUnit[] = [];
  facets: Facet[] = [];
  filters: MathCurriculumUnitFilterOptions = {};
  sorting: MathCurriculumUnitSortingOptions = {};
  onlyWaiting: boolean = false;
  checkAll: boolean = false;
  page: number = 1;
  size: number = 20;
  total: number = 0;


  constructor() {
    makeAutoObservable(this);
  }

  async setFilterStorage(storage: MATH_CURRICULUM_UNIT_STORAGE) {
    localStorage.setItem(`${storage}Filter`, JSON.stringify(this.filters))
  }

  async setSortingStorage(storage: MATH_CURRICULUM_UNIT_STORAGE) {
    localStorage.setItem(`${storage}Sorting`, JSON.stringify(this.sorting))
  }

  async getFilterStorage(storage: MATH_CURRICULUM_UNIT_STORAGE) {
    const item = localStorage.getItem(`${storage}Filter`)
    return item ? JSON.parse(item) : {}
  }

  async getSortingStorage(storage: MATH_CURRICULUM_UNIT_STORAGE) {
    const item = localStorage.getItem(`${storage}Sorting`)
    return item ? JSON.parse(item) : {}
  }

  async readyStorage(storage?: MATH_CURRICULUM_UNIT_STORAGE) {
    if (storage) {
      this.storageState = "pending";
      this.storage = storage
      this.filters = await this.getFilterStorage(storage);
      this.sorting = await this.getSortingStorage(storage);
      this.storageState = "done";
    }
  }

  async readMany(
    onlyHasQuestion?: boolean,
    returnQuestionAggs?: boolean,
    returnRuleAggs?: boolean
  ) {
    this.state = "pending";
    try {
      if (this.storage) {
        await this.setFilterStorage(this.storage);
        await this.setSortingStorage(this.storage);
      }
      const response = await repository.readMany({
        filters: removeDot(this.storage?await this.getFilterStorage(this.storage): this.filters, [".keyword", ".kwd"]),
        sorting: this.storage?await this.getSortingStorage(this.storage): this.sorting,
        return_question_aggs: returnQuestionAggs,
        return_rule_aggs: returnRuleAggs,
        waiting_only: this.onlyWaiting,
        only_has_question: onlyHasQuestion,
        page: this.page,
        size: this.size
      });
      this.units = response.data.units;
      this.total = response.data.total;

      if (Object.values(this.filters).some(v => v)) this.searchState = "done"
      else this.searchState = "none"

      await this.readFacets();

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async read(curriculumDetailCode: string | null | undefined) {
    try {
      if (!curriculumDetailCode)
        return undefined;
      const response = await repository.read(curriculumDetailCode);
      return response.data
    } catch (e) {
      throw e;
    }
  }

  async updateMany(payload: MathCurriculumUnitUpdateRequest) {
    try {
      const p = _.cloneDeep(payload)
      const response = await repository.update(p);
      return response.data
    } catch (e) {
      throw e;
    }
  }

  async readFacets() {
    try {
      const response = await repository.readFacets({fields: this.facetsFields});
      this.facets = response.data.facets
    } catch (e) {
      throw e;
    }
  }

  setText(text: string, field: string) {
    if (!text) {
      delete this.filters[field];
      return;
    }
    this.filters[field] = text;
  }

  getFacet(field: string) {
    const facet = this.facets.filter(f => field === f.field)[0]
    if (!facet) return []
    return facet.facet
  }

  async pushFilter(field: string, item?: any) {
    if (_.isUndefined(item) || _.isNull(item)) {
      delete this.filters[field];
      return;
    }
    this.filters[field] = item["value"]?item["value"]:item;
  }

  async popFilter(field: string) {
    delete this.filters[field];
  }

  getFilter(field: string) {
    return !_.isUndefined(this.filters[field]) ? this.filters[field] : undefined;
  }

  async pushAndPopUnit(unit: MathCurriculumUnit) {
    if (this.unitStorage.some((u) => u._id === unit._id)) {
      this.unitStorage = this.unitStorage.filter(u => u._id !== unit._id);
    } else {
      this.unitStorage.push(unit)
    }
  }

  async switchCheckAll() {
    if (this.checkAll) {
      await this.turnCheckAllOff();
    }else {
      await this.turnCheckAllOn();
    }
  }

  async turnCheckAllOn() {
    this.checkAll = true;
    this.unitStorage = this.units;
  }

  async turnCheckAllOff() {
    this.checkAll = false;
    this.unitStorage = [];
  }

  async setUnitStorage(units: MathCurriculumUnit[]) {
    this.unitStorage = units;
  }

  async setPage(page: number) {
    this.page = page
  }

  async setSize(size: number) {
    this.page = 1;
    this.size = size
  }

  async setSorting(field: string) {
    this.sorting[field] = this.sorting[field]? this.sorting[field] === "desc" ? "asc" : undefined: "desc"
  }

  getSorting(field: string) {
    return this.sorting[field];
  }

  getUnitName(unit?: MathCurriculumUnit) {
    if (!unit) return ""
    return [unit.unit1_name, unit.unit2_name, unit.unit3_name, unit.time_unit_name, unit.type_unit_name].join("  ")
  }

  async switchOnlyWaiting() {
    if (this.onlyWaiting) {
      this.onlyWaiting = false;
    } else if (!Object.entries(this.filters).length) {
      this.sorting = {};
      this.onlyWaiting = true;
    }
  }

  unitInfo(unit: MathCurriculumUnit) {
    return `${unit.grade_semester} ${unit.unit1_name} ${unit.unit2_name} ${unit.unit3_name} ${unit.time_unit_name} ${unit.type_unit_name} ${unit.detail_type_unit_name}`
  }

  async reset() {
    this.filters = {};
    this.sorting = {};
    this.unitStorage = [];
    this.onlyWaiting = false;
    this.checkAll = false;
    this.page = 1;
    this.size = 20;
  }

  async clear() {
    this.state = "none";
    this.searchState = "none";
    this.storageState = "none";
    this.storage = undefined;
    this.units = [];
    this.unitStorage = [];
    this.facets = [];
    this.filters = {};
    this.sorting = {};
    this.checkAll = false;
    this.page = 1;
    this.size = 20;
    this.total = 0;
    this.onlyWaiting = false;
  }

  async search() {
    this.unitStorage = [];
    this.checkAll = false;
    this.state = "none";
  }
}
