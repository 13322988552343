import "bootstrap/dist/css/bootstrap.min.css";

import { useCallback, useEffect, useMemo, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import styled, { ThemeProvider } from "styled-components";
import { MathJaxContext } from "better-react-mathjax";

import { Theme } from "assets/style/Theme";
import Global from "assets/style/Global";

import { ToastList } from "hooks/useToast";
import useStores from "hooks/useStores";

import { ROUTERS } from "constant";

import SuspenseLoader from "components/SuspenseLoader";
import ConditionRoute from "routes/ConditionRoute";

import Modals from "views/Modals";
import * as views from "views";

const useWebsocket = process.env.REACT_APP_USE_WEBSOCKET!;

const App = observer(() => {
  const { authentication, activeUnit, job } = useStores();

  const authNotInitialized = useCallback(() => {
    return authentication.state === "none";
  }, [authentication.state]);

  const initializeAuth = useCallback(async () => {
    await authentication.proof();
  }, [authentication]);

  useEffect(() => {
    if (authNotInitialized()) initializeAuth();
  }, [initializeAuth, authentication.state, authNotInitialized]);

  const loggedIn = useMemo(() => {
    return authentication.state === "done" && authentication.isLogin;
  }, [authentication.state, authentication.isLogin]);

  const isChecker = useMemo(() => {
    return loggedIn && (authentication.admin() || activeUnit.unit?.checker_email === authentication.user?.email);
  }, [loggedIn, activeUnit.unit, authentication]);

  const isAdmin = useMemo(() => {
    return loggedIn && (authentication.user?.is_admin || authentication.isDanbiAdmin);
  }, [loggedIn, authentication.user, authentication.isDanbiAdmin]);

  const ready = useMemo(() => {
    return authentication.state === "done" || authentication.state === "error";
  }, [authentication.state]);

  const notJobWebsocketInitialized = useCallback(() => {
    return useWebsocket === "true" && authentication.state === "done" && job.state === "none";
  }, [job.state, authentication.state]);

  const initializeJobWebsocket = useCallback(async () => {
    await job.startSocket(authentication.user!);
    await job.readSocket();
  }, [job, authentication.user]);

  useEffect(() => {
    if (notJobWebsocketInitialized()) initializeJobWebsocket();
  }, [job.state, initializeJobWebsocket, notJobWebsocketInitialized]);

  if (!ready) return null;

  return (
    <AppStyle>
      <ThemeProvider theme={Theme.light}>
        <MathJaxContext>
          <BrowserRouter>
            <Suspense fallback={<SuspenseLoader />}>
              <Routes>
                <Route path="/" element={<Navigate replace to={ROUTERS.auth.login} />} />
                <Route
                  path={ROUTERS.auth.base}
                  element={
                    <ConditionRoute condition={!loggedIn} redirectPath={ROUTERS.inspection.textbook}>
                      <views.authView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path="/"
                  element={
                    <ConditionRoute condition={isAdmin} redirectPath={ROUTERS.auth.login}>
                      <views.mainView />
                    </ConditionRoute>
                  }
                >
                  <Route path={ROUTERS.rule.baseTextbook} element={<views.ruleView />} />
                  <Route path={ROUTERS.rule.curriculum} element={<views.curriculumRuleView />} />
                  <Route path={ROUTERS.question.baseTextbook} element={<views.questionView />} />
                  <Route path={ROUTERS.question.baseCurriculum} element={<views.curriculumQuestionView />} />
                </Route>

                <Route
                  path="/"
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={ROUTERS.auth.login}>
                      <views.mainView />
                    </ConditionRoute>
                  }
                >
                  <Route path={ROUTERS.inspection.baseTextbook} element={<views.inspectionView />} />
                  <Route path={ROUTERS.inspection.baseCurriculum} element={<views.inspectionCurriculumView />} />
                  <Route path={ROUTERS.dashboard.base} element={<views.dashboardView />} />
                  <Route path={ROUTERS.stats.question} element={<views.questionStatsView />} />
                  <Route path={ROUTERS.stats.textbook} element={<views.textbookStatsView />} />
                  <Route path={ROUTERS.stats.autoClassification} element={<views.autoClassificationStatsView />} />
                  <Route path={ROUTERS.stats.inspection} element={<views.inspectionStatsView />} />
                  <Route path={ROUTERS.searchSimulation.similar} element={<views.searchSimulationView />} />
                  <Route path={ROUTERS.searchSimulation.bulk} element={<views.bulkTestView />} />
                </Route>

                <Route
                  path={ROUTERS.question.baseTextbookQuestionId}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={ROUTERS.auth.login}>
                      <views.inspectQuestionIdView />
                    </ConditionRoute>
                  }
                />

                <Route
                  path={ROUTERS.question.baseCurriculumQuestionId}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={"/auth/login"}>
                      <views.inspectCurriculumQuestionIdView />
                    </ConditionRoute>
                  }
                />

                <Route
                  path={ROUTERS.rule.textbookUnit}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={ROUTERS.auth.login}>
                      <views.ruleUnitView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.rule.curriculumUnit}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={ROUTERS.auth.login}>
                      <views.curriculumRuleUnitView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.inspection.textbookQuestion}
                  element={
                    <ConditionRoute condition={isChecker} redirectPath={loggedIn ? ROUTERS.inspection.textbook : ROUTERS.auth.login}>
                      <views.inspectQuestionView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.inspection.curriculumQuestion}
                  element={
                    <ConditionRoute condition={isChecker} redirectPath={loggedIn ? ROUTERS.inspection.curriculum : ROUTERS.auth.login}>
                      <views.inspectCurriculumQuestionView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.inspection.baseTextbookQuestionId}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={ROUTERS.auth.login}>
                      <views.inspectQuestionIdView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.inspection.baseCurriculumQuestionId}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={"/auth/login"}>
                      <views.inspectCurriculumQuestionIdView />
                    </ConditionRoute>
                  }
                />

                <Route
                  path={ROUTERS.stats.autoClassificationQuestion}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={"/auth/login"}>
                      <views.autoClassificationQuestionStatsView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.stats.baseAutoClassificationQuestionId}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={"/auth/login"}>
                      <views.autoClassificationQuestionIdStatsView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.stats.autoClassificationCurriculumQuestion}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={"/auth/login"}>
                      <views.autoClassificationCurriculumQuestionStatsView />
                    </ConditionRoute>
                  }
                />
                <Route
                  path={ROUTERS.stats.baseAutoClassificationCurriculumQuestionId}
                  element={
                    <ConditionRoute condition={loggedIn} redirectPath={"/auth/login"}>
                      <views.autoClassificationCurriculumQuestionIdStatsView />
                    </ConditionRoute>
                  }
                />

                <Route path="*" element={<views.error404View />} />
              </Routes>
            </Suspense>

            <Modals />
          </BrowserRouter>

          <Global />
          <ToastList />
        </MathJaxContext>
      </ThemeProvider>
    </AppStyle>
  );
});

const AppStyle = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 800px;

  overflow-y: hidden;
`;

export default App;
