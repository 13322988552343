import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";

import { CLASSIFICATION_TYPE_ENG_KOR_MAPPING, INSPECTION_STATUS_ENG_KOR_MAPPING, ROUTERS } from "constant";

import Button from "components/Button";
import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";

import { engToKor, scoreToPercent } from "utils";


const CurriculumQuestionItemModal = observer(() => {
  const { mathQuestionSearch, mathQuestionList } = useStores();
  const modalId = "curriculumQuestionItemModal";
  const [mouseOver, setMouseOver] = useState(false);
  const [htmlMode, setHtmlMode] = useState(false);

  const handleOkayClick = () => {};

  const question = useMemo(() => {
    return mathQuestionSearch.detail!;
  }, [mathQuestionSearch.detail]);

  const unit = useMemo(() => {
    return question._parent;
  }, [question._parent])

  //* Event methods
  const handleQuestionIdClick = () => {
    window.location.replace(ROUTERS.inspection.curriculumQuestionId(question!._id));
  };

  const handleHTMLModeButtonClick = () => {
    setHtmlMode(!htmlMode);
  };

  return (
    <MaruModal id={modalId} size="xl" customWidth="1184px" onOkayClick={handleOkayClick}>
      <Heading className="block-select" id="draggable-handle">
        <b>검수완료 기출문항 자세히 보기</b>
      </Heading>

      <Content>
        <Row>
          <Item style={{ alignItems: "center" }}>
            <Field>문항 ID</Field>
            <Value
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              onClick={handleQuestionIdClick}
              mouseOver={mouseOver}
              style={{ fontWeight: "500" }}
            >
              {question!._id}
            </Value>
          </Item>

          <Item style={{ alignItems: "center" }}>
            <Field>상태</Field>
            <Value>
              <InspectionStatusText status={question!.inspection_status}>
                {engToKor(INSPECTION_STATUS_ENG_KOR_MAPPING, question!.inspection_status)}
              </InspectionStatusText>
              <Badge>{mathQuestionList.getInspectionStatus(question!)}</Badge>
              {unit.checker_email && (
                <em style={{ color: "#484848", opacity: "0.8" }}>{`(${unit.checker_email})`}</em>
              )}
            </Value>
          </Item>
        </Row>

        <Row>
          <Item>
            <Field>시험지 ID</Field>
            <Value>{question?.pocCode}</Value>
          </Item>

          {question?.checker_type && (
            <Item>
              <Field>최종분류근거</Field>
              <Value>
                <em className="text-info" style={{ marginRight: "4px" }}>
                  {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
                </em>
                <em style={{ color: "#484848", opacity: "0.8" }}>{question.score && `(${scoreToPercent(question.score)})`}</em>
              </Value>
            </Item>
          )}
        </Row>

        <Row style={{ marginTop: 4 }}>
          <Item>
            <Field style={{ alignItems: "flex-start" }}>시험정보</Field>
            <Value style={{ alignItems: "flex-start" }}>{mathQuestionList.questionInfo(question)}</Value>
          </Item>

          <Item>
            {
              question.rep_question_id &&
              <>
                <Field style={{ alignItems: "flex-start" }}>기준문항 ID</Field>
                <Value style={{ alignItems: "flex-start" }}>{question?.rep_question_id}</Value>
              </>
            }
          </Item>
        </Row>

        <Row>
          <Item style={{ margin: "0" }}>
            <Field>표준커리큘럼 ID</Field>
            <Value>{question?.unitCode}</Value>
          </Item>
        </Row>

        <Row>
          <Item style={{ margin: "0" }}>
            <Field>표준커리큘럼정보</Field>
            <Value>{question?.grade_semester}</Value>
          </Item>
        </Row>

        <Row>
          <Item style={{ margin: "0" }}>
            <Field></Field>
            <Value style={{ lineHeight: "normal" }}>
              {question?.unit_name}
            </Value>
          </Item>
        </Row>

        <LevelsWrapper className="scrollbar">
          <Levels level1={question.level1} level2={question.level2} level3={question.level3} htmlMode={htmlMode} noMargin />

          <FlexWrapper>
            <HTMLModeButton onClick={handleHTMLModeButtonClick}>HTML 모드 {htmlMode ? "OFF" : "ON"}</HTMLModeButton>
          </FlexWrapper>
        </LevelsWrapper>
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > b {
    margin-left: 8px;
    font-weight: bold;
  }
`;

const Content = styled.div`
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  min-height: 30px;
  padding: 0 16px;

  line-height: normal;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-shrink: 0;
  min-height: 26px;

  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 112px;
  min-height: 26px;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div<{ mouseOver?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 26px;

  color: ${(props) => props.theme.text.BLACK};

  text-decoration: ${(props) => (props.mouseOver ? "underline" : "none")};
  cursor: ${(props) => (props.mouseOver ? "pointer" : "default")};
  line-height: normal;

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }
`;
const Badge = styled.div`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const LevelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
  max-height: 600px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.MAIN};
`;

const FlexWrapper = styled.div`
  height: 32px;
  margin-top: 16px;
  margin-right: 16px;
`;
const HTMLModeButton = styled(Button)`
  width: 120px;
  height: 32px;

  background-color: ${(props) => props.theme.background.SUCCESS};

  &:hover {
    background-color: #40a547;
  }
`;

export default CurriculumQuestionItemModal;
