import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

import ModalAddon from "components/ModalAddon";

const CancelCurriculumInspectionModal = observer(() => {
  const {mathQuestionList, activeMathCurriculumUnit} = useStores();
  const modalId = "cancelCurriculumInspectionModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async () => {
    if (mode === 3) return;
    if (
      await handler.handle(
        mathQuestionList.updateMany(
          {
            question_ids: mathQuestionList.questionStorage.map(q => q._id),
            values: {
              inspection_status: "waiting"
            }
          }
        )
      )
    ) return;
    toast.fireToast({
      type: "fail",
      content: toastMessage.fail.cancelInspection,
      duration: 5000,
    });
    await activeMathCurriculumUnit.reSet();
    await mathQuestionList.search();
  };

  const mode = useMemo(() => {
    if (mathQuestionList.questionStorage.every(u => u.inspection_status === "inspected"))
      return 1
    if (mathQuestionList.questionStorage.some(u => u.inspection_status === "waiting"))
      return 3
    return 2
    // eslint-disable-next-line
  }, [mathQuestionList.questionStorage.length])

  return (
    <MaruModal
      id={modalId}
      title="검수취소"
      buttonColor={mode !== 3 ? "#e9397a" : ""}
      buttonText={mode === 3 ? "확인" : "검수취소"}
      warning={
        <>
          취소 후에는 결과
          <em style={{ color: "#e9397a" }}> 복원이 불가</em>합니다.
        </>
      }
      withCancel={mode !== 3}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {mode === 1 && <OnlyChecked count={mathQuestionList.questionStorage.length}/>}
        {mode === 2 && <Mixed count={mathQuestionList.questionStorage.length}/>}
        {mode === 3 && <OnlyUnchecked />}
      </ModalInnerPanel>

      <ModalAddon mode="fail" />
    </MaruModal>
  );
});

interface CancelCurriculumInspectionProps {
  count: number;
}

const OnlyChecked = ({count}: CancelCurriculumInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b>을 <em className="text-danger">검수취소</em>{" "}
    처리합니다.
  </Paragraph>
);
const Mixed = ({count}: CancelCurriculumInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b> 중 <em className="text-success">검수완료</em> 또는{" "}
    <em className="text-info">검수보류</em> 처리한 문항이 있습니다.
    <br />
    이대로 검수취소로 상태를 변경하시겠습니까?
  </Paragraph>
);
const OnlyUnchecked = () => (
  <Paragraph>
    검수대기 문항에 대해서는 <em className="text-danger">검수취소</em>를 할 수
    없습니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }
`;

export default CancelCurriculumInspectionModal;