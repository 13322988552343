import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { useToast } from "hooks/useToast";
import { MaruModal } from "hooks/useModal";

import {
  CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING,
  CLASSIFICATION_TYPE_ENG_KOR_MAPPING,
  INSPECTION_STATUS_ENG_KOR_MAPPING
} from "constant";
import { toastMessage } from "messages";

import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";

import {engToKor, scoreToPercent} from "utils";


const RuleAppliedCurriculumQuestionModal = observer(() => {
  const {mathRuleSimulation, questionList} = useStores();
  const modalId = "ruleAppliedCurriculumQuestionModal";
  const toast = useToast();

  const question = useMemo(() => {
    return mathRuleSimulation.detail!
  }, [mathRuleSimulation.detail])

  const handleQuestionIdClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(question._id);
    toast.fireToast({
      type: "success",
      content: toastMessage.success.copyQuestionId,
      duration: 5000,
    });
  }

  const inspectionStatus = useMemo(() => {
    return question.classification_status === "completed"
      ?engToKor(INSPECTION_STATUS_ENG_KOR_MAPPING, question.inspection_status)
      :"미분류"
  }, [question.classification_status, question.inspection_status])

  const clear = async () => {
    await mathRuleSimulation.clearDetail();
  }

  return (
    <MaruModal
      id={modalId}
      size="xl"
      customWidth="1184px"
      onClose={clear}
    >
      <Heading className="block-select" id="draggable-handle">
        분류규칙 적용 문항 자세히보기
      </Heading>

      <Content>
        <Row style={{ transform: "translateY(4px)" }}>
          <Item style={{ alignItems: "center" }}>
            <Field>문항 ID</Field>
            <Value
              onClick={handleQuestionIdClick}
              style={{ fontWeight: "500" }}
              clickable
            >{question._id}</Value>
          </Item>

          <Item style={{ alignItems: "center", transform: "translateY(-4px)" }}>
            <Field>상태</Field>
            <Value>
              <InspectionStatusText status={question.inspection_status}>{inspectionStatus}</InspectionStatusText>
              {
                question?.classification_consistency &&
                <Badge>{engToKor(CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING, question!.classification_consistency)}</Badge>
              }
              {
                question.modifier_email &&
                <em style={{ color: "#484848", opacity: "0.8" }}>({question.modifier_name})</em>
              }
            </Value>
          </Item>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Item>
            <Field>시험지 ID</Field>
            <Value>
              {question.pocCode}
            </Value>
          </Item>

          <Item>
            {
              question?.checker_type &&
              <>
                <Field>최종분류근거</Field>
                <Value>
                  <em className="text-info" style={{ marginRight: "4px" }}>
                    {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
                  </em>
                  <em style={{ color: "#484848", opacity: "0.8" }}>
                    {question.score && `(${scoreToPercent(question.score)})`}
                  </em>
                </Value>
              </>
            }
          </Item>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Item>
            <Field>시험정보</Field>
            <Value>
              {questionList.questionInfo(question)}
            </Value>
          </Item>

          <Item>
            {
              question.rep_question_id &&
              <>
                <Field>기준문항 ID</Field>
                <Value>{question.rep_question_id}</Value>
              </>
            }
          </Item>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Item style={{ margin: "0" }}>
            <Field>표준커리큘럼 ID</Field>
            <Value>
              {question.unitCode}
            </Value>
          </Item>
        </Row>

        <Row style={{ marginTop: "8px" }}>
          <Item style={{ margin: "0" }}>
            <Field>표준커리큘럼정보</Field>
            <Value>
              {question.gradeNm}학년 {question.seasonNm}학기
            </Value>
          </Item>
        </Row>

        <Row>
          <Item style={{ margin: "0" }}>
            <Field></Field>
            <Value style={{ lineHeight: "normal" }}>
              {question.unit_name && `${ question!.unit_name}`}
            </Value>
          </Item>
        </Row>

        <LevelsWrapper className="scrollbar">
          <Levels level1={question!.level1} level2={question!.level2} level3={question!.level3} />
        </LevelsWrapper>
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Content = styled.div`
  border-radius: 4px;
  max-height: 720px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 16px;

  min-height: 20px;
  line-height: normal;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-shrink: 0;
  min-height: 20px;

  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  flex-shrink: 0;
  width: 112px;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div<{ clickable?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text.BLACK};
  cursor: ${(props) => props.clickable ? "pointer" : "auto"};

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  &:hover {
    text-decoration: ${(props) => props.clickable ? "underline" : "none"};
  }
`;
const Badge = styled.div`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const LevelsWrapper = styled.div`
  margin-top: 16px;
  max-height: 600px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.MAIN};
`;

export default RuleAppliedCurriculumQuestionModal;
