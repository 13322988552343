import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";

import RuleModalContent from "views/inspection/questionId/RuleModalContent";
import UnitModalContent from "views/inspection/questionId/UnitModalContent";
import QuestionModalContent from "views/inspection/questionId/QuestionModalContent";

const AutoClassifiedModal = observer(() => {
  const { classificationResult } = useStores();
  const modalId = "autoClassifiedModal";

  const type = useMemo(() => {
    return classificationResult.detail?.classification_type;
  }, [classificationResult.detail]);

  return (
    <MaruModal
      id={modalId}
      size={type === "question" ? "xl" : "lg"}
      customWidth={type === "question" ? "1184px" : "800px"}
    >
      <Heading className="block-select" id="draggable-handle">
        자동분류 결과
        {(() => {
          switch (type) {
            case "rule":
              return <em>분류규칙 자세히 보기</em>;

            case "unit":
              return <em>교과서 본문 자세히 보기</em>;

            case "question":
              return <em>검수완료 기출문항 자세히 보기</em>;
          }
        })()}
      </Heading>

      <Content>
        {(() => {
          switch (type) {
            case "rule":
              return <RuleModalContent />;

            case "unit":
              return <UnitModalContent />;

            case "question":
              return <QuestionModalContent />;
          }
        })()}
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Content = styled.div`
  border-radius: 4px;
`;

export default AutoClassifiedModal;
