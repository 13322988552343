import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";

const AutoClassificationSignModal = observer(() => {
  const modalId = "autoClassificationSignModal";

  return (
    <MaruModal
      id={modalId}
      title="자동분류 진행 중"
      buttonText="확인"
    >
      <Paragraph>
        자동분류가 진행 중입니다. <br />
        자동분류 진행 중에는 <br />
        시뮬레이션이 결과 문항에 대한 분류규칙 적용이 불가능합니다. <br />
        잠시 후에 다시 시도해주세요.
      </Paragraph>
    </MaruModal>
  );
});

const Paragraph = styled.p`
  margin-top: 24px;

  font-size: 13px;
  font-weight: 500;
  color: #333;
  
  text-align: center;
  line-height: 1.5rem;
`;

export default AutoClassificationSignModal;