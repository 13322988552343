import { DropdownItemType, EngKorMapping } from "types/common";
import { ReactComponent as RulesIcon } from "assets/icons/icon_class_rule.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/icon_dashboard.svg";
import { ReactComponent as QuestionsIcon } from "assets/icons/icon_divide_question.svg";
import { ReactComponent as CheckIcon } from "assets/icons/icon_final_check.svg";
import { ReactComponent as SimilarDocsIcon } from "assets/icons/icon_similar_doc.svg";
import { ReactComponent as StatisticsIcon } from "assets/icons/icon_statistics.svg";

export const ROUTERS = {
  dashboard: {
    base: "dashboard",
  },
  searchSimulation: {
    base: "simulation",
    similar: "simulation/similar",
    bulk: "simulation/bulk",
  },
  inspection: {
    baseTextbook: "inspection/textbook",
    baseCurriculum: "inspection/curriculum",
    baseTextbookQuestionId: "/inspection/textbook/question/:question_id",
    baseCurriculumQuestionId: "/inspection/curriculum/question/:question_id",
    textbook: "/inspection/textbook",
    curriculum: "/inspection/curriculum",
    textbookQuestionId: (questionId: string) => `/inspection/textbook/question/${questionId}`,
    curriculumQuestionId: (questionId: string) => `/inspection/curriculum/question/${questionId}`,
    textbookQuestion: "/inspection/textbook/question",
    curriculumQuestion: "/inspection/curriculum/question",
  },
  auth: {
    base: "auth/*",
    login: "/auth/login",
    signup: "/auth/signup",
  },
  question: {
    baseTextbook: "question/textbook",
    textbook: "/question/textbook",
    baseCurriculum: "question/curriculum",
    curriculum: "/question/curriculum",
    baseCurriculumQuestionId: "/question/curriculum/:question_id",
    curriculumQuestionId: (questionId: string) => `/question/curriculum/${questionId}`,
    baseTextbookQuestionId: "/question/textbook/:question_id",
    textbookQuestionId: (questionId: string) => `/question/textbook/${questionId}`,
  },
  rule: {
    baseTextbook: "rule/textbook",
    textbook: "/rule/textbook",
    curriculum: "/rule/curriculum",
    textbookUnit: "/rule/textbook/unit",
    curriculumUnit: "/rule/curriculum/unit",
  },
  stats: {
    question: "/stats/question",
    textbook: "/stats/textbook",
    autoClassification: "/stats/auto-classification",
    inspection: "/stats/inspection",
    autoClassificationQuestion: "/stats/auto-classification/question",
    baseAutoClassificationQuestionId: "/stats/auto-classification/question/:question_id",
    autoClassificationQuestionId: (questionId: string) => `/stats/auto-classification/question/${questionId}`,
    autoClassificationCurriculumQuestion: "/stats/auto-classification/curriculum/question",
    baseAutoClassificationCurriculumQuestionId: "/stats/auto-classification/curriculum/question/:question_id",
    autoClassificationCurriculumQuestionId: (questionId: string) => `/stats/auto-classification/curriculum/question/${questionId}`,
  },
};

export const MENU = {
  admin: [
    { name: "Dashboard", path: "dashboard", icon: DashboardIcon, subMenus: [] },
    {
      name: "분류규칙 설정",
      path: "rule",
      icon: RulesIcon,
      subMenus: [
        { name: "교과서별 (수학 외 과목)", path: "textbook" },
        { name: "표준커리큘럼별 (수학)", path: "curriculum" },
      ],
    },
    {
      name: "기출 문제 분류",
      path: "question",
      icon: QuestionsIcon,
      subMenus: [
        { name: "교과서별 (수학 외 과목)", path: "textbook" },
        { name: "표준커리큘럼별 (수학)", path: "curriculum" },
      ],
    },
    {
      name: "검수",
      path: "inspection",
      icon: CheckIcon,
      subMenus: [
        { name: "교과서별 (수학 외 과목)", path: "textbook" },
        { name: "표준커리큘럼별 (수학)", path: "curriculum" },
      ],
    },
    {
      name: "검색 시뮬레이션",
      path: "simulation",
      icon: SimilarDocsIcon,
      subMenus: [
        { name: "유사문항 검색", path: "similar" },
        { name: "벌크 테스트", path: "bulk" },
      ],
    },
    {
      name: "통계",
      path: "stats",
      icon: StatisticsIcon,
      subMenus: [
        { name: "기출문항 등록 통계", path: "question" },
        { name: "교과서 등록 통계", path: "textbook" },
        { name: "자동분류 통계", path: "auto-classification" },
        { name: "검수 통계", path: "inspection" },
      ],
    },
  ],
  common: [
    { name: "Dashboard", path: "dashboard", icon: DashboardIcon, subMenus: [] },
    {
      name: "검수",
      path: "inspection",
      icon: CheckIcon,
      subMenus: [
        { name: "교과서별 (수학 외 과목)", path: "textbook" },
        { name: "표준커리큘럼별 (수학)", path: "curriculum" },
      ],
    },
    {
      name: "통계",
      path: "stats",
      icon: StatisticsIcon,
      subMenus: [
        { name: "기출문항 등록 통계", path: "question" },
        { name: "교과서 등록 통계", path: "textbook" },
        { name: "자동분류 통계", path: "auto-classification" },
        { name: "검수 통계", path: "inspection" },
      ],
    },
  ],
};

export const RULE_SAVE_OPTIONS: DropdownItemType[] = [
  { value: { classification_status: "waiting" }, label: "미분류 기출문제" },
  { value: { is_completed: 0 }, label: "기출문제 전체(분류완료, 검수대기 상태 포함)" },
];

export const PAGINATION_SIZE_OPTIONS: DropdownItemType[] = [
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
];

export const INSPECTION_STATUS_OPTIONS: DropdownItemType[] = [
  { value: "waiting", label: "검수대기" },
  { value: "inspected", label: "검수완료" },
  { value: "hold", label: "검수보류" },
];

export const INSPECTION_STATUS_WITH_NONE_OPTIONS: DropdownItemType[] = [...INSPECTION_STATUS_OPTIONS, { value: "none", label: "미검수" }];

export const QUESTION_SEARCH_OPTIONS: DropdownItemType[] = [
  {
    value: "all",
    label: "전체",
    placeHolder: "학교정보(ID,이름,지역), 교과서정보(교과서 및 단원의 ID, 이름, 페이지), 문항정보(시험지ID, 지문·문제 내용) 검색",
  },
  {
    value: "school",
    label: "학교 정보",
    placeHolder: "학교정보(ID,이름,지역) 검색",
  },
  {
    value: "question",
    label: "문항 정보",
    placeHolder: "시험지ID, 지문·문제 내용 검색",
  },
  {
    value: "textbook",
    label: "교과서 정보",
    placeHolder: "교과서 및 단원의 ID, 이름, 페이지 검색",
  },
];

export const CURRICULUM_QUESTION_SEARCH_OPTIONS: DropdownItemType[] = [
  { value: "all", label: "전체" },
  { value: "school", label: "학교 정보" },
  { value: "question", label: "문항 정보" },
  { value: "curriculum", label: "표준커리큘럼 정보" },
];

export const CLASSIFICATION_STATUS_OPTIONS: DropdownItemType[] = [
  { value: "waiting", label: "분류대기" },
  { value: "completed", label: "분류완료" },
  { value: "failed", label: "분류실패" },
];

export const CLASSIFICATION_TYPE_OPTIONS: DropdownItemType[] = [
  { value: "unit", label: "교과서" },
  { value: "question", label: "기출" },
  { value: "rule", label: "분류규칙" },
];

export const LEVEL_OPTIONS: DropdownItemType[] = [
  { value: "초등학교", label: "초등학교" },
  { value: "중학교", label: "중학교" },
  { value: "고등학교", label: "고등학교" },
];

export const LEVEL_OPTIONS_NUMBER: DropdownItemType[] = [
  { value: 1, label: "초등학교" },
  { value: 2, label: "중학교" },
  { value: 3, label: "고등학교" },
];

export const COURSE_NAME_OPTIONS: DropdownItemType[] = [
  { value: "초등", label: "초등" },
  { value: "중등", label: "중등" },
  { value: "고등", label: "고등" },
];

export const QUESTION_SCORE_RANGE_OPTIONS: DropdownItemType[] = [
  { value: 0.9, label: "90점 이상" },
  { value: 0.8, label: "80점 이상" },
  { value: 0.7, label: "70점 이상" },
  { value: 0.6, label: "60점 이상" },
  { value: 0.5, label: "50점 이상" },
  { value: 0.4, label: "40점 이상" },
  { value: 0.3, label: "30점 이상" },
  { value: 0.2, label: "20점 이상" },
  { value: 0.1, label: "10점 이상" },
];

export const MINIMUM_SHOULD_MATCH_DROPDOWN_OPTIONS: DropdownItemType[] = [
  { value: "5%", label: "한 개 이상 매치" },
  { value: "30%", label: "절반 이상 키워드 매치" },
  { value: "80%", label: "대부분의 키워드 매치" },
];

export const CLASSIFICATION_CONSISTENCY_OPTIONS: DropdownItemType[] = [
  { value: "new", label: "신규분류" },
  { value: "unclassifiable", label: "분류불가" },
  { value: "waiting", label: "검수대기" },
  { value: "clear", label: "이상없음 확인" },
  { value: "corrected", label: "분류수정" },
];

export const GRADE_SEMESTER_OPTIONS: DropdownItemType[] = [
  { value: "1-1", label: "1학년 1학기" },
  { value: "1-2", label: "1학년 2학기" },
  { value: "2-1", label: "2학년 1학기" },
  { value: "2-2", label: "2학년 2학기" },
  { value: "3-1", label: "3학년 1학기" },
  { value: "3-2", label: "3학년 2학기" },
];

export const MINIMUM_SHOULD_MATCH_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "5%", kor: "한 개 이상 매치" },
  { eng: "30%", kor: "절반 이상 키워드 매치" },
  { eng: "80%", kor: "대부분의 키워드 매치" },
];

export const JOB_TYPE_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "CFA", kor: "자동분류" },
  { eng: "CFR", kor: "자동분류(규칙)" },
  { eng: "UISW", kor: "상태변경/취소" },
  { eng: "UISN", kor: "상태변경" },
];

export const INSPECTION_STATUS_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "none", kor: "미검수" },
  { eng: "waiting", kor: "검수대기" },
  { eng: "inspected", kor: "검수완료" },
  { eng: "hold", kor: "검수보류" },
];

export const CLASSIFICATION_TYPE_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "unit", kor: "교과서" },
  { eng: "question", kor: "기출" },
  { eng: "rule", kor: "분류규칙" },
];

export const DIFFICULTY_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "CD12398", kor: "1" },
  { eng: "CD12399", kor: "2" },
  { eng: "CD12400", kor: "3" },
  { eng: "CD12401", kor: "4" },
  { eng: "CD12402", kor: "5" },
  { eng: "CD12403", kor: "6" },
];

export const CLASSIFICATION_STATUS_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "waiting", kor: "분류대기" },
  { eng: "completed", kor: "분류완료" },
  { eng: "failed", kor: "분류실패" },
];

export const COURSE_NM_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "1", kor: "초등학교" },
  { eng: "2", kor: "중학교" },
  { eng: "3", kor: "고등학교" },
];

export const UNIT_FILTER_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "subject.keyword", kor: "과목" },
  { eng: "publisher.keyword", kor: "출판사" },
  { eng: "textbook.keyword", kor: "교과서" },
  { eng: "unit_name", kor: "결과단원" },
  { eng: "unitCode", kor: "단원ID" },
  { eng: "modified_dt", kor: "최종수정일" },
  { eng: "checker_name.keyword", kor: "검수자" },
  { eng: "modifier_name.keyword", kor: "최종수정자" },
  { eng: "level", kor: "학교급" },
];

export const MATH_CURRICULUM_UNIT_FILTER_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "semesterNm.keyword", kor: "학기" },
  { eng: "grade_name.keyword", kor: "학년" },
  { eng: "unit_name", kor: "표준커리큘럼" },
  { eng: "course_name", kor: "학교급" },
  { eng: "curriculumDetailCode", kor: "표준커리큘럼ID" },
  { eng: "modified_dt", kor: "최종수정일" },
  { eng: "checker_name.keyword", kor: "검수자" },
  { eng: "modifier_name.keyword", kor: "최종수정자" },
];

export const QUESTION_FILTER_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "subjectNm", kor: "과목" },
  { eng: "grade_semester_test", kor: "시험종류" },
  { eng: "year", kor: "기출연도" },
  { eng: "revised_year", kor: "개정연도" },
  { eng: "classification_status", kor: "분류상태" },
  { eng: "checker_type", kor: "분류근거" },
  { eng: "inspection_status", kor: "검수상태" },
  { eng: "modifier_name", kor: "최종수정자" },
  { eng: "modified_dt", kor: "최종수정일" },
  { eng: "all", kor: "전체" },
  { eng: "score", kor: "정확도" },
  { eng: "question", kor: "문항 정보" },
  { eng: "textbook", kor: "교과서 정보" },
  { eng: "school", kor: "학교 정보" },
  { eng: "id", kor: "문항 ID" },
  { eng: "textbookSb", kor: "교과서" },
  { eng: "publisher", kor: "출판사" },
  { eng: "course_name", kor: "학교급" },
];

export const CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "new", kor: "신규분류" },
  { eng: "unclassifiable", kor: "분류불가" },
  { eng: "waiting", kor: "검수대기" },
  { eng: "clear", kor: "이상없음 확인" },
  { eng: "corrected", kor: "분류수정" },
];

export const CLASSIFICATION_STATS_FILTER_ENG_KOR_MAPPING: EngKorMapping[] = [
  { eng: "courseNm", kor: "학교급" },
  { eng: "subjectNm", kor: "과목" },
  { eng: "textbookSb", kor: "출판사 및 교과서" },
  { eng: "revised_year", kor: "개정연도" },
  { eng: "year", kor: "기출연도" },
  { eng: "grade_semester_test", kor: "시험종류" },
  { eng: "query", kor: "지역명, 학교명" },
  { eng: "score", kor: "정확도" },
  { eng: "grade_semester", kor: "학년-학기" },
  { eng: "unit_name", kor: "표준커리큘럼" },
];

export const DEV_ONLY_ROUTER_LIST: string[] = [
  "dashboard",
  "rule/curriculum",
  "question/curriculum",
  "inspection/curriculum",
  "simulation",
  "stats/question",
  "stats/textbook",
  "stats/auto-classification",
  "stats/inspection",
];

export const TEXBOOK_NAME_LIST: string[] = ["국어", "영어", "과학", "사회", "역사"];
