import { makeAutoObservable } from "mobx";

import repository from "repositories/Stats";
import { GRADE_SEMESTER_OPTIONS, LEVEL_OPTIONS } from "constant";
import { DropdownItemType, Scalable, StoreState } from "types/common";
import { ClassificationStatItem } from "types/stats/model";
import { GetClassificationStatsRequest, GetMathClassificationStatsRequest } from "types/stats/schema";

function convertCourseNm(course: string) {
  switch (course) {
    case "초등학교":
      return 1;

    case "중학교":
      return 2;

    case "고등학교":
      return 3;
  }
}

function convertGradeSemester(gradeSemester: string) {
  const [grade, semester] = gradeSemester.split("-");
  const result = `${grade}학년 ${semester}학기`;

  return result;
}

interface Total {
  inspection_status?: {
    key: "none" | "waiting" | "inspected" | "hold" | "sum" | "wating";
    count: number;
  }[];
  key?: string;
  doc_count?: number;
}

interface Filters {
  [index: string]: DropdownItemType | Scalable | string | undefined;
  // 공통
  courseNm?: DropdownItemType;
  year?: DropdownItemType;
  query?: string;
  score?: Scalable;
  grade_semester_test?: DropdownItemType;
  // 수학 외 과목
  subjectNm?: DropdownItemType;
  textbookSb?: DropdownItemType;
  revised_year?: DropdownItemType;
  // 수학
  grade_semester?: DropdownItemType;
  unit_name?: string;
}

export default class AutoClassificationStatsList {
  state: StoreState = "none";
  total: Total = {};
  list: ClassificationStatItem[] = [];
  field: "textbook" | "curriculum" = "textbook";
  filters: Filters = {};
  mathFilters: Filters = {
    courseNm: LEVEL_OPTIONS[1],
    grade_semester: GRADE_SEMESTER_OPTIONS[0]
  };
  page: number = 1;
  size: number = 50;
  resetSwitch: boolean = false;
  loading: boolean = true;
  fieldSwitchFlag: boolean = false; // field 토글시에 필터 초기화가 되지 않도록 하기 위해 선언한 변수. 이 값이 true면 Filters안의 useEffect가 실행 X
  filteredOptions: DropdownItemType[] = [];
  selectedTotalCount: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async getAutoClassificationStats() {
    this.list = [];
    this.state = "pending";

    try {
      const { courseNm, subjectNm, textbookSb, revised_year, year, grade_semester_test, query, score } = { ...this.filters };

      const payload: GetClassificationStatsRequest = {
        courseNm: courseNm ? convertCourseNm(courseNm.label) : undefined,
        subjectNm: subjectNm ? subjectNm.value : undefined,
        textbookSb: textbookSb ? textbookSb.value : undefined,
        revised_year: revised_year ? revised_year.value : undefined,
        year: year ? year.value : undefined,
        grade_semester_test: grade_semester_test ? grade_semester_test.value : undefined,
        query: query ? query : undefined,
        score: score ? score : undefined,
        from: (this.page - 1) * this.size,
        size: this.size,
      };

      const response = await repository.getClassificationStats(payload);
      this.total = response.data.response.find((item: any) => item.key === "total");
      this.list = response.data.response.filter((item: any) => item.key !== "total");
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  async getMathClassificationStats() {
    this.list = [];
    this.state = "pending";

    try {
      const { courseNm, grade_semester, unit_name, year, grade_semester_test, query, score } = { ...this.mathFilters };

      const payload: GetMathClassificationStatsRequest = {
        grade_semester: grade_semester ? grade_semester.value : undefined,
        unit_name: unit_name,
        courseNm: courseNm ? convertCourseNm(courseNm.label) : undefined,
        year: year ? year.value : undefined,
        query: query ? query : undefined,
        score: score ? score : undefined,
        grade_semester_test: grade_semester_test ? grade_semester_test.value : undefined,
        from: (this.page - 1) * this.size,
        size: this.size,
      };

      const response = await repository.getMathClassificationStats(payload);
      this.total = response.data.response.find((item: any) => item.key === "total");
      this.list = response.data.response.filter((item: any) => item.key !== "total");
      this.list = this.list.map((item) => ({ ...item, grade_semester: convertGradeSemester(item.grade_semester) }));
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  search() {
    this.state = "none";
  }

  setFilters(filterName: string, value?: DropdownItemType | Scalable | string) {
    if (value) {
      if (this.field === "textbook") this.filters[filterName] = value;
      else this.mathFilters[filterName] = value;
    } else {
      if (filterName === "query") {
        if (this.field === "textbook") this.filters.query = "";
        else this.mathFilters.query = "";
      }
    }
  }

  popFilter(field: string) {
    if (this.field === "textbook") delete this.filters[field];
    else delete this.mathFilters[field];
  }

  turnResetSwitchOff() {
    this.resetSwitch = false;
  }

  setField(field: "textbook" | "curriculum") {
    this.field = field;
  }

  setPage(page: number) {
    this.page = page;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  setFieldSwitchFlag(flag: boolean) {
    this.fieldSwitchFlag = flag;
  }

  setFilteredOptions(options: DropdownItemType[]) {
    this.filteredOptions = options;
  }

  setSelectedTotalCount(count: number) {
    this.selectedTotalCount = count;
  }

  reset() {
    this.filters = {};
    this.page = 1;
  }

  mathReset() {
    this.mathFilters = {};
  }

  clear() {
    this.page = 1;
    this.total = {};
    this.list = [];
    this.field = "textbook";
    this.filters = {};
    this.state = "none";
  }
}
