import Base from "repositories";
import {FacetRequest} from "types/common";
import {
  QuestionReadManyRequest,
  QuestionSearchRequest, QuestionSemanticsSearchRequest,
  QuestionSimilarSearchRequest,
  QuestionUpdateRequest
} from "types/question/schema";

class Question extends Base {
  constructor() {
    super({ prefix: "/question" });
  }

  async readMany(payload: QuestionReadManyRequest) {
    return this.axiosInstance.post(``, payload);
  }

  async read(questionId: string) {
    return this.axiosInstance.get(`${questionId}`);
  }

  async readFacets(payload: FacetRequest) {
    return this.axiosInstance.post(`/facets`, payload);
  }

  async readSemanticsFacets(payload: FacetRequest) {
    return this.axiosInstance.post(`/semantics/facets`, payload);
  }

  async update(payload: QuestionUpdateRequest) {
    return this.axiosInstance.put(``, payload);
  }

  async readSimilarMany(payload: QuestionSimilarSearchRequest) {
    return this.axiosInstance.post('/search/similar', payload);
  }

  async search(payload: QuestionSearchRequest) {
    return this.axiosInstance.post('/search', payload);
  }

  async readSemanticsMany(payload: QuestionSemanticsSearchRequest) {
    return this.axiosInstance.post('/search/semantics', payload);
  }

  async analyzeClassificationResult(questionId: string) {
    return this.axiosInstance.post(`/classification/${questionId}/analyze`);
  }

  async download(fileType: string, payload: QuestionReadManyRequest) {
    return this.axiosInstance.post(
      `/download/${fileType}`,
      payload,
      {
      responseType: "blob"
    });
  }

  async downloadSemantics(fileType: string, payload: QuestionSemanticsSearchRequest) {
    return this.axiosInstance.post(
      `/semantics/download/${fileType}`,
      payload,
      {
        responseType: "blob"
      });
  }

}

export default new Question();
