import {useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

import {DropdownItemType} from "types/common";
import { SimpleUser } from "types/user/schema";

import Dropdown from "components/Dropdown";
import Checkbox from "components/Checkbox";


const RequestInspectionModal = observer(() => {
  const {authentication, unitList} = useStores();
  const [selectedUser, setSelectedUser] = useState<DropdownItemType>();
  const [checked, setChecked] = useState(false);
  const modalId = "requestInspectionModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async() => {
    if (!selectedUser) return;
    const user: SimpleUser = authentication.users!.filter(u => u.email === selectedUser!.value)[0]
    if (mode === 2 && !checked) {
      await unitList.setUnitStorage(unitList.unitStorage.filter(u => !u.checker_email))
    }

    if (
      await handler.handle(
        unitList.updateMany(
          {
            unit_codes: unitList.unitStorage.map(u => u.unitCode),
            values: {
              checker_email: user.email,
              checker_name: user.name
            }
          }
        )
      )
    ) return;
    toast.fireToast({
      type: "success",
      content: toastMessage.success.requestInspection,
      duration: 5000,
    });
    setChecked(false);
    setSelectedUser(undefined);
    await unitList.clear();
  };

  const userOptions = useMemo(() => {
    return authentication.users?.map(u => {return {"value": u.email, "label": u.name}})
  }, [authentication.users])

  const mode = useMemo(() => {
    if (unitList.unitStorage.every(u => !u.checker_email))
      return 1
    if (unitList.unitStorage.every(u => u.checker_email))
      return 3
    return 2
    // eslint-disable-next-line
  }, [unitList.unitStorage.length])


  return (
    <MaruModal
      id={modalId}
      title="검수요청"
      withCancel
      disableOkay={!selectedUser}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {mode === 1 && <NoAssignedIncluded count={unitList.unitStorage.length}/>}
        {mode === 2 && <AssignedIncluded count={unitList.unitStorage.length} checked={checked}/>}
        {mode === 3 && <AllAssignedIncluded count={unitList.unitStorage.length}/>}

        <CheckerDropdown
          options={userOptions!}
          handleSelect={setSelectedUser}
          value={selectedUser}
          placeholder="검수자"
        />
      </ModalInnerPanel>

      {mode === 2 && (
        <CheckboxWrapper>
          <Checkbox
            style={{ marginRight: "8px" }}
            checked={checked}
            handleClick={() => setChecked(!checked)}
          />
          검수자가 이미 지정된 단원을 포함하여 검수요청
        </CheckboxWrapper>
      )}
    </MaruModal>
  );
});

interface RequestInspectionProps {
  count: number;
  checked?: boolean;
}

const NoAssignedIncluded = ({count}: RequestInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 단원</b>에 대해 다음 검수자에게 검수요청을 합니다.
  </Paragraph>
);
const AssignedIncluded = observer(({count, checked}: RequestInspectionProps) => {
  const {unitList} = useStores();

  const unWaitingQuestionCount = useMemo(() => {
    return unitList.unitStorage.filter(u => u.checker_email).length
  }, [unitList.unitStorage])

  return (
      <Paragraph>
        검수자가 지정된 <b>{unWaitingQuestionCount}개 단원</b>을 {checked?"포함한 ":"제외한 "}
        <b>{checked?count:count-unWaitingQuestionCount}개 단원</b>을<br />
        다음 검수자에게 검수요청 합니다.
      </Paragraph>
  )
});
const AllAssignedIncluded = ({count}: RequestInspectionProps) => (
  <Paragraph>
    검수자가 지정된 <b>{count}개 단원</b>을 다음 검수자에게 검수요청 합니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 0 4px;

  font-size: 13px;
  color: ${(props) => props.theme.text.BLACK};
`;
const CheckerDropdown = styled(Dropdown)`
  width: 260px;
  margin-top: 8px;
`;

export default RequestInspectionModal;
