import styled from "styled-components";

import { DropdownItemType } from "types/common";

import Dropdown from "components/Dropdown";

interface PaginationSizeSelectorProps {
  options: DropdownItemType[];
  total: number;
  value: DropdownItemType;
  handleSelect?: (selectedItem: DropdownItemType) => void;
}

const PaginationSizeSelector = ({
  options,
  total,
  handleSelect,
  value,
}: PaginationSizeSelectorProps) => {
  const handleItemSelect = (item?: DropdownItemType) => {
    handleSelect && handleSelect(item!);
  };

  return (
    <PaginationSizeSelectorStyle>
      <Showing>Showing</Showing>
      <SizeDropdown
        handleSelect={handleItemSelect}
        options={options}
        value={value}
      />
      of {total} items
    </PaginationSizeSelectorStyle>
  );
};

const PaginationSizeSelectorStyle = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;
  color: ${(props) => props.theme.text.MAIN};
`;

const Showing = styled.div`
  margin-right: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.text.GREY};
`;
const SizeDropdown = styled(Dropdown)`
  margin-right: 8px;
`;

export default PaginationSizeSelector;
