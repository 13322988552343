import { makeAutoObservable } from "mobx";
import _ from "lodash";

import repository from "repositories/Stats";
import { DropdownItemType, Scalable, StoreState } from "types/common";
import { StatBucket, StatItem } from "types/stats/model";
import { GetQuestionStatsRequest } from "types/stats/schema";
import { toISOStringCustom } from "utils";

const NOW = new Date();
const YEAR_AGO = new Date(new Date().setHours(-24 * 365));
const ORDERS = ["1-1", "1-2", "2-1", "2-2", "3-1", "3-2"];

interface Filters {
  [index: string]: DropdownItemType | Scalable | undefined;
  courseNm: DropdownItemType;
  year?: DropdownItemType;
  created_date: Scalable;
}

export default class QuestionStatList {
  state: StoreState = "none";
  // 응답으로 온 항목 중 key: "total" 인 경우를 저장
  total: StatBucket = {
    key: "total",
    bucket: [],
  };
  // 응답 중 key: "total" 이외의 나머지 경우를 저장
  list: StatBucket[] = [];
  field: "subjectNm" | "grade_semester" = "subjectNm";
  filters: Filters = {
    courseNm: { value: 2, label: "중학교" },
    created_date: {
      gte: toISOStringCustom(YEAR_AGO),
      lte: toISOStringCustom(NOW),
    },
  };
  page: number = 1;
  size: number = 50;
  resetSwitch: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getQuestionStats() {
    this.state = "pending";

    try {
      const { courseNm, year, created_date } = { ...this.filters };

      const payload: GetQuestionStatsRequest = {
        field: this.field,
        courseNm: courseNm.value,
        year: year ? year.value : undefined,
        created_date: {
          gte: created_date.gte.replaceAll(".", "-"),
          lte: created_date.lte.replaceAll(".", "-"),
        },
        from: (this.page - 1) * this.size,
        size: this.size,
      };

      const response = await repository.getQuestionStats(payload);

      let keys: string[] = []; // 과목 또는 학기 별 key들을 뜻함 예) 과학, 수학, 1-1, 1-2, ...
      // 현재 탭이 학기별인 경우
      if (this.field === "grade_semester") {
        // 응답에서 total을 가져온다
        this.total = response.data.response.find((el: StatBucket) => el.key === "total");

        // doc_count를 0으로 초기화하여 설정
        if (this.total.bucket.length !== 0) {
          const rawTotal: StatItem[] = response.data.response.find((el: StatBucket) => el.key === "total").bucket;
          this.total = { key: "total", bucket: ORDERS.map((order) => rawTotal.find((el) => el.key === order) ?? { key: order, doc_count: 0 }) };
        }

        // total을 제외한 나머지는 this.list에 저장
        this.list = response.data.response.filter((el: StatBucket) => el.key !== "total");
        // keys를 따로 추출
        keys = ORDERS.map((order) => this.total.bucket.map((el) => el.key).find((key) => key === order) ?? "");
      } else {
        this.total = response.data.response.find((el: StatBucket) => el.key === "total");
        this.list = response.data.response.filter((el: StatBucket) => el.key !== "total");
        keys = this.total.bucket.map((el) => el.key);
      }

      const sample: StatItem[] = [];
      keys.forEach((key) => {
        sample.push({ key: key, doc_count: 0 });
      });

      for (let i = 0; i < this.list.length; i++) {
        const tempArr = _.cloneDeep(sample);
        const item = this.list[i];

        keys.forEach((key) => {
          const targetIndex = item.bucket.findIndex((el) => el.key === key);

          if (targetIndex !== -1) {
            const target = tempArr.find((el) => el.key === key);

            if (target) {
              target.doc_count = item.bucket[targetIndex].doc_count;
            }
          }
        });

        this.list[i].bucket = tempArr;
      }
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  search() {
    this.state = "none";
  }

  setFilters(filterName: string, value: DropdownItemType | Scalable) {
    this.filters[filterName] = value;
  }

  turnResetSwitchOff() {
    this.resetSwitch = false;
  }

  setField(field: "subjectNm" | "grade_semester") {
    this.field = field;
  }

  setPage(page: number) {
    this.page = page;
  }

  reset() {
    this.filters = {
      courseNm: { value: 2, label: "중학교" },
      created_date: {
        gte: toISOStringCustom(YEAR_AGO),
        lte: toISOStringCustom(NOW),
      },
    };
    this.page = 1;
    this.resetSwitch = true;
  }

  clear() {
    this.total = {
      key: "total",
      bucket: [],
    };
    this.list = [];
    this.page = 1;
    this.field = "subjectNm";
    this.filters = {
      courseNm: { value: 2, label: "중학교" },
      created_date: {
        gte: toISOStringCustom(YEAR_AGO),
        lte: toISOStringCustom(NOW),
      },
    };
    this.state = "none";
  }
}
