import styled from "styled-components";

import CheckIcon from "assets/icons/CheckIcon";
import CancelIcon from "assets/icons/CancelIcon";
import ReturnIcon from "assets/icons/ReturnIcon";

interface ModalAddonProps {
  mode: "success" | "fail" | "postpone";
}

const ModalAddon = ({ mode }: ModalAddonProps) => {
  return (
    <ModalAddonStyle>
      <Addon mode={mode}>
        {mode === "success" && <CheckIcon size={64} />}
        {mode === "fail" && <CancelIcon size={64} color="white" />}
        {mode === "postpone" && <ReturnIcon size={64} />}
      </Addon>
    </ModalAddonStyle>
  );
};

const ModalAddonStyle = styled.div`
  position: absolute;
  top: -100px;
  right: 0;

  display: flex;
  justify-content: center;
  width: 100%;
`;

const Addon = styled.div<{ mode: "success" | "fail" | "postpone" }>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 120px;
  height: 120px;

  border-radius: 9999px;
  background: ${(props) =>
    props.mode === "success"
      ? props.theme.background.SUCCESS
      : props.mode === "fail"
      ? props.theme.background.DANGER
      : props.theme.background.LIGHT_GREY};

  font-size: 20px;
`;

export default ModalAddon;
