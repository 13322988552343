import styled from "styled-components";


interface InspectionStatusTextProps
  extends React.HTMLAttributes<HTMLDivElement> {
  status: string;
}

const InspectionStatusText = ({
  status,
  children,
  ...props
}: InspectionStatusTextProps) => {
  return (
    <InspectionStatusTextStyle status={status} {...props}>
      {children}
    </InspectionStatusTextStyle>
  );
};

const InspectionStatusTextStyle = styled.span<{ status: string }>`
  font-weight: 500;
  color: ${(props) => {
    switch (props.status) {
      case "inspected":
        return props.theme.text.SUCCESS;
      case "hold":
        return props.theme.text.GREY;
      case "waiting":
        return props.theme.text.DANGER;
    }
  }};
`;

export default InspectionStatusText;
