import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/MathCurriculumUnit";
import {StoreState} from "types/common";
import {MathCurriculumUnit} from "types/unit/math/model"

const MATH_CURRICULUM_UNIT_SEARCH_MODE = {
  DEFAULT: "default",
  REGEX: "regex",
  RULE: "rule"
} as const;

export type MathCurriculumUnitSearchMode = typeof MATH_CURRICULUM_UNIT_SEARCH_MODE[keyof typeof MATH_CURRICULUM_UNIT_SEARCH_MODE];

export default class MathCurriculumUnitSearch {
  state: StoreState = "none";
  units?: MathCurriculumUnit[] = [];
  detail?: MathCurriculumUnit;
  mode: MathCurriculumUnitSearchMode = "default";
  query: string = "";
  searchedQuery?: string;
  hasNext: boolean = true;
  total?: number;
  page: number = 1;
  size: number = 20;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany() {
    this.state = "pending";
    try {

      if (_.isUndefined(this.searchedQuery)) {
        this.units = [];
        this.searchedQuery = "";
        this.state = "ready";
        return;
      } else this.searchedQuery = this.query;

      if (this.mode === "rule") await this.simulate();
      else {
        const response = await repository.searchUnit({
          query: this.query,
          mode: this.mode,
          page: this.page,
          size: this.size
        });
        this.units = response.data.units;
        this.total = response.data.total;
      }

      this.hasNext = this.units!.length !== this.total;

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async simulate() {

  }

  async nextScroll() {
    this.page = this.page + 1;
    if (this.mode === "rule") await this.simulate();
    else {
      const response = await repository.searchUnit({
        query: this.query,
        mode: this.mode,
        page: this.page,
        size: this.size
      });
      this.units!.push(...response.data.units);
      this.total = response.data.total;
    }
    this.hasNext = this.units!.length !== this.total;
  }

  async setQuery(query: string) {
    this.query = query;
  }

  async setDetail(unit: MathCurriculumUnit) {
    this.detail = unit;
  }

  async setMode(mode: MathCurriculumUnitSearchMode) {
    this.mode = mode;
  }

  async ready() {
    this.query = "";
    this.searchedQuery = undefined;
    await this.search();
  }

  async clear() {
    this.state = "none";
    this.total = undefined;
    this.units = [];
    this.mode = "default"
    this.query = "";
    this.searchedQuery = undefined;
    this.detail = undefined;
    this.size = 20;
    this.page = 1;
    this.hasNext = true;
  }

  async search() {
    this.page = 1;
    this.state = "none";
  }
}
