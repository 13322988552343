import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

import ModalAddon from "components/ModalAddon";

const CompleteCurriculumInspectionModal = observer(() => {
  const {mathQuestionList, activeMathCurriculumUnit} = useStores();
  const modalId = "completeCurriculumInspectionModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async () => {
    if (mode === 3) return;
    if (
      await handler.handle(
        mathQuestionList.updateMany(
          {
            question_ids: mathQuestionList.questionStorage.map(q => q._id),
            values: {
              inspection_status: "inspected"
            }
          }
        )
      )
    ) return;
    toast.fireToast({
      type: "success",
      content: toastMessage.success.saveInspection,
      duration: 5000,
    });
    await activeMathCurriculumUnit.reSet();
    await mathQuestionList.search();
  };

  const mode = useMemo(() => {
    if (mathQuestionList.questionStorage.every(u => u.inspection_status === "waiting"))
      return 1
    if (mathQuestionList.questionStorage.every(u => u.inspection_status !== "waiting"))
      return 3
    return 2
    // eslint-disable-next-line
  }, [mathQuestionList.questionStorage.length])

  return (
    <MaruModal
      id={modalId}
      title="검수하기"
      withCancel={mode !== 3}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {mode === 1 && <OnlyUnchecked count={mathQuestionList.questionStorage.length}/>}
        {mode === 2 && <Mixed count={mathQuestionList.questionStorage.length} unit_name={activeMathCurriculumUnit.unit?.unit_name}/>}
        {mode === 3 && <OnlyChecked />}
      </ModalInnerPanel>

      <ModalAddon mode="success" />
    </MaruModal>
  );
});

interface CompleteCurriculumInspectionProps {
  count: number;
  unit_name?: string;
}

const OnlyUnchecked = ({count}: CompleteCurriculumInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b>을 <em>검수완료</em> 처리합니다.
  </Paragraph>
);
const Mixed = ({count, unit_name}: CompleteCurriculumInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b>의 분류 결과를 <br />{" "}
    <b>{unit_name} 단원</b>으로 <br /> 저장합니다.
  </Paragraph>
);
const OnlyChecked = () => (
  <Paragraph>
    이미 <em>검수완료</em> 처리된 문항입니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    color: ${(props) => props.theme.text.SUCCESS};
  }
`;

export default CompleteCurriculumInspectionModal;
