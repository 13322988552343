import _ from "lodash";
import {makeAutoObservable} from "mobx";

import repository from "repositories/Unit";

import {StoreState} from "types/common";
import {Unit} from "types/unit/model"

import {isInRightRange} from "utils";


export default class ActiveUnit {
  state: StoreState = "none";
  storageId: string = "unitCode";
  unit?: Unit;
  rank1UnitCode?: string;
  unitsByPage: Unit[] = [];
  unitByPage?: Unit;
  page?: number;

  constructor() {
    makeAutoObservable(this);
  }

  async getCode() {
    return localStorage.getItem(this.storageId);
  }

  async readySet() {
    this.state = "pending"
  }

  async set(unit?: Unit) {
    this.state = "pending";
    if (unit) {
      this.page = unit.start_page
      localStorage.setItem(this.storageId, unit.unitCode);
      this.unit = _.clone(unit);
      const response = await repository.readByPage(unit.unitCode);
      this.unitsByPage = response.data.units;
      this.unitsByPage = this.unitsByPage.sort((a, b) => {
        return a.page!.reduce((acc, cur, idx) => { return acc += cur; }, 0) -
          b.page!.reduce((acc, cur, idx) => { return acc += cur; }, 0)
      })
    } else {
      localStorage.removeItem(this.storageId);
      this.unit = undefined;
    }
    this.state = "done";
  }

  async setRank1UnitCode(unitCode?: string) {
    if (!unitCode) return;
    this.rank1UnitCode = unitCode;
  }

  async setUnitCode(unitCode: string) {
    if (unitCode) localStorage.setItem(this.storageId, unitCode);
    else localStorage.removeItem(this.storageId);
  }

  async setPage(page: number) {
    if (isInRightRange(page, this.unit!.start_page!, this.unit!.end_page!)) {
      this.page = page;
    }
  }

  getUnitByPage() {
    const unit = this.unitsByPage.filter(u => u.page?.includes(this.page!));
    return !!unit.length? unit[0]: this.unitsByPage[0];
  }

  async reSet() {
    this.state = "none";
  }

  async clear() {
    this.state = "none";
    this.unit = undefined;
    this.rank1UnitCode = undefined;
    this.unitsByPage = [];
    this.unitByPage = undefined;
    this.page = undefined;
  }
}