import Base from "repositories";
import {
  UnitReadManyRequest,
  UnitSearchRequest,
  UnitSelectRequest,
  UnitUpdateRequest
} from "types/unit/schema";
import {FacetRequest} from "types/common";

class Unit extends Base {
  constructor() {
    super({ prefix: "/unit" });
  }

  async readMany(payload: UnitReadManyRequest) {
    return this.axiosInstance.post(``, payload);
  }

  async readFacets(payload: FacetRequest) {
    return this.axiosInstance.post('/facets', payload);
  }

  async update(payload: UnitUpdateRequest) {
    return this.axiosInstance.put(``, payload);
  }

  async read(unitCode: string) {
    return this.axiosInstance.get(`${unitCode}`);
  }

  async readByPage(unitCode: string) {
    return this.axiosInstance.get(`/${unitCode}/page`);
  }

  async readSelectMany(payload: UnitSelectRequest) {
    return this.axiosInstance.post('/select', payload);
  }

  async searchUnit(payload: UnitSearchRequest) {
    return this.axiosInstance.post('/search', payload);
  }

}

export default new Unit();
