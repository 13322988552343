import {useMemo} from "react";
import { useToast } from "hooks/useToast";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import {useErrorHandler} from "hooks/useErrorHandler";

import {RULE_SAVE_OPTIONS} from "constant";
import { toastMessage } from "messages";

import {RuleSimulationRequest} from "types/rule/schema";
import {Rule} from "types/rule/model";


const RuleApplyCurriculumModal = observer(() => {
  const {mathRuleSimulation, mathRuleList, job, activeMathCurriculumUnit} = useStores();
  const modalId = "ruleApplyCurriculumModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async () => {
    let rule: Rule | undefined;
    if (mathRuleList.rule) {
      rule = await handler.handle(mathRuleList.update(mathRuleList.rule!.id!, mathRuleList.payload!), true)
    }
    else {
      rule = await handler.handle(mathRuleList.create(activeMathCurriculumUnit.unit!.unitCode!, mathRuleList.payload!), true)
    }
    if (!rule) return;
    await job.setType("CSM")
    const condition: RuleSimulationRequest = {
      rule: rule,
      filters: mathRuleSimulation.filter
    }
    await job.setCondition(condition)
    await job.create();
    toast.fireToast({
      type: "success",
      content: toastMessage.success.applyRule,
      duration: 5000
    })
    await mathRuleSimulation.clear();
    await mathRuleList.clear();
  };

  const clear = async () => {
    await job.clearCreatePayload()
    await mathRuleSimulation.clearExpected();
    await mathRuleList.clearRule()
  }

  const selected = useMemo(() => {
    return RULE_SAVE_OPTIONS.filter(o => JSON.stringify(o.value) === JSON.stringify(mathRuleSimulation.filter))[0]
    // eslint-disable-next-line
  }, [mathRuleSimulation.filter?.is_completed, mathRuleSimulation.filter?.classification_status])

  const mode = useMemo(() => {
    if (selected && selected.label === RULE_SAVE_OPTIONS[0].label)
      return 1
    else return 2
  }, [selected])

  return (
    <MaruModal
      id={modalId}
      size="lg"
      customWidth="500px"
      backdrop
      withCancel
      onClose={clear}
      onOkayClick={handleOkayClick}
    >
      <Heading id="draggable-handle" className="block-select">
        선택한 문제에 분류규칙 적용 후 저장
      </Heading>

      <Content>
        입력한 분류규칙이 적용되는 <br />
        {mode === 1 && <b>미분류 기출문제 {mathRuleSimulation.expectedTotal}건</b>}
        {mode === 2 && <b>기출문제 전체(분류완료, 검수대기 상태 포함) {mathRuleSimulation.expectedTotal}건</b>}
        을 <br />
        이 단원으로 바로 자동분류합니다.
      </Content>

      <Description>
        이후 새로 자동분류하는 문항에도 이 분류규칙이 적용됩니다.
      </Description>

    </MaruModal>
  );
});

const Heading = styled.h1`
  display: flex;
  justify-content: center;
  padding-top: 32px;

  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Content = styled.div`
  margin-top: 16px;
  padding: 24px 0;

  border-radius: 4px;
  background: #f9f9fb;

  line-height: 1.5rem;
  text-align: center;
  font-size: 13px;
  color: ${(props) => props.theme.text.FOCUS};

  > b {
    margin-left: 4px;
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }
`;

const Description = styled.div`
  margin-top: 16px;

  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.text.BLACK};
`;

export default RuleApplyCurriculumModal;
