import React from "react";
import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import { Modal, ModalDialog } from "react-bootstrap";
import Draggable from "react-draggable";
import styled from "styled-components";

import Button from "components/Button";

class ModalStore {
  constructor() {
    makeAutoObservable(this);
  }

  modalStatus: { [id: string]: boolean } = {};

  register(id: string, status?: boolean) {
    this.modalStatus = {
      ...this.modalStatus,
      [id]: !!status,
    };
  }

  open(id: string) {
    this.modalStatus = {
      ...this.modalStatus,
      [id]: true,
    };
  }

  close(id: string) {
    this.modalStatus = {
      ...this.modalStatus,
      [id]: false,
    };
  }

  clear() {
    this.modalStatus = {};
  }
}

interface DraggableModalDialogProps extends React.HTMLAttributes<HTMLDivElement> {}

const DraggableModalDialog = ({ ...props }: DraggableModalDialogProps) => {
  return (
    <Draggable handle="#draggable-handle">
      <ModalDialog {...props} />
    </Draggable>
  );
};

const context = React.createContext(new ModalStore());
export const useModal = () => React.useContext(context);

interface MaruModalProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  title?: string;
  size?: "sm" | "lg" | "xl";
  backdrop?: boolean;
  customWidth?: string;
  customHeight?: string;
  buttonColor?: string;
  buttonText?: string;
  warning?: React.ReactNode;
  withCancel?: boolean;
  noPadding?: boolean;
  onClose?: () => void;
  onOkayClick?: () => void;
  disableOkay?: boolean;
  pendingOkay?: boolean;
  removeButtons?: boolean;
}

export const MaruModal = observer(
  ({
    children,
    id,
    title,
    size,
    backdrop = true,
    customWidth = "460px",
    customHeight,
    buttonColor,
    buttonText,
    warning,
    withCancel,
    noPadding,
    onClose,
    onOkayClick,
    disableOkay,
    pendingOkay,
    removeButtons,
  }: MaruModalProps) => {
    const modal = useModal();

    //* Event methods
    const handleClose = () => {
      onClose && onClose();
      modal.close(id);
    };

    const handleOkayClick = () => {
      onOkayClick && onOkayClick();
      modal.close(id);
    };

    return (
      <ModalStyle
        dialogAs={DraggableModalDialog}
        show={modal.modalStatus[id]}
        onHide={handleClose}
        backdrop={backdrop}
        customWidth={customWidth}
        customHeight={customHeight}
        centered
        size={size}
      >
        <ModalWrapper noPadding={noPadding}>
          {title && (
            <ModalHeader id="draggable-handle">
              <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
          )}

          <ModalBody>{children}</ModalBody>

          {warning && <Warning>{warning}</Warning>}

          {!removeButtons && (
            <Buttons noPadding={noPadding}>
              {withCancel && <CancelButton onClick={handleClose}>취소</CancelButton>}
              <OkayButton
                buttonColor={buttonColor}
                onClick={handleOkayClick}
                disabled={disableOkay}
                pending={pendingOkay}
                options={{ disabledTextColor: "#fff" }}
              >
                {buttonText ? buttonText : "확인"}
              </OkayButton>
            </Buttons>
          )}
        </ModalWrapper>
      </ModalStyle>
    );
  }
);

const ModalStyle = styled(Modal)`
  overflow: hidden;
  display: flex !important;
  justify-content: center !important;

  .modal-content {
    border: thin solid #e0e0e1 !important;
    border-radius: 4px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
    width: ${(props) => props.customWidth} !important;
    height: ${(props) => props.customHeight} !important;
  }

  .modal-header {
    border-bottom: 0 !important;
  }
`;

const ModalWrapper = styled.div<{ noPadding?: boolean }>`
  position: relative;
  width: 100%;

  padding: ${(props) => (props.noPadding ? "0px" : "24px")};
  padding-top: 0;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;

  height: 64px;
`;

const ModalTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
`;
const ModalBody = styled.div``;

const Buttons = styled.div<{ noPadding?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  margin-top: 20px;
  margin-right: ${(props) => (props.noPadding ? "24px" : "0")};
`;
const CancelButton = styled(Button)`
  width: 72px;
  margin-right: 4px;

  background: ${(props) => props.theme.button.WHITE};

  font-weight: 500;
  color: ${(props) => props.theme.text.GREY};

  &:hover {
    background: ${(props) => props.theme.button.CANCEL};
  }
`;
const OkayButton = styled(Button)<{ buttonColor?: string }>`
  min-width: 96px;
  padding: 0 24px;
  background: ${(props) => (props.buttonColor ? props.buttonColor : props.theme.button.BLACK)};

  font-weight: 500;
`;

const Warning = styled.div`
  position: absolute;
  bottom: 38px;

  font-size: 12px;
  color: #666666;
`;
