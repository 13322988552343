import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";

import InfoItem from "components/InfoItem";

const UnitItemModal = observer(() => {
  const { unitSearch } = useStores();
  const modalId = "unitItemModal";

  const handleOkayClick = () => {};

  const unit = useMemo(() => {
    return unitSearch.detail;
  }, [unitSearch.detail]);

  const main = useMemo(() => {
    return unitSearch.detail?.main_content;
  }, [unitSearch.detail]);

  const sub = useMemo(() => {
    return unitSearch.detail?.sub_content;
  }, [unitSearch.detail]);

  const exam = useMemo(() => {
    return unitSearch.detail?.exam_content;
  }, [unitSearch.detail]);

  return (
    <MaruModal id={modalId} size="lg" customWidth="800px" onOkayClick={handleOkayClick}>
      <Heading className="block-select" id="draggable-handle">
        <b>교과서 본문 자세히 보기</b>
      </Heading>

      <Content>
        <Row>
          <InfoItem field="출판사명" value={unit!.publisher} />
        </Row>

        <Row>
          <InfoItem field="교과서명" value={unit!.textbook} />
        </Row>

        <Row>
          <InfoItem field="단원 ID" value={unit!.unitCode} />
        </Row>

        <Row>
          <InfoItem field="단원명" value={unit!.unit_name} />
        </Row>

        <Row>
          <InfoItem field="페이지" value={`P${unit!.page}`} />
        </Row>

        <InnerPanel className="scrollbar">
          {main && (
            <>
              <InnerHeading>본문</InnerHeading>
              <MainContentList>
                <MainContent>{main.map((item: string) => <ContentItem>{item}</ContentItem>)}</MainContent>
              </MainContentList>
            </>
          )}
          {sub && (
            <>
              <InnerHeading>부가 설명글</InnerHeading>
              <SubContentList>
                <SubContent>{sub.map((item: string) => <ContentItem>{item}</ContentItem>)}</SubContent>
              </SubContentList>
            </>
          )}
          {exam && (
            <>
              <InnerHeading>예제</InnerHeading>
              <ExamContentList>
                <ExamContent>{exam.map((item: string) => <ContentItem>{item}</ContentItem>)}</ExamContent>
              </ExamContentList>
            </>
          )}
        </InnerPanel>
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > b {
    margin-left: 8px;
    font-weight: bold;
  }
`;

const Content = styled.div`
  border-radius: 4px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 0 16px;
  line-height: 1rem;
`;

const InnerPanel = styled.div`
  height: 420px;
  margin-top: 16px;

  font-size: 13px;
  color: ${(props) => props.theme.text.BLACK};
`;

const InnerHeading = styled.h1`
  height: 40px;
  padding-top: 4px;

  border-bottom: 2px solid ${(props) => props.theme.border.DIVIDER};

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
  line-height: normal;
`;
const MainContentList = styled.ul``;
const MainContent = styled.li`
  margin-bottom: 8px;
  padding: 16px;

  border: 1px solid ${(props) => props.theme.border.MAIN};
  background: ${(props) => props.theme.background.HOVER};

  font-size: 13px;
  color: ${(props) => props.theme.text.MAIN};
  text-align: justify;
  line-height: 1.4rem;
`;
const SubContentList = styled.ul``;
const SubContent = styled(MainContent)``;

const ContentItem = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin: 0;
  }
`;

const ExamContentList = styled.ul``;
const ExamContent = styled(MainContent)``;

export default UnitItemModal;
