import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

const SaveRuleModal = observer(() => {
  const {ruleList, activeUnit, ruleSimulation} = useStores();
  const modalId = "saveRuleModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async () => {
    if (ruleList.rule) {
      if (await handler.handle(ruleList.update(ruleList.rule!.id!, ruleList.payload!))) return;
    }
    else {
      if (await handler.handle(ruleList.create(activeUnit.unit!.unitCode, ruleList.payload!))) return;
    }

    toast.fireToast({
      type: "success",
      content: toastMessage.success.saveRule,
      duration: 5000,
    });

    await ruleList.clear();
    await ruleSimulation.clear();
  };

  const handleClose = async () => {
    await ruleList.setPayload(undefined);
    toast.fireToast({
      type: "fail",
      content: toastMessage.fail.cancelRule,
      duration: 5000,
    });
  }

  return (
    <MaruModal
      id={modalId}
      title="규칙 저장"
      withCancel
      onOkayClick={handleOkayClick}
      onClose={handleClose}
      customWidth="500px"
    >
      <ModalInnerPanel>
        <Paragraph>
          <b>이후 자동분류하는 문항에는 이 분류규칙이 적용됩니다.</b>
        </Paragraph>
      </ModalInnerPanel>
    </MaruModal>
  );
});


const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO};
  }
`;

export default SaveRuleModal;