import Base from "repositories";
import { FacetRequest } from "types/common";
import {
  GetQuestionStatsRequest,
  GetTextbookStatsRequest,
  GetTrainingDataRequest,
  GetClassificationStatsRequest,
  GetMathClassificationStatsRequest,
  GetInspectionStatsRequest,
  GetInspectionPerInspectorStatsRequest,
} from "types/stats/schema";

class Stats extends Base {
  constructor() {
    super({ prefix: "/statistics" });
  }

  async readFacets(payload: FacetRequest) {
    return this.axiosInstance.post(`/facets`, payload);
  }

  async getOverview() {
    return this.axiosInstance.get(`/overview`);
  }

  async getClassificationStatus() {
    return this.axiosInstance.get(`/classification-status`);
  }

  async getFrequentUnits(publisher: string, textbook: string) {
    const payload = {
      publisher,
      textbook,
    };

    return this.axiosInstance.post(`/frequent-unit`, payload);
  }

  async getQuestionStats(payload: GetQuestionStatsRequest) {
    return this.axiosInstance.post(`/question`, payload);
  }

  async getTextbookStats(payload: GetTextbookStatsRequest) {
    return this.axiosInstance.post(`/textbook`, payload);
  }

  async getTrainingDataStats(payload: GetTrainingDataRequest) {
    return this.axiosInstance.post(`/training-data`, payload);
  }

  async getClassificationStats(payload: GetClassificationStatsRequest) {
    return this.axiosInstance.post(`/classification`, payload);
  }

  async getMathClassificationStats(payload: GetMathClassificationStatsRequest) {
    return this.axiosInstance.post(`/math-classification`, payload);
  }

  async getInspectionStats(payload: GetInspectionStatsRequest) {
    return this.axiosInstance.post(`/inspection`, payload);
  }

  async getInspectionPerInspectorStats(payload: GetInspectionPerInspectorStatsRequest) {
    return this.axiosInstance.post(`/inspection-per-inspector`, payload);
  }
}

export default new Stats();
