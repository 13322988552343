import { makeAutoObservable } from "mobx";

import repository from "repositories/Stats";
import { DropdownItemType, Scalable, StoreState } from "types/common";
import { StatBucket, StatItem } from "types/stats/model";
import { GetTextbookStatsRequest, GetTrainingDataRequest } from "types/stats/schema";
import { toISOStringCustom } from "utils";

const NOW = new Date();
const MONTH_AGO = new Date(new Date().setHours(-24 * 30));

interface Filters {
  [index: string]: DropdownItemType | Scalable | undefined;
  course: DropdownItemType;
  revised_year: DropdownItemType;
  created_date: Scalable;
}

export default class TextbookStatList {
  state: StoreState = "none";
  total: StatBucket = {
    key: "total",
    bucket: [],
  };
  list: StatBucket[] = [];
  field: "publisher" | "trainingData" = "publisher";
  filters: Filters = {
    course: { value: 2, label: "중학교" },
    revised_year: { value: 2015, label: "2015" },
    created_date: { gte: toISOStringCustom(MONTH_AGO), lte: toISOStringCustom(NOW) },
  };
  page: number = 1;
  size: number = 50;
  resetSwitch: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getTextbookStats() {
    this.total = {
      key: "total",
      bucket: [],
    };
    this.list = [];
    this.page = 1;
    this.state = "pending";

    try {
      const { course, revised_year } = { ...this.filters };

      const payload: GetTextbookStatsRequest = {
        course: course.value,
        revised_year: revised_year.value,
        from: (this.page - 1) * this.size,
        size: this.size,
      };

      const response = await repository.getTextbookStats(payload);
      this.total = response.data.response.find((el: StatBucket) => el.key === "total");
      this.list = response.data.response.filter((el: StatBucket) => el.key !== "total");
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  async getTrainingData() {
    this.total = {
      key: "total",
      bucket: [],
    };
    this.list = [];
    this.state = "pending";

    try {
      const { course, revised_year, created_date } = { ...this.filters };

      const payload: GetTrainingDataRequest = {
        course: course.value,
        revised_year: revised_year.value,
        created_date: {
          gte: created_date.gte.replaceAll(".", "-"),
          lte: created_date.lte.replaceAll(".", "-"),
        },
        from: (this.page - 1) * this.size,
        size: this.size,
      };

      const response = await repository.getTrainingDataStats(payload);
      this.total = response.data.response.find((el: StatBucket) => el.key === "total");
      this.list = response.data.response.filter((el: StatBucket) => el.key !== "total");

      const sample: StatItem[] = [];  // 새로 만들어서 넣어줄 배열
      const keys = this.total.bucket.map((el) => el.key); // total에서 key값 추출해서 비교할 때 쓰기 위함

      /* 
        토탈에 존재하는 key를 기준으로 값을 0으로 해 sample에 넣어준다. 
        즉 같은 키 값을 가지고 있고 모든 값이 0인 객체를 생성해 sample에 넣어주는 것. 
      */
      this.total.bucket.forEach((item) => {
        sample.push({ key: item.key, doc_count: 0 });
      })

      // total에는 있는 key지만 개별 데이터에 없는 경우 값의 표시를 맞춰주기 위한 작업.
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i];  // 개별 데이터

        // total key를 기준으로 개별 데이터가 해당 키 값을 가지고 있는지 조사
        keys.forEach((key) => {
          const targetIndex = item.bucket.findIndex((el) => el.key === key);

          // 없으면 데이터마다 배열의 길이가 달라서 표시가 어긋난다. 그것을 맞춰주기 위한 작업.
          if (targetIndex !== -1) {
            // 없는 key 값을 미리 만들어둔 sample에서 찾은 다음
            const target = sample.find((el) => el.key === key);

            if (target) {
              // 그 값을 sample에 넣어준다.
              target.doc_count = item.bucket[targetIndex].doc_count;
            }
          }
        })

        // 그러면 개별 데이터가 갖고있던 값을 제외한 나머지 key에 대한 값은 0을 갖고있는 sample이 만들어진다.
        // 이를 기존의 개별 데이터 대신 사용한다.
        this.list[i].bucket = sample;
      }
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  search() {
    this.state = "none";
  }

  setFilters(filterName: string, value: DropdownItemType | Scalable) {
    this.filters[filterName] = value;
  }

  setField(field: "publisher" | "trainingData") {
    this.field = field;
  }

  turnResetSwitchOff() {
    this.resetSwitch = false;
  }

  setPage(page: number) {
    this.page = page;
  }

  reset() {
    this.filters = {
      course: { value: 2, label: "중학교" },
      revised_year: { value: 2009, label: "2009" },
      created_date: { gte: toISOStringCustom(MONTH_AGO), lte: toISOStringCustom(NOW) },
    };
    this.page = 1;
    this.resetSwitch = true;
  }

  clear() {
    this.total = {
      key: "total",
      bucket: [],
    };
    this.page = 1;
    this.list = [];
    this.field = "publisher";
    this.filters = {
      course: { value: 2, label: "중학교" },
      revised_year: { value: 2015, label: "2015" },
      created_date: { gte: toISOStringCustom(MONTH_AGO), lte: toISOStringCustom(NOW) },
    };
    this.state = "none";
  }
}
