export const Theme = {
  light: {
    background: {
      SIDEBAR: "#484848",
      PANEL_WHITE: "#FFFFFF",
      CHIP: "#333333",
      HOVER: "#f2f2f2",
      INFO: "#2992ff",
      SUCCESS: "#42b64a",
      DANGER: "#e9397a",
      LIGHT_GREY: "#cccccc",
      FOCUS: "#666666",
      DIM: "#f9f9fb",
    },
    text: {
      MAIN: "#484848",
      BLACK: "#333333",
      WHITE: "#FFFFFF",
      REVERSE2: "rgba(255, 255, 255, 0.6)",
      GREY: "#999999",
      IDLE: "#a5a5a5",
      HOVER: "#636364",
      INFO: "#2992ff",
      SUCCESS: "#42b64a",
      DANGER: "#e9397a",
      PLACEHOLDER: "#cccccc",
      FOCUS: "#666666",
      DIM: "#f9f9fb",
    },
    button: {
      BLACK: "#484848",
      INFO: "#2992ff",
      SUCCESS: "#42b64a",
      DANGER: "#e9397a",
      CANCEL: "#F2F2F2",
      WHITE: "#ffffff",
      FOCUS: "#666666",
    },
    border: {
      MAIN: "#F2F2F2",
      FOCUS: "#666666",
      DIVIDER: "#484848",
      TABLE_ROW: "#f6f6f6",
      FILTERS: "#cccccc",
      INFO: "#2992ff",
      SUCCESS: "#42b64a",
      DANGER: "#e9397a",
      DIM: "#ececed",
    },
    shadow: {
      MAIN: "#00000029",
    },
  },
  dark: {},
};