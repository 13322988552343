import Base from "repositories";
import {
  RuleCreate,
  RuleRequest,
  RuleSimulationRequest,
  RuleUpdate
} from "types/rule/schema";


class Rule extends Base {
  constructor() {
    super({ prefix: "/rule" });
  }

  async readMany(payload: RuleRequest) {
    return this.axiosInstance.post(``, payload);
  }

  async create(unitCode: string, payload: RuleCreate) {
    return this.axiosInstance.post(`/${unitCode}`, payload)
  }

  async delete(ruleId: string) {
    return this.axiosInstance.delete(`/${ruleId}`)
  }

  async update(ruleId: string, payload: RuleUpdate) {
    return this.axiosInstance.put(`/${ruleId}`, payload)
  }

  async simulate(payload: RuleSimulationRequest) {
    return this.axiosInstance.post('/simulate', payload)
  }

}

export default new Rule();
