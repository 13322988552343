import React, { useLayoutEffect, useRef } from "react";
import { MathJax } from "better-react-mathjax";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";

interface LevelsProps extends React.HTMLAttributes<HTMLDivElement> {
  level1?: string;
  level2?: string;
  level3?: string;
  noMargin?: boolean;
  htmlMode?: boolean;
  stackMode?: boolean;
}

const Levels = observer(({ level1, level2, level3, noMargin, htmlMode = false, stackMode = false, ...props }: LevelsProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const { activeQuestion } = useStores();

  useLayoutEffect(() => {
    if (ref.current) {
      const images: NodeListOf<HTMLImageElement> = ref.current.querySelectorAll("img");

      for (let i = 0; i < images.length; i++) {
        const image = images[i];

        if (image.src.includes("cloudfront")) {
          return;
        } else {
          const newSrc = "https://cloudfront.qnai.io/" + image.src.split("/").slice(3).join("/");
          image.src = newSrc;
        }
      }
    }
  }, [activeQuestion.question, htmlMode]);

  return (
    <>
      {htmlMode ? (
        <LevelsStyle ref={ref} stackMode={stackMode} {...props}>
          {(level1 || level2) && (
            <Group className="scrollbar">
              {level1 && <Level1 dangerouslySetInnerHTML={{ __html: level1 }} />}
              {level2 && <Level2 dangerouslySetInnerHTML={{ __html: level2 }} />}
            </Group>
          )}
          {level3 && <Level3 noMargin={noMargin} dangerouslySetInnerHTML={{ __html: level3 }} />}
        </LevelsStyle>
      ) : (
        <MathJax>
          <LevelsStyle ref={ref} stackMode={stackMode} {...props}>
            {(level1 || level2) && (
              <Group className="scrollbar">
                {level1 && <Level1 dangerouslySetInnerHTML={{ __html: level1 }} />}
                {level2 && <Level2 dangerouslySetInnerHTML={{ __html: level2 }} />}
              </Group>
            )}
            {level3 && <Level3 noMargin={noMargin} dangerouslySetInnerHTML={{ __html: level3 }} />}
          </LevelsStyle>
        </MathJax>
      )}
    </>
  );
});

const LevelsStyle = styled.div<{ stackMode: boolean }>`
  display: ${(props) => (props.stackMode ? "block" : "flex")};
  background: white;
  line-height: 1rem;

  .itex_normal_box {
    margin-top: 8px;
    padding: 16px;
    border: 1px solid ${(props) => props.theme.border.DIM};
    background: #fbfbfb;
    line-height: 1.2rem;
  }

  .itex_box_body {
    margin: 8px 0;
    padding: 16px;
    border: 1px solid ${(props) => props.theme.border.DIM};
    background: #fbfbfb;
    line-height: 1.2rem;
  }

  .img_wrap {
    margin-top: 16px;
  }

  table {
    margin-top: 8px;

    td {
      height: 100%;
      text-align: center;
    }
  }
`;

const Group = styled.div<{ noMargin?: boolean }>`
  width: auto;
  flex: 1;
  height: 300px;
  margin: ${(props) => (props.noMargin ? "0" : "16px")};

  font-size: 13px;
  color: ${(props) => props.theme.text.BLACK};
  line-height: 1.4rem;
`;

const Level1 = styled.div`
  margin-bottom: 16px;
  font-weight: 500;
`;
const Level2 = styled.div`
  padding: 16px;
  border: 1px solid ${(props) => props.theme.border.DIM};
  background: #fbfbfb;
`;
const Level3 = styled.div<{ noMargin?: boolean }>`
  width: auto;
  flex: 1;
  padding: 16px;

  font-size: 13px !important;
  color: ${(props) => props.theme.text.BLACK};

  > p {
    margin-top: 4px;
  }

  .level4 {
    margin-top: 8px;

    line-height: normal;

    color: ${(props) => props.theme.text.GREY};

    > div.sel_list {
      p {
        > span.sel_num_po {
          margin-right: 4px;
        }

        > span.itexmath {
          transform: translateY(-2px);
          margin-right: 1px;
          font-size: 12px;
        }
      }
    }
  }

  .later-level {
    margin-top: 8px;
    padding: 16px;

    border: 1px solid ${(props) => props.theme.border.DIM};
    background: #fbfbfb;
  }

  span.itexmath {
    font-family: 'Consolas', 'Courier New', monospace !important;
  }

  span.sel_num_po {
    font-family: "Noto Sans KR", "Roboto", sans-serif !important;
  }
`;

export default Levels;
