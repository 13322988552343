import React from "react";
import styled from "styled-components";

interface InfoItemProps {
  field: string;
  value?: string;
  bold?: boolean;
  regex?: boolean;
  color?: "default" | "success" | "info" | "danger";
  withMargin?: boolean;
  wideField?: boolean;
}

const InfoItem = ({ field, value, bold = false, regex = false, color = "default", withMargin = false, wideField = false }: InfoItemProps) => {
  if (!value) return null;

  return (
    <InfoItemStyle withMargin={withMargin}>
      <Field wideField={wideField}>{field}</Field>
      <Value isBold={bold} regex={regex} color={color}>
        {value}
      </Value>
    </InfoItemStyle>
  );
};

const InfoItemStyle = styled.div<{ withMargin: boolean }>`
  display: flex;
  align-items: flex-start;
  margin-top: ${(props) => (props.withMargin ? "4px" : "0")};
  margin-left: ${(props) => (props.withMargin ? "12px" : "0")};

  font-size: 13px;
  line-height: 1.5rem;
`;
const Field = styled.div<{ wideField: boolean }>`
  width: ${(props) => props.wideField ? "140px" : "112px" };
  flex-shrink: 0;

  color: #666;
`;

interface ValueProps {
  isBold: boolean;
  regex: boolean;
  color: "default" | "success" | "info" | "danger";
}
const Value = styled.div<ValueProps>`
  flex: 1;
  font-weight: ${({ isBold }) => (isBold ? "500" : "normal")};
  font-family: ${({ regex }) => regex && "'Consolas', 'Courier New', monospace !important"};
  color: ${({ color, theme }) => {
    switch (color) {
      case "default":
        return theme.text.BLACK;

      case "success":
        return theme.text.SUCCESS;

      case "info":
        return theme.text.INFO;

      case "danger":
        return theme.text.DANGER;
    }
  }};
  word-break: break-all;
`;

export default InfoItem;
