import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";

import { toastMessage } from "messages";

const DeleteCurriculumRuleModal = observer(() => {
  const {mathRuleList} = useStores();
  const modalId = "deleteCurriculumRuleModal";
  const toast = useToast();

  const handleOkayClick = async () => {
    await mathRuleList.delete(mathRuleList.rule!.id!)
    toast.fireToast({
      type: "fail",
      content: toastMessage.fail.deleteRule,
      duration: 5000,
    });
    await mathRuleList.clear();
  };

  const handleClose = async () => {
    await mathRuleList.setRule();
  }

  return (
    <MaruModal
      id={modalId}
      title="규칙 삭제"
      buttonColor="#e9397a"
      buttonText="삭제"
      warning={
        <>
          삭제 후에는
          <em style={{ color: "#e9397a" }}>결과 복원</em>이 불가합니다.
        </>
      }
      withCancel
      onOkayClick={handleOkayClick}
      onClose={handleClose}
      customWidth="500px"
    >
      <ModalInnerPanel>
        <Paragraph>
          <b>이 분류규칙은 이후 자동분류 시 더 이상 적용되지 않습니다.</b> <br />
          (이전에 이 규칙으로 자동분류된 문항의 분류결과 조회만 가능합니다.)
        </Paragraph>
      </ModalInnerPanel>
    </MaruModal>
  );
});


const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO};
  }
`;

export default DeleteCurriculumRuleModal;