import axios, { AxiosInstance } from "axios";

import {getCookie} from "utils";

const apiHost = process.env.REACT_APP_HOST!;
const apiPrefix = process.env.REACT_APP_PREFIX!;
const websocketHost = process.env.REACT_APP_WEBSOCKET_HOST

export default class Base {
  axiosInstance: AxiosInstance;
  HOST: string;
  webSocketHost?: string;

  constructor({ host, prefix, websocket }: { host?: string; prefix?: string; websocket?: boolean}) {
    this.HOST = `${host?host:apiHost.length?apiHost:window.location.origin}${apiPrefix&&apiPrefix}`;
    if (prefix) this.HOST += prefix;
    if (websocket) {
      this.webSocketHost =
        websocketHost?
        websocketHost:
        `${window.location.protocol.startsWith("https")?"wss":"ws"}://${window.location.host}/ws`
    };
    this.axiosInstance = axios.create({
      baseURL: this.HOST,
    });

    this.axiosInstance.interceptors.response.use(
      async (config) => {
        return config
      }, (error) => {
        return Promise.reject(error)
      }
    )

    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const token = getCookie("access-token");
        const headers: any = {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          "Access-Control-Allow-Origin": apiHost,
        };
        if (token) headers["Authorization"] = `Bearer ${token}`;
        config.headers = headers;
        config.withCredentials = true;
        return config;
      },
      (error) => {
        // handle error
        return Promise.reject(error);
      }
    );
  }
}