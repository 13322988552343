import { useState, useCallback } from "react";

export const useClientRect = () => {
  const [rect, setRect] = useState<DOMRect | null>(null);

  const ref = useCallback((node: HTMLElement | null) => {
    if (node !== null) setRect(node.getBoundingClientRect());
    // eslint-disable-next-line
  }, []);

  return [rect, ref] as const;
};
