import React from "react";
import { makeAutoObservable } from "mobx";

interface PageRef {
  page: number;
  ref: React.RefObject<HTMLElement>;
}

export default class PageRefList {
  pageRefList: PageRef[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  addPageRef(ref: PageRef) {
    this.pageRefList.push(ref);
  }

  getPageRef(page: number) {
    return this.pageRefList.find((pageRef) => pageRef.page === page);
  }

  moveIntoPage(page: number) {
    const pageRef = this.getPageRef(page);

    if (pageRef) {
      pageRef.ref.current?.scrollIntoView({ behavior: "smooth" });
    }
  }

  clear() {
    this.pageRefList = [];
  }
}
