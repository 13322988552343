import {useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { engToKor, scoreToPercent } from "utils";
import {
  CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING,
  CLASSIFICATION_TYPE_ENG_KOR_MAPPING,
  INSPECTION_STATUS_ENG_KOR_MAPPING,
} from "constant";

import Button from "components/Button";
import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";
import useStores from "hooks/useStores";
import {toastMessage} from "messages";
import {useToast} from "hooks/useToast";

const QuestionModalContent = observer(() => {
  const {mathClassificationResult, mathQuestionList} = useStores();
  const toast = useToast();
  const [mouseOver, setMouseOver] = useState(false);
  const [htmlMode, setHtmlMode] = useState(false);

  const question = useMemo(() => {
    return mathClassificationResult.detail?.model;
  }, [mathClassificationResult.detail?.model]);

  //* Event methods
  const handleQuestionIdClick = () => {
    navigator.clipboard.writeText(question._id);
    toast.fireToast({
      type: "success",
      content: toastMessage.success.copyQuestionId,
      duration: 5000,
    });
  };

  const handleHTMLModeButtonClick = () => {
    setHtmlMode(!htmlMode);
  };

  return (
    <QuestionModalContentStyle>
      <Row style={{ transform: "translateY(-2px)" }}>
        <Item style={{ alignItems: "center" }}>
          <Field>문항 ID</Field>
          <Value
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            onClick={handleQuestionIdClick}
            mouseOver={mouseOver}
            style={{ fontWeight: "500" }}
          >
            {question._id}
          </Value>
        </Item>

        <Item style={{ alignItems: "center" }}>
          <Field>상태</Field>
          <Value>
            <InspectionStatusText status={question.inspection_status}>
              {engToKor(
                INSPECTION_STATUS_ENG_KOR_MAPPING,
                question!.inspection_status
              )}
            </InspectionStatusText>
            {
              question.classification_consistency &&
              <Badge>{engToKor(CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING, question!.classification_consistency)}</Badge>
            }
            {
              question.modifier_email &&
              <em style={{ color: "#484848", opacity: "0.8" }}>({question.modifier_name})</em>
            }
          </Value>
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item>
          <Field>시험지 ID</Field>
          <Value>{question.pocCode}</Value>
        </Item>

        <Item>
          <Field>최종분류근거</Field>
          <Value>
            <em className="info" style={{ marginRight: "4px" }}>
              {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
            </em>
            <em style={{ color: "#484848", opacity: "0.8" }}>
              {question.score && `(${scoreToPercent(question.score)})`}
            </em>
          </Value>
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item>
          <Field>시험정보</Field>
          <Value>{mathQuestionList.questionInfo(question)}</Value>
        </Item>

        <Item>
          {
            question.rep_question_id &&
            <>
              <Field>기준문항 ID</Field>
              <Value>{question.rep_question_id}</Value>
            </>
          }
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item style={{ margin: "0" }}>
          <Field>표준커리큘럼 ID</Field>
          <Value>{question.unitCode}</Value>
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item style={{ margin: "0" }}>
          <Field>표준커리큘럼정보</Field>
          <Value>{question.grade_semester}</Value>
        </Item>
      </Row>

      <Row>
        <Item style={{ margin: "0" }}>
          <Field></Field>
          <Value style={{ lineHeight: "normal" }}>
            {question.unit_name}
          </Value>
        </Item>
      </Row>

      <LevelsWrapper className="scrollbar">
        <Levels level1={question.level1} level2={question.level2} level3={question.level3} htmlMode={htmlMode} noMargin />

        <FlexWrapper>
          <HTMLModeButton onClick={handleHTMLModeButtonClick}>HTML 모드 {htmlMode ? "OFF" : "ON"}</HTMLModeButton>
        </FlexWrapper>
      </LevelsWrapper>
    </QuestionModalContentStyle>
  );
});

const QuestionModalContentStyle = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 16px;

  min-height: 20px;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-shrink: 0;
  min-height: 20px;

  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  width: 112px;
  flex-shrink: 0;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div<{ mouseOver?: boolean }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text.BLACK};

  text-decoration: ${(props) => (props.mouseOver ? "underline" : "none")};
  cursor: ${(props) => (props.mouseOver ? "pointer" : "default")};

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }
`;
const Badge = styled.div`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const LevelsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  margin-top: 16px;
  max-height: 600px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.DIM};
`;

const FlexWrapper = styled.div`
  height: 32px;
  margin-top: 16px;
  margin-right: 16px;
`;
const HTMLModeButton = styled(Button)`
  width: 120px;
  height: 32px;

  background-color: ${(props) => props.theme.background.SUCCESS};

  &:hover {
    background-color: #40a547;
  }
`;

export default QuestionModalContent;
