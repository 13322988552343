import { makeAutoObservable } from "mobx";

import repository from "repositories/Stats";
import { DropdownItemType, Scalable, StoreState } from "types/common";
import { StatBucket } from "types/stats/model";
import { GetInspectionPerInspectorStatsRequest, GetInspectionStatsRequest } from "types/stats/schema";
import { toISOStringCustom } from "utils";

const NOW = new Date();
const MONTH_AGO = new Date(new Date().setHours(-24 * 30));

interface Filters {
  [index: string]: DropdownItemType | Scalable | undefined;
  classification_consistency?: DropdownItemType;
  modified_dt: Scalable;
}

export default class InspectionStatsList {
  state: StoreState = "none";
  total: StatBucket = {
    key: "total",
    bucket: [],
  };
  list: StatBucket[] = [];
  field: "inspection" | "inspector" = "inspection";
  filters: Filters = {
    modified_dt: {
      gte: toISOStringCustom(MONTH_AGO),
      lte: toISOStringCustom(NOW),
    },
  };
  page: number = 1;
  size: number = 50;
  resetSwitch: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getInspectionStats() {
    this.state = "pending";

    try {
      const { classification_consistency, modified_dt } = { ...this.filters };

      const payload: GetInspectionStatsRequest = {
        classification_consistency: classification_consistency && classification_consistency.value,
        modified_dt: {
          gte: modified_dt.gte.replaceAll(".", "-"),
          lte: modified_dt.lte.replaceAll(".", "-"),
        },
        from: (this.page - 1) * this.size,
        size: this.size
      };

      const response = await repository.getInspectionStats(payload);
      this.total = response.data.response.find((el: StatBucket) => el.key === "total");
      this.list = response.data.response.filter((el: StatBucket) => el.key !== "total");
      this.list.forEach((item) => {
        item.bucket.pop();
      })
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  async getByInspectorStats() {
    this.state = "pending";

    try {
      const { classification_consistency, modified_dt } = { ...this.filters };

      const payload: GetInspectionPerInspectorStatsRequest = {
        classification_consistency: classification_consistency && classification_consistency.value,
        modified_dt: {
          gte: modified_dt.gte.replaceAll(".", "-"),
          lte: modified_dt.lte.replaceAll(".", "-"),
        },
        from: (this.page - 1) * this.size,
        size: this.size
      };

      const response = await repository.getInspectionPerInspectorStats(payload);
      this.total = response.data.response.find((el: StatBucket) => el.key === "total");
      this.list = response.data.response.filter((el: StatBucket) => el.key !== "total");
      this.list.forEach((item) => {
        item.bucket.pop();
      })
    } catch (e) {
      console.error(e);
      this.state = "error";
    }

    this.state = "done";
  }

  search() {
    this.state = "none";
  }

  setFilters(filterName: string, value: DropdownItemType | Scalable) {
    this.filters[filterName] = value;
  }

  turnResetSwitchOff() {
    this.resetSwitch = false;
  }

  setField(field: "inspection" | "inspector") {
    this.field = field;
  }

  setPage(page: number) {
    this.page = page;
  }

  reset() {
    this.filters = {
      modified_dt: {
        gte: toISOStringCustom(MONTH_AGO),
        lte: toISOStringCustom(NOW),
      },
    };
    this.page = 1;
    this.resetSwitch = true;
  }

  clear() {
    this.total = {
      key: "total",
      bucket: [],
    };
    this.list = [];
    this.page = 1;
    this.field = "inspection";
    this.filters = {
      modified_dt: {
        gte: toISOStringCustom(MONTH_AGO),
        lte: toISOStringCustom(NOW),
      },
    };
    this.state = "none";
  }
}
