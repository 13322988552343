import Base from "repositories";
import {FacetRequest} from "types/common";
import {
  MathCurriculumUnitReadManyRequest,
  MathCurriculumUnitSearchRequest, MathCurriculumUnitSelectRequest,
  MathCurriculumUnitUpdateRequest
} from "types/unit/math/schema";

class Unit extends Base {
  constructor() {
    super({ prefix: "/math/curriculum/unit" });
  }

  async readMany(payload: MathCurriculumUnitReadManyRequest) {
    return this.axiosInstance.post(``, payload);
  }

  async readFacets(payload: FacetRequest) {
    return this.axiosInstance.post('/facets', payload);
  }

  async update(payload: MathCurriculumUnitUpdateRequest) {
    return this.axiosInstance.put(``, payload);
  }

  async read(curriculumDetailCode: string) {
    return this.axiosInstance.get(`${curriculumDetailCode}`);
  }

  async readByDetail(curriculumDetailCode: string) {
    return this.axiosInstance.get(`/${curriculumDetailCode}/detail`);
  }


  async readSelectMany(payload: MathCurriculumUnitSelectRequest) {
    return this.axiosInstance.post('/select', payload);
  }

  async searchUnit(payload: MathCurriculumUnitSearchRequest) {
    return this.axiosInstance.post('/search', payload);
  }

}

export default new Unit();
