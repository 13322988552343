import Base from "repositories";
import {
  BulkTestRequest,
} from "types/bulk-test/schema";


class BulkTest extends Base {
  constructor() {
    super({ prefix: "/bulk-test" });
  }

  async readMany(payload: BulkTestRequest) {
    return this.axiosInstance.post(``, payload);
  }

  async test(testId: string) {
    return this.axiosInstance.post(`/test/${testId}?background=true`)
  }

  async create(testName: string, formData: FormData) {
    return this.axiosInstance.post(`/${testName}?background=true`, formData)
  }

  async download(testId: string) {
    return this.axiosInstance.post(
      `/download/${testId}`
      ,
      undefined
      ,
      {
        responseType: "blob"
      }
    )
  }

}

export default new BulkTest();
