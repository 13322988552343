import {useMemo, useState} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";
import {useErrorHandler} from "hooks/useErrorHandler";

import {QuestionReadManyRequest} from "types/question/schema";

import Checkbox from "components/Checkbox";

const ChangeStatusModal = observer(() => {
  const {questionList, job} = useStores();
  const [waiting, setWaiting] = useState(true);
  const [none, setNone] = useState(false);
  const handler = useErrorHandler();
  const modalId = "changeStatusModal";

  const isSelected = useMemo(() => {
    return questionList.questionStorage.length
  }, [questionList.questionStorage.length])


  const handleOkayClick = async () => {
    await job.setType(none?"UISN":"UISW");
    const condition: QuestionReadManyRequest = questionList.jobPayload();
    await job.setCondition(condition);
    if (
      await handler.handle(job.create())
    ) return;
    await questionList.clearQuestionStorage();
    await clear();
  };

  const handleWaitingClick = async () => {
    if (!waiting) {
      setWaiting(true)
      setNone(false)
      return
    }
    setWaiting(!none)
  }

  const handleNoneClick = async () => {
    if (!none) {
      setWaiting(false)
      setNone(true)
      return
    }
    setNone(!waiting)
  }

  const clear = async () => {
    await job.clearCreatePayload();
  }

  return (
    <MaruModal
      id={modalId}
      title="상태 변경"
      buttonColor={none ? "#e9397a" : ""}
      buttonText="확인"
      withCancel
      onClose={clear}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {waiting && <Waiting count={isSelected?isSelected:questionList.total} isSelected={!!isSelected}/>}
        {none && <None count={isSelected?isSelected:questionList.total} isSelected={!!isSelected}/>}
      </ModalInnerPanel>

      <CheckboxWrapper>
        <CheckboxItem>
          <Checkbox checked={waiting} handleClick={handleWaitingClick}/>
          <em className="text-info">검수대기 요청</em>
        </CheckboxItem>

        <CheckboxItem>
          <Checkbox checked={none} handleClick={handleNoneClick}/>
          <em className="text-danger">검수요청 취소</em>
        </CheckboxItem>
      </CheckboxWrapper>
    </MaruModal>
  );
});

const CheckboxWrapper = styled.div`
  padding-top: 8px;
`;
const CheckboxItem = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;

  font-size: 13px;
  font-weight: 500;

  > div {
    margin-right: 8px;
  }

  > em {
    padding-right: 4px;
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }
`;

interface ModalInnerPanelProps {
  count: number;
  isSelected: boolean;
}

const Waiting = ({count, isSelected}: ModalInnerPanelProps) => (
  <Paragraph>
    {isSelected?"선택한":"조회된"} <b>{count}건의 문항</b>을 <em className="text-info">검수대기 상태로 변경</em>합니다. <br />
    검수자는 검수 메뉴에서 결과 단원별로 지정할 수 있습니다.
  </Paragraph>
);

const None = ({count, isSelected}: ModalInnerPanelProps) => (
  <Paragraph>
    {isSelected?"선택한":"조회된"} <b>문항 {count}건</b>의 <em className="text-danger">검수요청을 취소</em>합니다. <br />
    검수요청이 회수된 문항은 검수자에게 보이지 않습니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }
`;

export default ChangeStatusModal;