import {useMemo, useState} from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import Button from "components/Button";

import useStores from "hooks/useStores";
import {useToast} from "hooks/useToast";
import {MaruModal, useModal} from "hooks/useModal";
import {useErrorHandler} from "hooks/useErrorHandler";


import {
  CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING,
  CLASSIFICATION_TYPE_ENG_KOR_MAPPING,
  INSPECTION_STATUS_ENG_KOR_MAPPING,
  ROUTERS
} from "constant";

import {toastMessage} from "messages";

import InspectionStatusText from "components/InspectionStatusText";
import Levels from "components/Levels";
import { ReactComponent as CancelIcon } from "assets/icons/icon_cancel_16.svg";

import {engToKor, makePage, scoreToPercent, utcToLocal} from "utils";

const SimilarQuestionModal = observer(() => {
  const { similarQuestionList, questionList, unitSelect, activeUnit } = useStores();
  const modalId = "similarQuestionModal";
  const [mouseOver, setMouseOver] = useState(false);
  const toast = useToast();
  const modal = useModal();
  const handler = useErrorHandler();

  const question = useMemo(() => {
    return similarQuestionList.detailQuestion;
  }, [similarQuestionList.detailQuestion]);

  const handleQuestionIdClick = () => {
    window.location.replace(ROUTERS.inspection.textbookQuestionId(question!._id));
  }

  const handleCancelInspectionClick = async () => {
    if (
      await handler.handle(
        questionList.updateMany(
          {
            question_ids: [question!._id],
            values: {
              is_completed: 0
            }
          }
        )
      )
    ) return;
    modal.close(modalId);
    toast.fireToast({
      type: "fail",
      content: toastMessage.fail.cancelInspection,
      duration: 5000,
    });
    await unitSelect.clear();
    await similarQuestionList.clear();
    await activeUnit.clear();
  }

  const inspected = useMemo(() => {
    return question?.inspection_status === "inspected" || question?.inspection_status === "hold"
  }, [question?.inspection_status])

  const unit = useMemo(() => {
    return question?._parent;
  }, [question?._parent])

  return (
    <MaruModal id={modalId} customWidth="1184px" size="xl">
      <Heading id="draggable-handle">유사문항 자세히 보기</Heading>

      <Row>
        <Info>
          <Field>문항 ID</Field>
          <Value
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            onClick={handleQuestionIdClick}
            mouseOver={mouseOver}
          >
            <b>{question!._id}</b>
          </Value>
        </Info>

        <Info>
          <Field>상태</Field>
          <Value>
            <InspectionStatusText status={question!.inspection_status}>
              {engToKor(
                INSPECTION_STATUS_ENG_KOR_MAPPING,
                question!.inspection_status
              )}
            </InspectionStatusText>
            {
              question?.classification_consistency &&
              <SideChip>{engToKor(CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING, question!.classification_consistency)}</SideChip>
            }
            {
              (unit && unit.checker_email) &&
              <em style={{color: "#484848", opacity: "0.8"}}>{`(${unit.checker_email})`}</em>
            }

            {inspected &&
              <CancelButton onClick={handleCancelInspectionClick}>
                <CancelIcon/>
                검수취소
              </CancelButton>
            }
          </Value>
        </Info>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Info>
          <Field>시험지 ID</Field>
          <Value>
            {question?.pocCode}
          </Value>
        </Info>

        <Info>
          {
            question?.checker_type &&
            <>
              <Field>최종분류근거</Field>
              <Value>
                <em className="text-info" style={{marginRight: "4px"}}>
                  {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
                </em>
                <em className="text-grey">
                  {question!.score &&
                    `(${scoreToPercent(question.score)})`}
                </em>
              </Value>
            </>
          }
        </Info>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Info>
          <Field>시험정보</Field>
          <Value>
            {questionList.questionInfo(question)}
          </Value>
        </Info>

        <Info>
          {
            question?.modifier_name &&
            <>
              <Field>최종수정</Field>
              <Value>
                {question.modifier_name} ({utcToLocal(question.modified_dt, "YYYY-MM-DD HH:mm")})
              </Value>
            </>
          }
        </Info>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Info style={{ margin: "0" }}>
          <Field>단원 ID</Field>
          <Value>
            {question!.unitCode}
          </Value>
        </Info>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Info style={{ margin: "0" }}>
          <Field>단원명</Field>
          <Value>
            {`${question?.textbookSb} `}
            {`${question!.unit_name}`}
          </Value>
        </Info>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Info style={{ margin: "0" }}>
          <Field>페이지</Field>
          <Value>
            <b>
              {makePage(question?.page)}
            </b>
          </Value>
        </Info>
      </Row>

      <QuestionBox className="scrollbar">
        <Levels level1={question?.level1} level2={question?.level2} level3={question?.level3}/>
      </QuestionBox>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
`;

const Row = styled.div`
  display: flex;
  padding: 0 16px;
  min-height: 19px;
`;
const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: 19px;

  flex: 1;
  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  display: flex;
  align-items: center;

  flex-shrink: 0;
  width: 96px;
  min-height: 19px;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div<{ mouseOver?: boolean }>`
  min-height: 19px;

  color: ${(props) => props.theme.text.BLACK};

  text-decoration: ${(props) => (props.mouseOver ? "underline" : "none")};
  cursor: ${(props) => (props.mouseOver ? "pointer" : "default")};
  line-height: normal;

  > b {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-grey {
    color: ${(props) => props.theme.text.GREY};
  }
`;

const SideChip = styled.em`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const CancelButton = styled(Button)`
  margin-left: 32px;
  background: none;
  color: ${(props) => props.theme.text.DANGER};

  border: none;

  svg {
    fill: ${(props) => props.theme.text.DANGER};
  }
`;

const QuestionBox = styled.div`
  max-height: 600px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.DIM};
`;

export default SimilarQuestionModal;
