import { createGlobalStyle } from "styled-components";
import { reset } from "styled-reset";
import "react-datepicker/dist/react-datepicker.css";
import "assets/style/style.css";
import "katex/dist/katex.min.css";
// import "react-tooltip/dist/react-tooltip.css";

import { datepickerStyle } from "assets/style/datepickerStyle";
import { toastAnimation } from "assets/style/toastAnimation";

const Global = createGlobalStyle`
  ${reset}

  * {
    font-family: "Noto Sans KR", "Roboto", sans-serif !important;
    box-sizing: border-box;
  }

  *:before, *:after {
    box-sizing: border-box;
  }
  
  body {
  }

  .modal-backdrop {
    background: rgba(0, 0, 0, 0.4) !important;
  }

  .scrollbar {
    overflow-y: auto;
    scrollbar-track-color: #f4f6f7;
    scrollbar-arrow-color: #babac0;
    scrollbar-3dlight-color: #f4f6f7;
    scrollbar-highlight-color: #f4f6f7;
    scrollbar-shadow-color: #babac0;
    scrollbar-face-color: #babac0;

    &::-webkit-scrollbar {
      background: inherit;
      width: 4px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .scrollbar-x {
    overflow-x: auto;
    scrollbar-track-color: #f4f6f7;
    scrollbar-arrow-color: #babac0;
    scrollbar-3dlight-color: #f4f6f7;
    scrollbar-highlight-color: #f4f6f7;
    scrollbar-shadow-color: #babac0;
    scrollbar-face-color: #babac0;

    &::-webkit-scrollbar {
      background: inherit;
      width: 4px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .scrollbar-all {
    overflow: auto;
    scrollbar-track-color: #f4f6f7;
    scrollbar-arrow-color: #babac0;
    scrollbar-3dlight-color: #f4f6f7;
    scrollbar-highlight-color: #f4f6f7;
    scrollbar-shadow-color: #babac0;
    scrollbar-face-color: #babac0;

    &::-webkit-scrollbar {
      background: inherit;
      width: 4px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .block-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${datepickerStyle}
  ${toastAnimation}
`;

export default Global;

export const layoutSizes = {};
