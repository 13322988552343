import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";

import CurriculumModalContent from "views/question/curriculum/CurriculumModalContent";
import QuestionModalContent from "views/question/curriculum/QuestionModalContent";

const CurriculumSpecificModal = observer(() => {
  const {mathClassificationResult} = useStores();
  const modalId = "curriculumSpecificModal";

  const type = useMemo(() => {
    return mathClassificationResult.detail!.classification_type
  }, [mathClassificationResult.detail]);

  const clear = async () => {
    await mathClassificationResult.clearDetail();
  };

  return (
    <MaruModal
      id={modalId}
      size={type === "question" ? "xl" : "lg"}
      customWidth={type === "question" ? "1184px" : "800px"}
      onClose={clear}
      onOkayClick={clear}
    >
      <Heading className="block-select" id="draggable-handle">
        {(() => {
          switch (type) {
            case "rule":
              return <>표준커리큘럼 자세히 보기</>;

            case "question":
              return <>검수완료 기출문항 자세히 보기</>;
          }
        })()}
      </Heading>

      <Content>
        {(() => {
          switch (type) {
            case "rule":
              return <CurriculumModalContent />;

            case "question":
              return <QuestionModalContent />;
          }
        })()}
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Content = styled.div`
  border-radius: 4px;
`;

export default CurriculumSpecificModal;
