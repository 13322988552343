import {makeAutoObservable} from "mobx";
import repository from "repositories/MathCurriculumUnit";
import {StoreState} from "types/common";
import {
  MathCurriculumUnitSelect as MathCurriculumUnitSelectSchema, MathCurriculumUnitSelectRequest,
} from "types/unit/math/schema"


export default class MathCurriculumUnitSelect {
  state: StoreState = "none";
  result?: MathCurriculumUnitSelectSchema[] = [];
  selected?: MathCurriculumUnitSelectSchema;
  question_id?: string;
  rep_question_id?: string;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(payload: MathCurriculumUnitSelectRequest) {
    this.state = "pending";
    try {
      const response = await repository.readSelectMany(payload);
      this.result = response.data.selects;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async select(selected: MathCurriculumUnitSelectSchema,
               rep_question_id?: string,
               question_id?: string) {
    this.selected = selected;
    this.rep_question_id = rep_question_id;
    this.question_id = question_id;
  }

  async clear() {
    this.state = "none";
    this.result = [];
    this.rep_question_id = undefined;
    this.question_id = undefined;
    this.selected = undefined;
  }
}
