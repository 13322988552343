import React, { useState, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { observer } from "mobx-react-lite";
import { MathJax } from "better-react-mathjax";
import styled from "styled-components";

import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import useStores from "hooks/useStores";

import { toastMessage } from "messages";

import {MathCurriculumUnit} from "types/unit/math/model";

import { removeHTMLTags, fixForMathJax, subString } from "utils";


interface SimulationCurriculumItemProps {
  unit: MathCurriculumUnit;
}

const SimulationCurriculumItem = observer(({ unit }: SimulationCurriculumItemProps) => {
  const { mathRuleUnitSimulation } = useStores();
  const [mouseOver, setMouseOver] = useState(false);
  const modal = useModal();
  const toast = useToast();

  const level1 = useMemo(() => removeHTMLTags(unit?.level1), [unit]);
  const level2 = useMemo(() => removeHTMLTags(unit?.level2), [unit]);
  const level3 = useMemo(() => removeHTMLTags(unit?.level3), [unit]);

  const snippet = useMemo(() => {
    const _result = [];
    if (level1) _result.push(...["(대발문)", level1, "</br>"]);
    if (level3) _result.push(...["(문제)", level3, "</br>"]);
    if (level2) _result.push(...["(지문)", level2]);
    const result = fixForMathJax(_result.join(" "));
    return subString(result);
  }, [level1, level2, level3]);


  const handleSimulationItemClick = async () => {
    await mathRuleUnitSimulation.setDetail(unit);
    modal.open("mainQuestionDetailModal");
  };

  const handleQuestionIdClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    navigator.clipboard.writeText(unit._id);
    toast.fireToast({
      type: "success",
      content: toastMessage.success.copyQuestionId,
      duration: 5000,
    });
  };

  return (
    <SimulationItemStyle onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)} onClick={handleSimulationItemClick}>
      <MathJax>
        <Row>
          <Cell
            className="ellipsis"
            data-tip
            data-for={unit._id}
            style={{
              display: "inline-block",
              width: "120px",
              fontWeight: "500",
              textDecoration: mouseOver ? "underline" : "none",
              cursor: "pointer",
              marginRight: "8px",
            }}
            onClick={handleQuestionIdClick}
          >
            {unit._id}
          </Cell>
          <IdTooltip delayShow={600} id={unit.detail_type_unit_cd} effect="solid">
            <TooltipContent>{unit.detail_type_unit_cd}</TooltipContent>
          </IdTooltip>
          <Unit>
            <Cell style={{ display: "block", flex: "1" }}>
              <div>{
                `${unit.unit1_name}${unit.unit2_name}${unit.unit3_name}`
              }</div>
              <div>{
                `${unit.time_unit_name}${unit.type_unit_name}${unit.detail_type_unit_name}`
              }</div>
            </Cell>
          </Unit>
        </Row>


        <Content dangerouslySetInnerHTML={{ __html: snippet }} />
      </MathJax>
    </SimulationItemStyle>
  );
});

const SimulationItemStyle = styled.div`
  margin-bottom: 4px;
  padding: 16px 24px;

  border: 1px solid ${(props) => props.theme.border.DIM};
  background: ${(props) => props.theme.background.PANEL_WHITE};

  line-height: normal;
  font-size: 13px;
  color: ${(props) => props.theme.text.BALCK};

  &:hover {
    border: 1px solid ${(props) => props.theme.border.DIVIDER};
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
`;
const IdTooltip = styled(ReactTooltip)`
  padding: 8px !important;

  border-radius: 4px !important;
  background: #333 !important;
`;
const TooltipContent = styled.div`
  padding: 4px 0;

  line-height: normal;
  font-size: 12px;
  color: ${(props) => props.theme.text.PLACEHOLDER};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Cell = styled.div`
  flex-shrink: 0;
`;

const Unit = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-left: 8px;

  color: ${(props) => props.theme.text.GREY};
`;

const Content = styled.div`
  margin-top: 8px;
  padding: 16px;

  border: 1px solid #f2f2f2;
  background: #fbfbfb;

  text-align: justify;
  line-height: 1.4rem;
  font-size: 13px;
  color: ${(props) => props.theme.text.MAIN};
`;

export default SimulationCurriculumItem;
