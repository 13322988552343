interface CancelIconProps extends React.HTMLAttributes<HTMLElement> {
  size: number;
  color?: string;
}

const CancelIcon = ({ size, color = "#fff" }: CancelIconProps) => {
  return (
    <svg
      id="icon_cancle_16"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <rect
        id="사각형_24808"
        data-name="사각형 24808"
        width="16"
        height="16"
        fill="#fff"
        opacity="0"
      />
      <path
        id="합치기_7"
        data-name="합치기 7"
        d="M-5904.639,50.009a.6.6,0,0,1,0-.851l2.974-2.974-2.974-2.974a.6.6,0,0,1,0-.849.6.6,0,0,1,.849,0l2.975,2.974,2.974-2.974a.6.6,0,0,1,.851,0,.6.6,0,0,1,0,.849l-2.975,2.975,2.975,2.974a.6.6,0,0,1,0,.849.6.6,0,0,1-.851,0l-2.974-2.973-2.975,2.975a.6.6,0,0,1-.425.176A.6.6,0,0,1-5904.639,50.009Z"
        transform="translate(5908.852 -38.186)"
        fill={color}
      />
    </svg>
  );
};

export default CancelIcon;
