import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/Unit";
import {StoreState} from "types/common";
import {Unit} from "types/unit/model"


export default class UnitSearch {
  state: StoreState = "none";
  units?: Unit[] = [];
  detail?: Unit;
  textbookId?: string[];
  query: string = "";
  searchedQuery?: string;
  hasNext: boolean = true;
  total?: number;
  page: number = 1;
  size: number = 20;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(textbookId: string[]) {
    this.state = "pending";
    try {
      this.textbookId = textbookId
      if (_.isUndefined(this.searchedQuery)) {
        this.units = [];
        this.searchedQuery = "";
        this.state = "ready";
        return;
      } else this.searchedQuery = this.query;

      const response = await repository.searchUnit({
        textbookId: textbookId,
        query: this.query,
        page: this.page,
        size: this.size
      });

      this.units = response.data.units;
      this.total = response.data.total;

      this.hasNext = this.units!.length !== this.total;

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async nextScroll() {
    this.page = this.page + 1;
    const response = await repository.searchUnit({
      textbookId: this.textbookId,
      query: this.query,
      page: this.page,
      size: this.size
    });
    this.units!.push(...response.data.units);
    this.total = response.data.total;

    this.hasNext = this.units!.length !== this.total;
  }

  async setQuery(query: string) {
    this.query = query;
  }

  async setDetail(unit: Unit) {
    this.detail = unit;
  }

  async clear() {
    this.state = "none";
    this.total = undefined;
    this.textbookId = undefined;
    this.units = [];
    this.query = "";
    this.searchedQuery = undefined;
    this.detail = undefined;
    this.size = 20;
    this.page = 1;
    this.hasNext = true;
  }

  async search() {
    this.page = 1;
    this.state = "none";
  }
}
