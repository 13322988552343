import { makeAutoObservable } from "mobx";
import repository from "repositories/Question";
import { StoreState } from "types/common";
import {
  ClassificationResult as ClassificationResultSchema,
  ClassificationCandidateResult
} from "types/question/model"

export default class ClassificationResult {
  state: StoreState = "none";
  result?: ClassificationResultSchema;
  detail?: ClassificationCandidateResult;

  constructor() {
    makeAutoObservable(this);
  }

  async analyze(questionId: string) {
    this.state = "pending";
    try {
      const response = await repository.analyzeClassificationResult(questionId);
      this.result = response.data;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async setDetail(result: ClassificationCandidateResult) {
    this.detail = result;
  }

  async clearDetail() {
    this.detail = undefined;
  }

  async clear() {
    this.state = "none";
    this.result = undefined;
    this.detail = undefined;
  }
}
