import {makeAutoObservable} from "mobx";
import repository from "repositories/MathRule"

import {StoreState} from "types/common";
import {Rule} from "types/rule/model"
import {Question} from "types/question/model";
import {RuleFilterOptions} from "types/rule/schema";


export default class MathRuleSimulation {
  rule?: Rule;
  state: StoreState = "pending";
  filter?: RuleFilterOptions;
  detail?: Question;
  top20State: StoreState = "none";
  expectedState: StoreState = "none";
  top20: Question[] = [];
  top20Total: number = 0;
  result: Question[] = [];
  expectedTotal: number = 0;
  page: number = 1;
  size: number = 20;
  total: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async readTop20Many(rule: Rule) {
    this.top20State = "pending";
    this.rule = rule;
    try {
      const response = await repository.simulate(
        {
          rule: rule,
          page: 1,
          size: 20
        }
      )
      this.top20 = response.data.questions;
      this.top20Total = response.data.total;
      this.top20State = "done";
    } catch (e) {
      this.top20State = "error";
      throw e;
    }
  }

  async readMany() {
    this.state = "pending";
    try {
      const response = await repository.simulate(
        {
          rule: this.rule!,
          page: this.page,
          size: this.size
        }
      )
      this.result = response.data.questions;
      this.total = response.data.total;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async getTotal() {
    this.expectedState = "pending";
    try {
      const response = await repository.simulate(
        {
          rule: this.rule!,
          filters: this.filter,
        }
      )
      this.expectedTotal = response.data.total;
      this.expectedState = "done";
    } catch (e) {
      throw e;
    }
  }

  async setPage(page: number) {
    this.page = page;
  }

  async setSize(size: number) {
    this.page = 1;
    this.size = size
  }

  async setFilter(filter: RuleFilterOptions) {
    this.filter = filter;
  }

  async clearResult() {
    this.result = [];
    this.state = "pending"
    this.page = 1;
    this.size = 20;
    this.total = 0;
  }

  async clearExpected() {
    this.expectedTotal = 0;
  }

  async setDetail(question: Question) {
    this.detail = question;
  }

  async clearDetail() {
    this.detail = undefined;
  }

  async clear() {
    this.state = "pending";
    this.top20State = "none";
    this.expectedState = "none";
    this.result = [];
    this.top20 = [];
    this.rule = undefined;
    this.filter = undefined;
    this.detail = undefined;
    this.top20Total = 0;
    this.expectedTotal = 0;
    this.total = 0;
    this.page = 1;
    this.size = 20;
  }

  async search() {
    this.state = "none";
  }
}
