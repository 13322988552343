import styled from "styled-components";

import { Spinner as ReactSpinner } from 'react-bootstrap';

interface ButtonOptions {
  disabledButtonColor?: string;
  disabledBorderColor?: string;
  disabledTextColor?: string;
}

interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  options?: ButtonOptions;
  pending?: boolean;
  dynamicHover?: boolean;
}

const Button = ({ disabled, options, pending, dynamicHover = false, children, ...props }: ButtonProps) => {
  return (
    <ButtonStyle
      className="block-select"
      disabled={disabled || pending}
      dynamicHover={dynamicHover}
      options={options}
      {...props}
    >
      {pending ? 
        <ReactSpinner animation="border" size="sm">
          <span className="visually-hidden">불러오는중...</span>
        </ReactSpinner> :
        <>
         {children}
        </>
      }
    </ButtonStyle>
  );
};

interface ButtonStyleProps {
  disabled?: boolean;
  options?: ButtonOptions;
  dynamicHover: boolean;
}

const ButtonStyle = styled.button<ButtonStyleProps>`
  min-width: 60px;
  height: 40px;

  border: none;
  border-color: ${(props) => {
    if (props.disabled) {
      if (props.options && props.options.disabledBorderColor)
        return `${props.options.disabledBorderColor} !important`;
    } 
  }};
  border-radius: 4px;
  background: ${(props) => {
    if (props.disabled) {
      if (props.options && props.options.disabledButtonColor)
        return `${props.options.disabledButtonColor} !important`;

      return "#f2f2f2";
    } else {
      return props.theme.button.INFO;
    }
  }};

  font-size: 13px;
  color: ${(props) => {
    if (props.disabled) {
      if (props.options && props.options.disabledTextColor)
        return `${props.options.disabledTextColor} !important`;
    } else {
      return "white";
    }
  }};
  line-height: normal;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background-image: ${(props) => props.dynamicHover ? "linear-gradient(rgb(0 0 0/10%) 0 0)" : "none"};
  }
`;

export default Button;
