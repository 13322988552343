import { makeAutoObservable } from "mobx";
import repository from "repositories/Authentication";
import { StoreState } from "types/common";
import {
  LoginUser,
  SimpleUser,
  UserCreate,
  UserLogin
} from "types/user/schema";

export default class Authentication {
  user?: LoginUser;
  users?: SimpleUser[];
  state: StoreState = "none";
  isLogin: boolean = false;
  isDanbiAdmin: boolean = false;
  isDanaAdmin: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  async proof() {
    this.state = "pending";
    try {
      const res = await repository.proofUser();
      this.user = {
        email: res.data.email,
        name: res.data.username,
        is_admin: res.data.is_admin || res.data.role === "owner",
        role: res.data.role,
        team: res.data.team,
        nickname: res.data.nickname
      };
      this.isDanbiAdmin = this.user.role === "general-manager"
      this.isDanaAdmin = this.user.role === "search-manager"
      this.isLogin = true;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  admin() {
    return this.user?.is_admin || this.isDanaAdmin || this.isDanbiAdmin;
  }

  async logout() {
    try {
      await repository.logout();
      await this.clear();
    } catch (e) {
      throw e;
    }
  }

  async login(payload: UserLogin) {
    this.state = "pending";
    try {
      await repository.login(payload);
      this.isLogin = true;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async join(payload: UserCreate) {
    try {
      const res = await repository.join(payload);
      return res.data;
    } catch (e) {
      throw e;
    }
  }

  async readUserMany() {
    try {
      const res = await repository.readUserMany();
      this.users = res.data;
    } catch (e) {
      throw e;
    }
  }

  async clear() {
    this.state = "none";
    this.user = undefined;
    this.isLogin = false;
    this.isDanbiAdmin = false;
    this.isDanaAdmin = false;
  }
}
