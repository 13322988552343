import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {useDownloader} from "hooks/useDownloader";

import Radio from "components/Radio";

import { toastMessage } from "messages";

const CurriculumQuestionDownloadModal = observer(() => {
  const modalId = "curriculumQuestionDownloadModal";
  const toast = useToast();
  const downloader = useDownloader();
  const { mathQuestionList } = useStores();

  const count = useMemo(() => mathQuestionList.questionStorage.length, [mathQuestionList.questionStorage.length]);

  const handleOkayClick = async () => {
    if (!await downloader.download(mathQuestionList.download())) return;
    toast.fireToast({
      type: "success",
      content: mathQuestionList.fileType==="excel"?toastMessage.success.downloadExcel:toastMessage.success.downloadJson,
      duration: 3000,
    });
    await clear();
  };

  const clear = async () => {
    await mathQuestionList.clearQuestionStorage();
    await mathQuestionList.clearFileType();
  }

  return (
    <MaruModal
      id={modalId}
      title="문항 내려받기"
      onOkayClick={handleOkayClick}
      onClose={clear}
      buttonText="내려받기"
    >
      <ModalInnerPanel>
        {count > 0 ?
          <Paragraph>
            <em className="text-success">선택한 문항({count}건)</em>
            <b>을 내려받을 포맷을 선택해주세요.</b>
          </Paragraph> : 
          <Paragraph>
            <b>조회된 문항을 내려받을 포맷을 선택해주세요.</b><br />
            (최대 1000건 내려받기 가능)
          </Paragraph>
        }
      </ModalInnerPanel>

      <Format>
        <Radio checked={mathQuestionList.fileType === "excel"} size="sm" onClick={async () => await mathQuestionList.setFileType("excel")} />
        <em>Excel</em>
      </Format>
      <Format>
        <Radio checked={mathQuestionList.fileType === "json"} size="sm" onClick={async () => await mathQuestionList.setFileType("json")} />
        <em>Json</em>
      </Format>
    </MaruModal>
  );
});

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    margin-bottom: 8px;
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }
`;

const Format = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0 8px 8px;

  
  > em {
    margin-left: 8px;
    font-size: 13px;
    font-weight: 500;
    color: ${(props) => props.theme.text.MAIN};
    line-height: 1rem;
  }
`;

export default CurriculumQuestionDownloadModal;