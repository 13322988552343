import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/Unit";
import {StoreState} from "types/common";
import {Unit} from "types/unit/model";
import {
  UnitSelect as UnitSelectSchema,
  UnitSelectRequest
} from "types/unit/schema"


export default class UnitSelect {
  state: StoreState = "none";
  result?: UnitSelectSchema[] = [];
  selected?: UnitSelectSchema;
  textbookId?: string;
  question_id?: string;
  page?: number | number[];

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(payload: UnitSelectRequest) {
    this.state = "pending";
    try {
      this.textbookId = payload.textbookId
      const response = await repository.readSelectMany(payload);
      this.result = response.data.selects;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async select(selected: UnitSelectSchema,
               page?: number | number[],
               question_id?: string) {
    if (!page) {
      const response = await repository.read(selected.unitCode);
      const unit: Unit = response.data
      this.page = _.range(unit.start_page!, unit.end_page!+1);
    } else this.page = page;
    this.selected = selected;
    this.question_id = question_id;
  }

  async clear() {
    this.state = "none";
    this.result = [];
    this.question_id = undefined;
    this.textbookId = undefined;
    this.selected = undefined;
    this.page = undefined;
  }
}
