import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";

import { PAGINATION_SIZE_OPTIONS } from "constant";

import {DropdownItemType} from "types/common";

import PaginationSizeSelector from "components/PaginationSizeSelector";
import Pagination from "components/Pagination";
import Spinner from "components/Spinner";

import SimulationItem from "views/rule/unit/SimulationItem";


const SimulationResultModal = observer(() => {
  const {ruleSimulation} = useStores();
  const modalId = "simulationResultModal";

  const handlePageSizeSelect = async (item: DropdownItemType) => {
    await ruleSimulation.setSize(item.value);
    await ruleSimulation.search();
  };

  const handlePageChange = async (page: number) => {
    await ruleSimulation.setPage(page);
    await ruleSimulation.search();
  };

  const selectedPageOption = useMemo(() => {
    return PAGINATION_SIZE_OPTIONS.filter((o) => o.value === ruleSimulation.size)[0];
  }, [ruleSimulation.size]);

  const isPending = useMemo(() => {
    return ruleSimulation.state === "pending";
  }, [ruleSimulation.state])

  const clear = async () => {
    await ruleSimulation.clearResult();
  }

  return (
    <MaruModal
      id={modalId}
      size="xl"
      customWidth="1184px"
      customHeight="744px"
      onClose={clear}
      noPadding
    >
      <Heading id="draggable-handle" className="block-select">
        시뮬레이션 결과 더보기
      </Heading>

      <Box>
        <List className="scrollbar">
          {
            isPending
            ?
              <Spinner />
            :
              ruleSimulation.result.map(q => <SimulationItem key={q._id} question={q}/>)
          }
        </List>

        <Wrapper>
          <PaginationSizeSelector
            options={PAGINATION_SIZE_OPTIONS}
            value={selectedPageOption}
            total={ruleSimulation.total}
            handleSelect={handlePageSizeSelect}
          />
          <Pagination
            total={ruleSimulation.total}
            size={ruleSimulation.size}
            onPageChange={handlePageChange}
            onEnterDown={handlePageChange}
            inputMode
            withText
          />
        </Wrapper>
      </Box>
    </MaruModal>
  );
});

const Heading = styled.h1`
  padding: 20px 24px;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Box = styled.div`
  height: 600px;
  padding: 16px 24px;

  background: #f9f9fb;
`;
const List = styled.div`
  height: 524px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

export default SimulationResultModal;
