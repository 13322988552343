import React from "react";

import Authentication from "stores/Authentication";
import UnitList from "stores/UnitList";
import ActiveUnit from "stores/ActiveUnit";
import QuestionList from "stores/QuestionList";
import ActiveQuestion from "stores/ActiveQuestion";
import UnitSelect from "stores/UnitSelect";
import SimilarQuestionList from "stores/SimilarQuestionList";
import ClassificationResult from "stores/ClassificationResult";
import UnitSearch from "stores/UnitSearch";
import QuestionSearch from "stores/QuestionSearch";
import PageRefList from "stores/PageRefList";
import RuleList from "stores/RuleList";
import RuleSimulation from "stores/RuleSimulation";
import Job from "stores/Job";
import MathCurriculumUnitList from "stores/math/MathCurriculumUnitList";
import ActiveMathCurriculumUnit from "stores/math/ActiveMathCurriculumUnit";
import MathQuestionList from "stores/math/MathQuestionList";
import ActiveMathQuestion from "stores/math/ActiveMathQuestion";
import MathCurriculumUnitSelect from "stores/math/MathCurriculumUnitSelect";
import MathClassificationResult from "stores/math/MathClassificationResult";
import SimilarMathQuestionList from "stores/math/SimilarMathQuestionList";
import MathQuestionSearch from "stores/math/MathQuestionSearch";
import MathCurriculumUnitSearch from "stores/math/MathCurriculumUnitSearch";
import MathRuleSimulation from "stores/math/MathRuleSimulation";
import MathRuleList from "stores/math/MathRuleList";
import MathRuleUnitSimulation from "stores/math/MathRuleUnitSimulation";
import Overview from "stores/stats/Overview";
import ClassificationStatus from "stores/stats/ClassificationStatus";
import FrequentUnitList from "stores/stats/FrequentUnitList";
import QuestionStatsList from "stores/stats/QuestionStatsList";
import TextbookStatsList from "stores/stats/TextbookStatsList";
import InspectionStatsList from "stores/stats/InspectionStatsList";
import AutoClassificationStatsList from "stores/stats/AutoClassificationStatsList";
import QuestionSimulation from "stores/QuestionSimulation";
import BulkTestList from "stores/BulkTestList";

const storeContext = React.createContext({
  authentication: new Authentication(),
  unitList: new UnitList(),
  activeUnit: new ActiveUnit(),
  questionList: new QuestionList(),
  activeQuestion: new ActiveQuestion(),
  unitSelect: new UnitSelect(),
  similarQuestionList: new SimilarQuestionList(),
  classificationResult: new ClassificationResult(),
  unitSearch: new UnitSearch(),
  questionSearch: new QuestionSearch(),
  pageRefList: new PageRefList(),
  ruleList: new RuleList(),
  ruleSimulation: new RuleSimulation(),
  job: new Job(),
  mathCurriculumUnitList: new MathCurriculumUnitList(),
  activeMathCurriculumUnit: new ActiveMathCurriculumUnit(),
  mathQuestionList: new MathQuestionList(),
  activeMathQuestion: new ActiveMathQuestion(),
  mathCurriculumUnitSelect: new MathCurriculumUnitSelect(),
  mathClassificationResult: new MathClassificationResult(),
  similarMathQuestionList: new SimilarMathQuestionList(),
  mathQuestionSearch: new MathQuestionSearch(),
  mathCurriculumUnitSearch: new MathCurriculumUnitSearch(),
  mathRuleSimulation: new MathRuleSimulation(),
  mathRuleList: new MathRuleList(),
  mathRuleUnitSimulation: new MathRuleUnitSimulation(),
  overview: new Overview(),
  classificationStatus: new ClassificationStatus(),
  frequentUnitList: new FrequentUnitList(),
  questionStatsList: new QuestionStatsList(),
  textbookStatsList: new TextbookStatsList(),
  inspectionStatsList: new InspectionStatsList(),
  autoClassificationStatsList: new AutoClassificationStatsList(),
  questionSimulation: new QuestionSimulation(),
  bulkTestList: new BulkTestList(),
});

export default storeContext;
