import { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";

import { DIFFICULTY_ENG_KOR_MAPPING } from "constant";

import Button from "components/Button";
import Levels from "components/Levels";
import InfoItem from "components/InfoItem";

import { engToKor } from "utils";


const MainQuestionDetailModal = observer(() => {
  const {mathRuleUnitSimulation} = useStores();
  const modalId = "mainQuestionDetailModal";
  const [htmlMode, setHtmlMode] = useState(false);

  const unit = useMemo(() => {
    return mathRuleUnitSimulation.detail!
  }, [mathRuleUnitSimulation.detail])

  const hasRule = useMemo(() => {
    return !!unit.rules?.length;
  }, [unit.rules?.length]);

  const clear = async () => {
    await mathRuleUnitSimulation.clearDetail();
  }

  const handleHTMLModeButtonClick = () => {
    setHtmlMode(!htmlMode);
  };

  return (
    <MaruModal id={modalId} size="xl" customWidth="1184px" onClose={clear}>
      <Heading className="block-select" id="draggable-handle">
        기준문항 자세히보기
      </Heading>

      <Content className="scrollbar">
        <Title>표준커리큘럼 정보</Title>
        <InfoBox>
          <InfoItem field="표준커리큘럼 ID" value={unit.detail_type_unit_cd} />
          <InfoItem field="학교급" value={unit.course_name} />
          <InfoItem field="학년-학기" value={unit.grade_semester} />
          <InfoItem field="대단원" value={unit.unit1_name} />
          <InfoItem field="중단원" value={unit.unit2_name} />
          <InfoItem field="소단원" value={unit.unit3_name} />
          <InfoItem field="차시" value={unit.time_unit_name} />
          <InfoItem field="유형" value={unit.type_unit_name} />
          <InfoItem field="세부유형" value={unit.detail_type_unit_name} />
        </InfoBox>

        <Title>기준문항 정보</Title>
        <MainQuestionBox>
          <InfoItem field="기준문항 ID" value={unit._id} />

          <MainQuestionWrapper>
            <FlexWrapper>
              <InfoItem field="난이도" value={engToKor(DIFFICULTY_ENG_KOR_MAPPING, unit.difficultyCd)} />
              <HTMLModeButton onClick={handleHTMLModeButtonClick}>HTML 모드 {htmlMode ? "OFF" : "ON"}</HTMLModeButton>
            </FlexWrapper>

            <Levels level1={unit.level1} level2={unit.level2} level3={unit.level3} htmlMode={htmlMode} />
          </MainQuestionWrapper>
        </MainQuestionBox>

        {hasRule && (
          <>
            <Title>분류규칙 정보</Title>
            {unit.rules?.map((r) => (
            <RuleInfoBox>
              <InfoItem field="분류규칙 ID" value={r.id} />
              <InfoItem field="꼭 포함" value={r.include_and} />
              <InfoItem field="그대로 포함" value={r.include_strict} />
              <InfoItem field="한 개 이상 포함" value={r.include_or} />
              <InfoItem field="제외" value={r.exclude} />
              <InfoItem field="정규표현식" value={r.regex} regex />
            </RuleInfoBox>
          ))}
          </>
        )}
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Content = styled.div`
  max-height: 720px;
`;

const Title = styled.h1`
  padding: 16px;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
`;

const InfoBox = styled.div`
  padding: 16px;

  border-top: 2px solid ${(props) => props.theme.border.DIVIDER};
  background: #fbfbfb;
`;
const RuleInfoBox = styled.div`
  padding: 16px;
  border-top: 2px solid ${(props) => props.theme.border.DIVIDER};
  background: #fbfbfb;
`;
const MainQuestionBox = styled.div`
  margin-top: 8px;
  padding: 16px;
  border-top: 2px solid ${(props) => props.theme.border.DIVIDER};
  background: #fbfbfb;
`;
const MainQuestionWrapper = styled.div`
  margin-top: 4px;
  padding: 12px 0;

  border: 1px solid ${(props) => props.theme.border.DIM};
  background-color: ${(props) => props.theme.background.PANEL_WHITE};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px;
`;
const HTMLModeButton = styled(Button)`
  width: 120px;
  height: 32px;

  background-color: ${(props) => props.theme.background.SUCCESS};

  &:hover {
    background-color: #40a547;
  }
`;

export default MainQuestionDetailModal;
