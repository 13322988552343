import { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import useStores from "hooks/useStores";
import { useToast } from "hooks/useToast";
import { MaruModal, useModal } from "hooks/useModal";
import { useErrorHandler } from "hooks/useErrorHandler";

import Button from "components/Button";
import { ReactComponent as CancelIcon } from "assets/icons/icon_cancel_16.svg";
import { CLASSIFICATION_TYPE_ENG_KOR_MAPPING, INSPECTION_STATUS_ENG_KOR_MAPPING, ROUTERS } from "constant";
import { toastMessage } from "messages";

import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";

import { engToKor, scoreToPercent } from "utils";


const SimilarCurriculumQuestionModal = observer(() => {
  const { similarMathQuestionList, mathQuestionList, mathCurriculumUnitSelect, activeMathCurriculumUnit } = useStores();
  const modalId = "similarCurriculumQuestionModal";
  const [mouseOver, setMouseOver] = useState(false);
  const [htmlMode, setHtmlMode] = useState(false);
  const toast = useToast();
  const modal = useModal();
  const handler = useErrorHandler();

  const question = useMemo(() => {
    return similarMathQuestionList.detailQuestion;
  }, [similarMathQuestionList.detailQuestion]);

  const unit = useMemo(() => {
    return question?._parent
  }, [question?._parent])

  const inspected = useMemo(() => {
    return question?.inspection_status === "inspected" || question?.inspection_status === "hold";
  }, [question?.inspection_status]);

  //* Event methods
  const handleQuestionIdClick = () => {
    window.location.replace(ROUTERS.inspection.curriculumQuestionId(question!._id));
  };

  const handleCancelInspectionClick = async () => {
    if (
      await handler.handle(
        mathQuestionList.updateMany({
          question_ids: [question!._id],
          values: {
            is_completed: 0,
          },
        })
      )
    )
      return;
    modal.close(modalId);
    toast.fireToast({
      type: "fail",
      content: toastMessage.fail.cancelInspection,
      duration: 5000,
    });
    await mathCurriculumUnitSelect.clear();
    await similarMathQuestionList.clear();
    await activeMathCurriculumUnit.clear();
  };

  const handleHTMLModeButtonClick = () => {
    setHtmlMode(!htmlMode);
  };

  return (
    <MaruModal id={modalId} customWidth="1184px" size="xl">
      <Heading id="draggable-handle">유사문항 자세히 보기</Heading>

      <Row style={{ transform: "translateY(-2px) " }}>
        <Info style={{ alignItems: "center" }}>
          <Field>문항 ID</Field>
          <Value
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            onClick={handleQuestionIdClick}
            mouseOver={mouseOver}
          >
            <b>{question!._id}</b>
          </Value>
        </Info>

        <Info style={{ transform: "translateY(1.5px)" }}>
          <Field>상태</Field>
          <Value style={{ display: "flex" }}>
            <InspectionStatusText status={question!.inspection_status}>
              {engToKor(INSPECTION_STATUS_ENG_KOR_MAPPING, question!.inspection_status)}
            </InspectionStatusText>
            <SideChip style={{ transform: "translateY(-3px)" }}>{mathQuestionList.getInspectionStatus(question!)}</SideChip>
            {unit && unit.checker_email && (
              <em style={{ color: "#484848", opacity: "0.8" }}>{`(${unit.checker_email})`}</em>
            )}

            {inspected && (
              <CancelButton onClick={handleCancelInspectionClick}>
                <CancelIcon />
                검수취소
              </CancelButton>
            )}
          </Value>
        </Info>
      </Row>

      <Row>
        <Info>
          <Field>시험지 ID</Field>
          <Value>{question?.pocCode}</Value>
        </Info>

        <Info>
          {question?.checker_type && (
            <>
              <Field>최종분류근거</Field>
              <Value>
                <em className="text-info" style={{ marginRight: "4px" }}>
                  {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
                </em>
                <em className="text-grey">{question!.score && `(${scoreToPercent(question.score)})`}</em>
              </Value>
            </>
          )}
        </Info>
      </Row>

      <Row>
        <Info>
          <Field>시험정보</Field>
          <Value>{mathQuestionList.questionInfo(question)}</Value>
        </Info>

        <Info>
          {
            question?.rep_question_id &&
            <>
              <Field>기준문항 ID</Field>
              <Value>{question?.rep_question_id}</Value>
            </>
          }
        </Info>
      </Row>

      <Row style={{ marginTop: "6px" }}>
        <Info style={{ lineHeight: "normal", margin: "0" }}>
          <Field>표준커리큘럼 ID</Field>
          <Value>{question?.unitCode}</Value>
        </Info>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Info style={{ margin: "0" }}>
          <Field>표준커리큘럼정보</Field>
          <Value>{question?.grade_semester}</Value>
        </Info>
      </Row>

      <Row>
        <Info style={{ margin: "0" }}>
          <Field></Field>
          <Value style={{ lineHeight: "normal" }}>
            {question?.unit_name}
          </Value>
        </Info>
      </Row>

      <QuestionBox className="scrollbar">
        <Levels level1={question?.level1} level2={question?.level2} level3={question?.level3} htmlMode={htmlMode} />

        <FlexWrapper>
          <HTMLModeButton onClick={handleHTMLModeButtonClick}>HTML 모드 {htmlMode ? "OFF" : "ON"}</HTMLModeButton>
        </FlexWrapper>
      </QuestionBox>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
  padding: 0 16px;
  min-height: 20px;
`;
const Info = styled.div`
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  min-height: 25px;

  flex: 1;
  font-size: 13px;
  line-height: normal;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  width: 96px;
  margin-right: 24px;
  flex-shrink: 0;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div<{ mouseOver?: boolean }>`
  color: ${(props) => props.theme.text.BLACK};

  text-decoration: ${(props) => (props.mouseOver ? "underline" : "none")};
  cursor: ${(props) => (props.mouseOver ? "pointer" : "default")};

  > b {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-grey {
    color: ${(props) => props.theme.text.GREY};
  }
`;

const SideChip = styled.div`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const CancelButton = styled(Button)`
  margin-left: 32px;
  background: none;
  color: ${(props) => props.theme.text.DANGER};

  border: none;

  transform: translateY(-3px);

  svg {
    fill: ${(props) => props.theme.text.DANGER};
  }
`;

const QuestionBox = styled.div`
  display: flex;
  justify-content: space-between;
  
  max-height: 600px;
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.DIM};
`;

const FlexWrapper = styled.div`
  height: 32px;
  margin-top: 16px;
  margin-right: 16px;
`;
const HTMLModeButton = styled(Button)`
  width: 120px;
  height: 32px;

  border: none;
  border-radius: 4px;
  background-color: ${(props) => props.theme.background.SUCCESS};

  font-size: 13px;
  color: ${(props) => props.theme.text.WHITE};

  &:hover {
    background-color: #40a547;
  }
`;

export default SimilarCurriculumQuestionModal;
