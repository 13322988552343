interface CheckIconProps {
  size: number;
  color?: string;
}

const CheckIcon = ({ size, color = "#fff" }: CheckIconProps) => {
  return (
    <svg
      id="icon_check_w_16"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
    >
      <rect
        id="사각형_24806"
        data-name="사각형 24806"
        width="16"
        height="16"
        fill="#fff"
        opacity="0"
      />
      <path
        id="패스_10722"
        data-name="패스 10722"
        d="M126.416,140.3h0a.832.832,0,0,1-.615-.265l-2.953-3.271a.687.687,0,0,1,.108-1.027.849.849,0,0,1,1.123.1l2.338,2.59,5.292-5.86a.85.85,0,0,1,1.123-.1.687.687,0,0,1,.108,1.026l-5.907,6.542A.83.83,0,0,1,126.416,140.3Z"
        transform="translate(-119.666 -128.3)"
        fill={color}
      />
    </svg>
  );
};

export default CheckIcon;
