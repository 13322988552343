interface ReturnIconProps {
  size: number;
  color?: string;
}

const ReturnIcon = ({ size, color = "#fff" }: ReturnIconProps) => {
  return (
    <svg
      id="icon_return_16"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <rect
        id="사각형_24807"
        data-name="사각형 24807"
        width="16"
        height="16"
        fill="#ccc"
        opacity="0"
      />
      <path
        id="패스_10723"
        data-name="패스 10723"
        d="M542.407,86.981A3.181,3.181,0,0,0,539.469,85h-3.418l1.166-1.17a.429.429,0,1,0-.608-.606l-1.845,1.851a.421.421,0,0,0-.111.376.412.412,0,0,0,.111.281l1.845,1.845a.43.43,0,0,0,.608-.607l-1.112-1.112h3.364a2.329,2.329,0,0,1,2.15,1.463,2.1,2.1,0,0,1,.169.856,2.321,2.321,0,0,1-2.319,2.319h-4.134a.429.429,0,1,0,0,.859h4.134a3.181,3.181,0,0,0,3.177-3.177A2.928,2.928,0,0,0,542.407,86.981Z"
        transform="translate(-530.646 -79.099)"
        fill={color}
      />
    </svg>
  );
};

export default ReturnIcon;
