import { makeAutoObservable } from "mobx";

import repository from "repositories/Stats";
import { StoreState } from "types/common";
import { StatItem } from "types/stats/model";

export default class FrequentUnitList {
  state: StoreState = "none";
  list: StatItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async getTop10Unit(publisher: string, textbook: string) {
    this.state = "pending";
    const response = await repository.getFrequentUnits(publisher, textbook);
    this.list = response.data.response;
    this.list = this.list.slice(0, 10);
    this.state = "done";
  }

  setReady() {
    this.state = "none";
  }

  clear() {
    this.list = [];
    this.state = "none";
  }
}
