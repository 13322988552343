import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import styled from "styled-components";

const SuspenseLoader = () => {
  const [isPrepare, setIsPrepare] = useState(false);

  useEffect(() => {
    return () => {
      setIsPrepare(false);
    };
    // eslint-disable-next-line
  }, []);

  setTimeout(() => {
    setIsPrepare(true);
  }, 3000);

  return (
    <Wrapper>
      <div>{isPrepare && <MaruSpinner animation={"border"} />}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: #ffffff !important;
  height: 100vh !important;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
  }
`;

const MaruSpinner = styled(Spinner)`
  margin-right: 8px;
  color: #202d86 !important;
`;

export default SuspenseLoader;
