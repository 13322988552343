import styled from "styled-components";

import { MaruModal } from "hooks/useModal";

interface OptionType {
  rule: string;
  description: string;
  example: string;
  result: string;
}
const OPTIONS: OptionType[] = [
  {
    rule: ".",
    description: "모든 문자 매치",
    example: "a.",
    result: "aa, ab, ac, az..."
  },
  {
    rule: "?",
    description: "앞의 문자를 선택적으로 간주",
    example: "ab?",
    result: "a, ab"
  },
  {
    rule: "+",
    description: "앞의 문자를 한 번 이상 반복",
    example: "ab+",
    result: "ab, abb …"
  },
  {
    rule: "*",
    description: "앞의 문자를 0번 이상 반복",
    example: "ab*",
    result: "a, ab, abb …"
  },
  {
    rule: "{}",
    description: "앞의 문자를 반복할 수 있는 횟수를 설정",
    example: "a{2,4}",
    result: "aa, aaa, aaaa"
  },
  {
    rule: "|",
    description: "앞의 문자열과 뒤의 문자열을 OR 검색",
    example: "abc|xyz",
    result: "abc와 xyz"
  },
]

const RegexInfoModal = () => {
  const modalId = "regexInfoModal";

  return (
    <MaruModal
      id={modalId}
      size="lg"
      customWidth="776px"
    >
      <Heading id="draggable-handle">
        정규표현식 입력 방법
      </Heading>

      <Header>
        <Row>
          <Cell style={{ width: "60px" }}>규칙</Cell>
          <Cell style={{ flex: "1" }}>설명</Cell>
          <Cell style={{ width: "80px" }} >입력예시</Cell>
          <Cell style={{ width: "200px" }} >검색결과</Cell>
        </Row>
      </Header>

      <Content>
        {OPTIONS.map((option, i) => (
          <Row key={`option-${i}`}>
            <Cell style={{ width: "60px" }}>{option.rule}</Cell>
            <Cell style={{ flex: "1" }}>{option.description}</Cell>
            <Cell style={{ width: "80px" }} >{option.example}</Cell>
            <Cell style={{ width: "200px" }} >{option.result}</Cell>
          </Row>
        ))}
        <Row>
          <Cell style={{ width: "60px" }}>[]</Cell>
          <Cell style={{ flex: "1" }}>안에 있는 문자들을 한 개 이상 포함하는 경우를 검색</Cell>
          <Cell style={{ width: "80px" }} >
            [abc] <br />
            [a-z] <br />
            [0-9] <br />
            [^a-z]
          </Cell>
          <Cell style={{ width: "200px" }} >
            a, b, c <br />
            a부터 z까지의 모든 알파벳 <br />
            0, 1, 2, 3, 4, 5, 6, 7, 8, 9 <br />
            a부터 z까지의 모든 알파벳을 제외
          </Cell>
        </Row>
        
        <Row>
          <Cell style={{ width: "60px" }}>＼d</Cell>
          <Cell style={{ flex: "1" }}>숫자</Cell>
          <Cell style={{ width: "80px" }} >＼d+</Cell>
          <Cell style={{ width: "200px" }} >
            1, 11, 2, 234, 3768 <br />
            숫자가 한 개 이상 있는 모든 경우
          </Cell>
        </Row>
      </Content>

    </MaruModal>
  );
};

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};

  > em {
    margin-left: 8px;
    font-weight: normal;
  }
`;

const Header = styled.div`
  border-bottom: 2px solid black;
  padding: 0 12px;
`;

const Content = styled.div`
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  border-top: none;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 8px 16px;

  border-bottom: 1px solid #EAEAEA;

  font-size: 13px;
  color: ${(props) => props.theme.text.BLACK};
  line-height: normal;

  &:last-child {
    border: none;
  }
`;
const Cell = styled.div`
  flex-shrink: 0;
`;

export default RegexInfoModal;
