import {useToast} from "hooks/useToast";
import {AxiosResponse} from "axios";


export const useDownloader = () => {
    const toast = useToast();

    const download = async (p: Promise<any>): Promise<any> => {
      let errored = false;
      await p
        .then(res => {
          resolveBlob(res);
        })
        .catch(e => {
          errored = true;
          toast.fireToast({
                type: "fail",
                content: typeof e.response.data === "string"
                  ? e.response.data
                  : e.response.data.message,
                duration: 5000,
          })
      })
      return errored;
    }
    return { download };
}

const resolveBlob = (response: AxiosResponse) => {
  const headers = response.headers['content-disposition'];
  let filename = headers!.split(';')[1].split('=')[1].replace('"', '').replace('"', '');
  filename = decodeURI(filename);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  link.remove();
}