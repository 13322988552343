import React from "react";
import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

type ConditionRouteProps = React.PropsWithChildren<{
  condition: boolean;
  redirectPath: string;
}>;

const ConditionRoute: React.FC<ConditionRouteProps> = observer(
  (props) => {
    if (props.condition) {
      return <>{props.children}</>;
    } else {
      return <Navigate replace to={props.redirectPath} />;
    }
  }
);

export default ConditionRoute;
