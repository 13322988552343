import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/MathQuestion";

import {Facet, StoreState} from "types/common";
import {
  QuestionFilterOptions,
  QuestionSortingOptions,
  QuestionUpdateRequest
} from "types/question/schema";
import {Question} from "types/question/model";

const MathQuestionStorage = {
  INSPECTION_MATH_QUESTION: "inspectionMathQuestion",
  MATH_QUESTION: "mathQuestion"
} as const;

type MATH_QUESTION_STORAGE = typeof MathQuestionStorage[keyof typeof MathQuestionStorage]

export default class MathQuestionList {
  facetsFields: string[] = ["year", "rep_question_id.keyword"];
  state: StoreState = "none";
  searchState: StoreState = "none";
  downloadState: StoreState = "none";
  storage?: MATH_QUESTION_STORAGE;
  storageState: StoreState = "none";
  questionStorage: Question[] = [];
  questions: Question[] = [];
  questionIds: string[] = [];
  facets: Facet[] = [];
  filters: QuestionFilterOptions = {};
  sorting: QuestionSortingOptions = {};
  checkAll: boolean = false;
  fileType: string = "excel";
  page: number = 1;
  size: number = 20;
  total: number = 0;
  show_all: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  async getNextQuestion(_id: string) {
    const idx = this.questionIds.findIndex(i => i === _id)
    if (idx+1 === this.questionIds.length)
      return this.questionIds[0]
    return this.questionIds[idx+1]
  }

  async getPreviousQuestion(_id: string) {
    const idx = this.questionIds.findIndex(i => i === _id)
    if (idx === 0)
      return this.questionIds[this.questionIds.length-1]
    return this.questionIds[idx-1]
  }

  async setFilterStorage(storage: MATH_QUESTION_STORAGE) {
    localStorage.setItem(`${storage}Filter`, JSON.stringify(this.filters))
  }

  async setSortingStorage(storage: MATH_QUESTION_STORAGE) {
    localStorage.setItem(`${storage}Sorting`, JSON.stringify(this.sorting))
  }

  async getFilterStorage(storage: MATH_QUESTION_STORAGE) {
    const item = localStorage.getItem(`${storage}Filter`)
    return item ? JSON.parse(item) : {}
  }

  async getSortingStorage(storage: MATH_QUESTION_STORAGE) {
    const item = localStorage.getItem(`${storage}Sorting`)
    return item ? JSON.parse(item) : {}
  }

  async getFilterFromStorage(storage: MATH_QUESTION_STORAGE, key: string) {
    const filter = await this.getFilterStorage(storage);
    if (_.isEmpty(filter)) return;
    if (Object.keys(filter).some(k => k === key)) return filter[key]
    else return;
  }

  async readyStorage(storage?: MATH_QUESTION_STORAGE, clear?: boolean) {
    if (storage) {
      this.storageState = "pending";
      this.storage = storage
      if (!clear) {
        this.filters = await this.getFilterStorage(storage);
        this.sorting = await this.getSortingStorage(storage);
      }
      this.storageState = "done";
    }
  }

  async readMany(unitCode?: string,
                 withoutNone: boolean = false,
                 include_id: boolean = false,
                 facets?: string[])
  {
    this.state = "pending";
    try {
      if (unitCode) {
        this.filters.unitCode = unitCode;
      }

      if (facets) this.facetsFields = facets;

      await this.readFacets(unitCode);

      if (this.storage) {
        await this.setFilterStorage(this.storage);
        await this.setSortingStorage(this.storage);
      }

      const response = await repository.readMany({
        filters: this.storage ?await this.getFilterStorage(this.storage): this.filters,
        sorting: this.storage ?await this.getSortingStorage(this.storage): this.sorting,
        include_id: include_id,
        without_none: withoutNone,
        page: this.page,
        size: this.size
      });

      if (Object.keys(this.filters).some(k => k)) this.searchState = "done"
      else this.searchState = "none"

      if (include_id) this.questionIds = response.data.questions
      else this.questions = response.data.questions;

      this.total = response.data.total? response.data.total: 0;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async download() {
    this.downloadState = "pending";
    try {
      const _filters = _.cloneDeep(this.filters)
      if (this.questionStorage.length)
        _filters.id = this.questionStorage.map(q => q._id)
      const response = await repository.download(
        this.fileType,
        {
        filters: _filters,
        sorting: this.storage ?await this.getSortingStorage(this.storage): this.sorting,
        page: 1,
        size: 1000
      });
      this.downloadState = "none"
      return response;
    } catch (e) {
      this.downloadState = "error";
      throw e;
    }
  }

  jobPayload(question_id?: string) {
    if (question_id)
      return {
        filters: {
          id: [question_id]
        },
        sorting: {}
      }
    const _filters = _.cloneDeep(this.filters)
    if (this.questionStorage.length)
      _filters.id = this.questionStorage.map(q => q._id)
    return {
      filters: _filters,
      sorting: this.sorting,
    }
  }

  async read(questionId: string) {
    try {
      const response = await repository.read(questionId);
      return response.data
    } catch (e) {
      throw e;
    }
  }

  async updateMany(payload: QuestionUpdateRequest) {
    try {
      const response = await repository.update(payload);
      await this.clearQuestionStorage();
      return response.data
    } catch (e) {
      throw e;
    }
  }

  async readFacets(unitCode?: string) {
    try {
      const response = await repository.readFacets({fields: this.facetsFields, unitCode: unitCode});
      this.facets = response.data.facets
    } catch (e) {
      throw e;
    }
  }

  async setFileType(fileType: string) {
    this.fileType = fileType;
  }

  async pushAndPopQuestion(question: Question) {
    if (this.questionStorage.some((q) => q._id === question._id)) {
      this.questionStorage = this.questionStorage.filter(q => q._id !== question._id);
    } else {
      this.questionStorage.push(question)
    }
  }

  async switchCheckAll() {
    if (this.checkAll) {
      await this.turnCheckAllOff();
    }else {
      await this.turnCheckAllOn();
    }
  }

  async turnCheckAllOn() {
    this.checkAll = true;
    this.questionStorage = this.questions;
  }

  async turnCheckAllOff() {
    this.checkAll = false;
    this.questionStorage = [];
  }

  async setPage(page: number) {
    this.page = page;
  }

  async setSize(size: number) {
    this.page = 1;
    this.size = size;
  }

  setText(text: string, field: string) {
    if (!text) {
      delete this.filters[field];
      return;
    }
    this.filters[field] = text;
  }

  async switchShowAll() {
    this.show_all = !this.show_all;
  }

  getFacet(field: string) {
    const facet = this.facets.filter(f => field === f.field)[0]
    if (!facet) return []
    return facet.facet
  }

  async pushFilter(field: string, item?: any) {
    if (_.isUndefined(item) || _.isNull(item)) {
      delete this.filters[field];
      return;
    }
    this.filters[field] = item["value"]?item["value"]:item;
  }

  async popFilter(field: string) {
    delete this.filters[field];
  }

  getFilter(field: string) {
    return !_.isUndefined(this.filters[field]) ? this.filters[field] : undefined;
  }

  async setSorting(field: string) {
    this.sorting[field] = this.sorting[field]? this.sorting[field] === "desc" ? "asc" : undefined: "desc"
  }

  getSorting(field: string) {
    return this.sorting[field];
  }

  getInspectionStatus = (question: Question) => {
    const inspected = question.inspection_status === "inspected"
    if (question.inspection_status === "hold") return "분류 불가"
    else if (question.inspection_status === "waiting") return "검수대기"

    if (question.classification_status !== "completed") return "";

    const _rank = [];
    _rank.push(...question.classification_result!.rule);
    _rank.push(...question.classification_result!.question);
    _rank.push(...question.classification_result!.unit);
    const rank1 = _rank[0];
    if (
      inspected &&
      (rank1.unitCode !== question.unitCode ||
        !_.isEqual(rank1.page, question.page) ||
        rank1.classification_type !== question.checker_type )
    ) return "분류 수정"
    else if (
      inspected &&
      rank1.unitCode === question.unitCode &&
      _.isEqual(rank1.page, question.page) &&
      rank1.classification_type === question.checker_type
    ) return "이상없음 확인"
    else return "신규 분류"
  }

  questionInfo = (q?: Question) => {
    if (!q) return ""
    return `${q.local_school && q.local_school+" "}${q.year && q.year+"년 "}${q.subjectNm && q.subjectNm+" "}${q.gradeNm && q.gradeNm+"학년 "}${q.seasonNm && q.seasonNm+"학기 "}${q.examTypeNm && q.examTypeNm+" "}${q.questionNo && q.questionNo+"번 문항"}`;
  }

  async reset() {
    if (!_.isUndefined(this.filters.is_completed))
      this.filters = {
      is_completed: this.filters.is_completed
    }
    else this.filters = {};
    this.sorting = {};
    this.questionStorage = [];
    this.checkAll = false;
    this.page = 1;
    this.size = 20;
  }

  async clearQuestionStorage() {
    this.questionStorage = [];
  }

  async clearFileType() {
    this.fileType = "excel";
  }

  async clear() {
    this.state = "none";
    this.searchState = "none";
    this.storageState = "none";
    this.storage = undefined;
    this.questions = [];
    this.questionIds = [];
    this.facets = [];
    this.filters = {};
    this.show_all = true;
    this.fileType = "excel";
    this.downloadState = "none";
    this.sorting =  {};
    this.page = 1;
    this.size = 20;
    this.total = 0;
  }

  async search() {
    this.questionStorage = [];
    this.state = "none";
  }
}
