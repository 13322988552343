import { makeAutoObservable } from "mobx";

import repository from "repositories/Stats";
import { StoreState } from "types/common";
import { OverviewResponse } from "types/stats/schema";

export default class Overview {
  state: StoreState = "none";
  data?: OverviewResponse;

  constructor() {
    makeAutoObservable(this);
  }

  async getOverviewData() {
    this.state = "pending";
    const response = await repository.getOverview();
    this.data = response.data;
    this.state = "done";
  }

  clear() {
    this.data = undefined;
    this.state = "none";
  }
}
