import {makeAutoObservable} from "mobx";
import repository from "repositories/MathRule"

import {StoreState} from "types/common";
import {Rule} from "types/rule/model"
import {MathCurriculumUnit} from "types/unit/math/model";


export default class MathRuleUnitSimulation {
  rule?: Rule;
  state: StoreState = "pending";
  detail?: MathCurriculumUnit;
  top20State: StoreState = "none";
  top20: MathCurriculumUnit[] = [];
  top20Total: number = 0;
  result: MathCurriculumUnit[] = [];
  expectedTotal: number = 0;
  page: number = 1;
  size: number = 20;
  total: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async readTop20Many(rule: Rule) {
    this.top20State = "pending";
    this.rule = rule;
    try {
      const response = await repository.simulateUnit(
        {
          rule: rule,
          page: 1,
          size: 20
        }
      )
      this.top20 = response.data.units;
      this.top20Total = response.data.total;
      this.top20State = "done";
    } catch (e) {
      this.top20State = "error";
      throw e;
    }
  }

  async readMany() {
    this.state = "pending";
    try {
      const response = await repository.simulateUnit(
        {
          rule: this.rule!,
          page: this.page,
          size: this.size
        }
      )
      this.result = response.data.units;
      this.total = response.data.total;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async setPage(page: number) {
    this.page = page;
  }

  async setSize(size: number) {
    this.page = 1;
    this.size = size;
  }

  async clearResult() {
    this.result = [];
    this.state = "pending"
    this.page = 1;
    this.size = 20;
    this.total = 0;
  }

  async setDetail(unit: MathCurriculumUnit) {
    this.detail = unit;
  }

  async clearDetail() {
    this.detail = undefined;
  }

  async clear() {
    this.state = "pending";
    this.top20State = "none";
    this.result = [];
    this.top20 = [];
    this.rule = undefined;
    this.detail = undefined;
    this.top20Total = 0;
    this.expectedTotal = 0;
    this.total = 0;
    this.page = 1;
    this.size = 20;
  }

  async search() {
    this.state = "none";
  }
}
