import { makeAutoObservable } from "mobx";
import repository from "repositories/Job"
import {Job as JobSchema} from "types/job/model";
import {StoreState} from "types/common";
import {SimpleUser} from "types/user/schema";

const JobType = {
  CFA: "CFA",
  CFR: "CFR",
  UISN: "UISN",
  UISW: "UISW",
  UISNM: "UISNM",
  UISWM: "UISWM",
  CFM: "CFM",
  CSM: "CSM",
} as const;

type JOB_TYPE = typeof JobType[keyof typeof JobType]

export default class Job {
  state: StoreState = "none";
  socket: WebSocket | null = null;
  job?: JobSchema;
  user?: SimpleUser;
  type?: JOB_TYPE;
  condition?: any;


  constructor() {
    makeAutoObservable(this);
  }

  async create() {
    try {
      const response = await repository.create(
        this.type!, {condition: this.condition}
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async setType(type: JOB_TYPE) {
    this.type = type;
  }

  async setCondition(condition: any) {
    this.condition = condition;
  }

  async clearCreatePayload() {
    this.type = undefined;
    this.condition = undefined;
  }

  async startSocket(user: SimpleUser) {
    this.state = "pending"
    this.user = user;
    this.socket = await repository.jobStatusWebsocket();
    this.socket.onerror = () => {
      setTimeout(() => this.startSocket(user), 500000)
    };
    this.state = "done"
  }

  async readSocket() {
    this.socket!.onmessage = evt => {
      if (this.job && evt.data === "null" && this.user?.email === this.job.creator_email)
        window.history.go(0)
      this.job = evt.data === "null"? undefined: JSON.parse(evt.data);
    };
  }

  async forceStartSocket() {
    if (!this.socket?.OPEN) {
      if (this.socket) this.socket?.close()
      this.socket = null;
      await this.startSocket(this.user!);
      await this.readSocket();
    }
  }

  async clear() {
    if (this.socket?.OPEN) this.socket?.close()
    this.state = "none";
    this.job = undefined;
    this.socket = null;
    this.user = undefined;
    this.type = undefined;
    this.condition = undefined;
  }
}
