import { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";

import RequestInspectionModal from "views/inspection/RequestInspectionModal";
import CompleteInspectionModal from "views/inspection/question/CompleteInspectionModal";
import CancelInspectionModal from "views/inspection/question/CancelInspectionModal";
import PostponeInspectionModal from "views/inspection/question/PostponeInspectionModal";
import CancelEachInspectionModal from "views/inspection/questionId/CancelEachInspectionModal";
import CompleteEachInspectionModal from "views/inspection/questionId/CompleteEachInspectionModal";
import PostponeEachInspectionModal from "views/inspection/questionId/PostponeEachInspectionModal";
import SimilarQuestionModal from "views/inspection/questionId/SimilarQuestionModal";
import AutoClassifiedModal from "views/inspection/questionId/AutoClassifiedModal";
import AutoClassifiedCurriculumModal from "views/inspection/curriculum/questionId/AutoClassifiedCurriculumModal";
import UnitItemModal from "views/inspection/questionId/UnitItemModal";
import QuestionItemModal from "views/inspection/questionId/QuestionItemModal";
import RegexInfoModal from "views/rule/unit/RegexInfoModal";
import RuleApplyModal from "views/rule/unit/RuleApplyModal";
import SimulationResultModal from "views/rule/unit/SimulationResultModal";
import DeleteRuleModal from "views/rule/unit/DeleteRuleModal";
import SaveRuleModal from "views/rule/unit/SaveRuleModal";
import RuleAppliedQuestionModal from "views/rule/unit/RuleAppliedQuestionModal";
import QuestionModal from "views/question/QuestionModal";
import ChangeStatusModal from "views/question/ChangeStatusModal";
import AutoClassificationModal from "views/question/AutoClassificationModal";
import SpecificModal from "views/question/SpecificModal";
import CancelInspectionModalInQuestionView from "views/question/CancelInspectionModalInQuestionView";
import QuestionDownloadModal from "views/question/QuestionDownloadModal";
import AutoClassificationSignModal from "views/AutoClassificationSignModal";
import CancelCurriculumInspectionModal from "views/inspection/curriculum/question/CancelCurriculumInspectionModal";
import CompleteCurriculumInspectionModal from "views/inspection/curriculum/question/CompleteCurriculumInspectionModal";
import PostponeCurriculumInspectionModal from "views/inspection/curriculum/question/PostponeCurriculumInspectionModal";
import CancelEachCurriculumInspectionModal from "views/inspection/curriculum/questionId/CancelEachCurriculumInspectionModal";
import CompleteEachCurriculumInspectionModal from "views/inspection/curriculum/questionId/CompleteEachCurriculumInspectionModal";
import PostponeEachCurriculumInspectionModal from "views/inspection/curriculum/questionId/PostponeEachCurriculumInspectionModal";
import SimilarCurriculumQuestionModal from "views/inspection/curriculum/questionId/SimilarCurriculumQuestionModal";
import CurriculumQuestionItemModal from "views/inspection/curriculum/questionId/CurriculumQuestionItemModal";
import RequestCurriculumInspectionModal from "views/inspection/curriculum/RequestCurriculumInspectionModal";
import DeleteCurriculumRuleModal from "views/rule/curriculum/unit/DeleteCurriculumRuleModal";
import RuleAppliedCurriculumQuestionModal from "views/rule/curriculum/unit/RuleAppliedCurriculumQuestionModal";
import MainQuestionDetailModal from "views/rule/curriculum/unit/MainQuestionDetailModal";
import RuleApplyCurriculumModal from "views/rule/curriculum/unit/RuleApplyCurriculumModal";
import SaveCurriculumRuleModal from "views/rule/curriculum/unit/SaveCurriculumRuleModal";
import SimulationResultCurriculumModal from "views/rule/curriculum/unit/SimulationResultCurriculumModal";
import SimulationResultMainQuestionModal from "views/rule/curriculum/unit/SimulationResultMainQuestionModal";
import AutoClassificationCurriculumModal from "views/question/curriculum/AutoClassificationCurriculumModal";
import CancelInspectionModalInCurriculumQuestionView from "views/question/curriculum/CancelInspectionModalInCurriculumQuestionView";
import ChangeStatusCurriculumModal from "views/question/curriculum/ChangeStatusCurriculumModal";
import CurriculumQuestionDownloadModal from "views/question/curriculum/CurriculumQuestionDownloadModal";
import CurriculumQuestionModal from "views/question/curriculum/CurriculumQuestionModal";
import CurriculumSpecificModal from "views/question/curriculum/CurriculumSpecificModal";
import RegexAlertModal from "views/rule/curriculum/unit/RegexAlertModal";

const Modals = observer(() => {
  const {
    authentication,
    similarQuestionList,
    similarMathQuestionList,
    unitSearch,
    questionSearch,
    ruleSimulation,
    mathRuleSimulation,
    mathRuleUnitSimulation,
    activeQuestion,
    activeMathQuestion,
    classificationResult,
    ruleList,
    mathRuleList,
    mathQuestionSearch,
    mathClassificationResult,
  } = useStores();

  const usersReady = useMemo(() => {
    return !!authentication.users;
  }, [authentication.users]);

  const similarQuestionDetailReady = useMemo(() => {
    return !!similarQuestionList.detailQuestion;
  }, [similarQuestionList.detailQuestion]);

  const similarCurriculumQuestionDetailReady = useMemo(() => {
    return !!similarMathQuestionList.detailQuestion;
  }, [similarMathQuestionList.detailQuestion]);

  const unitDetailReady = useMemo(() => {
    return !!unitSearch.detail;
  }, [unitSearch.detail])

  const questionDetailReady = useMemo(() => {
    return !!questionSearch.detail;
  }, [questionSearch.detail])

  const mathQuestionDetailReady = useMemo(() => {
    return !!mathQuestionSearch.detail;
  }, [mathQuestionSearch.detail])

  const ruleApplyReady = useMemo(() => {
    return !!ruleSimulation.expectedTotal && !!ruleList.payload
  }, [ruleSimulation.expectedTotal, ruleList.payload])

  const mathRuleApplyReady = useMemo(() => {
    return !!mathRuleSimulation.expectedTotal && !!mathRuleList.payload
  }, [mathRuleSimulation.expectedTotal, mathRuleList.payload])

  const ruleSimulationDetailReady = useMemo(() => {
    return !!ruleSimulation.detail
  }, [ruleSimulation.detail])

  const mathRuleSimulationDetailReady = useMemo(() => {
    return !!mathRuleSimulation.detail
  }, [mathRuleSimulation.detail])

  const mathRuleUnitSimulationDetailReady = useMemo(() => {
    return !!mathRuleUnitSimulation.detail
  }, [mathRuleUnitSimulation.detail])

  const saveRuleReady = useMemo(() => {
    return !!ruleList.payload
  }, [ruleList.payload])

  const saveMathRuleReady = useMemo(() => {
    return !!mathRuleList.payload
  }, [mathRuleList.payload])

  const classifiedQuestionReady = useMemo(() => {
    return activeQuestion.state === "done" && classificationResult.state === "done";
  }, [activeQuestion.state, classificationResult.state])

  const classifiedCurriculumQuestionReady = useMemo(() => {
    return activeMathQuestion.state === "done" && mathClassificationResult.state === "done";
  }, [activeMathQuestion.state, mathClassificationResult.state])

  const specificReady = useMemo(() => {
    return !!classificationResult.detail
  }, [classificationResult.detail])

  const mathSpecificReady = useMemo(() => {
    return !!mathClassificationResult.detail
  }, [mathClassificationResult.detail])

  return (
    <ModalsWrapper>
      {/* views/inspection modals */}
      {usersReady && <RequestInspectionModal />}
      {usersReady && <RequestCurriculumInspectionModal />}
      <CompleteInspectionModal />
      <CancelInspectionModal />
      <PostponeInspectionModal />

      {/* views/inspection/questionId modals */}
      <CancelEachInspectionModal />
      <CompleteEachInspectionModal />
      <PostponeEachInspectionModal />
      {specificReady && <AutoClassifiedModal />}
      {similarQuestionDetailReady && <SimilarQuestionModal />}
      {unitDetailReady && <UnitItemModal />}
      {questionDetailReady && <QuestionItemModal />}

      {/* views/inspection/curriculum modals */}
      {usersReady && <RequestCurriculumInspectionModal />}

      {/* views/inspection/curriculum/question modals */}
      <CancelCurriculumInspectionModal />
      <CompleteCurriculumInspectionModal />
      <PostponeCurriculumInspectionModal />

      {/* views/inspection/curriculum/questionId modals */}
      <CancelEachCurriculumInspectionModal />
      <CompleteEachCurriculumInspectionModal />
      <PostponeEachCurriculumInspectionModal />
      {mathSpecificReady && <AutoClassifiedCurriculumModal />}
      {similarCurriculumQuestionDetailReady && <SimilarCurriculumQuestionModal />}
      {mathQuestionDetailReady && <CurriculumQuestionItemModal />}

      {/* views/rule/unit modals  */}
      <RegexInfoModal />
      {ruleApplyReady && <RuleApplyModal />}
      <SimulationResultModal />
      <DeleteRuleModal />
      {saveRuleReady && <SaveRuleModal />}
      {ruleSimulationDetailReady && <RuleAppliedQuestionModal />}

      {/* views/rule/curriculum/unit modals */}
      <SimulationResultCurriculumModal />
      <DeleteCurriculumRuleModal />
      {mathRuleApplyReady && <RuleApplyCurriculumModal />}
      {saveMathRuleReady && <SaveCurriculumRuleModal />}
      {mathRuleSimulationDetailReady && <RuleAppliedCurriculumQuestionModal />}
      {mathRuleUnitSimulationDetailReady && <MainQuestionDetailModal />}
      <SimulationResultMainQuestionModal />
      <RegexAlertModal />

      {/* views/question modals */}
      {classifiedQuestionReady && <QuestionModal />}
      <ChangeStatusModal />
      <AutoClassificationModal />
      {specificReady && <SpecificModal />}
      <CancelInspectionModalInQuestionView />
      <QuestionDownloadModal />

      {/* views/question/curriculum modals */}
      {classifiedCurriculumQuestionReady && <CurriculumQuestionModal />}
      <ChangeStatusCurriculumModal />
      <AutoClassificationCurriculumModal />
      {mathSpecificReady && <CurriculumSpecificModal />}
      <CancelInspectionModalInCurriculumQuestionView />
      <CurriculumQuestionDownloadModal />

      {/* others */}
      <AutoClassificationSignModal />
    </ModalsWrapper>
  );
});

const ModalsWrapper = styled.div``;

export default Modals;
