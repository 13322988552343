import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/Question";
import {StoreState} from "types/common";
import {Question} from "types/question/model"


export default class QuestionSearch {
  state: StoreState = "none";
  textbookId?: string[];
  unitCode?: string;
  query: string = "";
  searchedQuery?: string;
  detail?: Question;
  questions?: Question[] = [];
  hasNext: boolean = true;
  total?: number;
  page: number = 1;
  size: number = 20;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(textbookId?: string[], unitCode?: string) {
    this.state = "pending";
    try {
      this.textbookId = textbookId;
      this.unitCode = unitCode;

      if (_.isUndefined(this.searchedQuery)) {
        this.questions = [];
        this.searchedQuery = "";
        this.state = "ready";
        return;
      } else this.searchedQuery = this.query;

      const response = await repository.search({
        textbookId: textbookId,
        unitCode: unitCode,
        query: this.query,
        page: this.page,
        size: this.size
      });

      this.questions = response.data.questions;
      this.total = response.data.total;
      this.hasNext = this.questions!.length !== this.total

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async nextScroll() {
    this.page = this.page + 1;
    const response = await repository.search({
      textbookId: this.textbookId,
      unitCode: this.unitCode,
      query: this.query,
      page: this.page,
      size: this.size
    });
    this.questions!.push(...response.data.questions);
    this.total = response.data.total;
    this.hasNext = this.questions!.length !== this.total;
  }


  async setQuery(query: string) {
    this.query = query;
  }

  async setDetail(unit: Question) {
    this.detail = unit;
  }

  async reset() {
    this.query = "";
    this.searchedQuery = undefined;
  }


  async clear() {
    this.state = "none";
    this.total = undefined;
    this.textbookId = undefined;
    this.unitCode = undefined;
    this.questions = [];
    this.detail = undefined;
    this.query = "";
    this.searchedQuery = undefined;
    this.hasNext = true;
    this.page = 1;
    this.size = 20;
  }

  async search() {
    this.page = 1;
    this.state = "none";
  }
}
