import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";

import {
  CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING,
  CLASSIFICATION_TYPE_ENG_KOR_MAPPING,
  INSPECTION_STATUS_ENG_KOR_MAPPING
} from "constant";

import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";

import {engToKor, makePage, scoreToPercent} from "utils";

const QuestionModalContent = observer(() => {
  const { classificationResult, questionList } = useStores();

  const question = useMemo(() => {
    return classificationResult.detail?.model;
  }, [classificationResult.detail?.model]);

  return (
    <QuestionModalContentStyle>
      <Row style={{ transform: "translateY(-2px)" }}>
        <Item  style={{ alignItems: "center" }}>
          <Field>문항 ID</Field>
          <Value style={{ fontWeight: "500" }}>{question._id}</Value>
        </Item>

        <Item style={{ alignItems: "center" }}>
          <Field>상태</Field>
          <Value>
            <InspectionStatusText status={question.inspection_status}>
              {engToKor(
                INSPECTION_STATUS_ENG_KOR_MAPPING,
                question!.inspection_status
              )}
            </InspectionStatusText>
            <Badge>{engToKor(CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING, question!.classification_consistency)}</Badge>
            {
              question.modifier_email &&
              <em style={{ color: "#484848", opacity: "0.8" }}>({question.modifier_name})</em>
            }
          </Value>
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item>
          <Field>시험지 ID</Field>
          <Value>
            {question!.pocCode}
          </Value>
        </Item>

        <Item>
          {
            question.checker_type &&
            <>
              <Field>최종분류근거</Field>
              <Value>
                <em className="info" style={{ marginRight: "4px" }}>
                  {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question!.checker_type)}
                </em>
                <em style={{ color: "#484848", opacity: "0.8" }}>
                  {question.score && `(${scoreToPercent(question.score)})`}
                </em>
              </Value>
            </>
          }
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item>
          <Field>시험정보</Field>
          <Value>
            {questionList.questionInfo(question)}
          </Value>
        </Item>

        <Item>
          <Field>페이지</Field>
          <Value style={{ fontWeight: "500" }}>
            {makePage(question!.page)}
          </Value>
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item style={{ margin: "0" }}>
          <Field>단원 ID</Field>
          <Value>{question!.unitCode}</Value>
        </Item>
      </Row>

      <Row style={{ marginTop: "8px" }}>
        <Item style={{ margin: "0" }}>
          <Field>단원명</Field>
          <Value>{question!.unit_name}</Value>
        </Item>
      </Row>

      <LevelsWrapper className="scrollbar">
        <Levels level1={question!.level1} level2={question!.level2} level3={question!.level3} />
      </LevelsWrapper>
    </QuestionModalContentStyle>
  );
});

const QuestionModalContentStyle = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 16px;

  min-height: 20px;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-shrink: 0;
  min-height: 20px;

  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  width: 112px;
  flex-shrink: 0;

  color: ${(props) => props.theme.text.FOCUS};
`;
const Value = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.text.BLACK};

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }
`;
const Badge = styled.div`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const LevelsWrapper = styled.div`
  margin-top: 16px;
  max-height: 600px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.DIM};
`;

export default QuestionModalContent;
