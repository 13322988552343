import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { useModal } from "hooks/useModal";
import { MaruModal } from "hooks/useModal";
import {useErrorHandler} from "hooks/useErrorHandler";

import { ReactComponent as FilterIcon } from "assets/icons/icon_filter_blue.svg";
import {
  CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING,
  CLASSIFICATION_TYPE_ENG_KOR_MAPPING,
  INSPECTION_STATUS_ENG_KOR_MAPPING,
  ROUTERS
} from "constant";

import {ClassificationCandidateResult} from "types/question/model";
import {QuestionReadManyRequest} from "types/question/schema";

import Levels from "components/Levels";
import InspectionStatusText from "components/InspectionStatusText";
import Button from "components/Button";

import {engToKor, makePage, scoreToPercent, utcToLocal} from "utils";


const QuestionModal = observer(() => {
  const {classificationResult, activeQuestion, questionList, job, activeUnit} = useStores();
  const modalId = "classifiedQuestionModal";
  const modal = useModal();
  const handler = useErrorHandler();

  const question = useMemo(() => {
    return activeQuestion.question!;
  }, [activeQuestion.question])

  const clear = async () => {
    await activeQuestion.clear();
    await classificationResult.clear();
    await job.clearCreatePayload();
  }

  const result: ClassificationCandidateResult[] = useMemo(() => {
    const _result = []
    if (classificationResult.result?.rule) _result.push(...classificationResult.result?.rule)
    if (classificationResult.result?.question) _result.push(...classificationResult.result?.question)
    if (classificationResult.result?.unit) _result.push(...classificationResult.result?.unit)
    return _result
  }, [classificationResult.result])

  //* Event methods
  const handleModalOpenClick = async (result: ClassificationCandidateResult) => {
    await classificationResult.setDetail(result)
    modal.open("specificModal");
  }

  const handleAutoClassificationButtonClick = async () => {
    await job.setType("CFA");
    const condition: QuestionReadManyRequest = questionList.jobPayload(question._id);
    await job.setCondition(condition);
    if (
      await handler.handle(job.create())
    ) return;
    await job.forceStartSocket();
  };

  const handleSearchUnitButtonClick = async () => {
    modal.close("classifiedQuestionModal");
    await activeUnit.clear();
    window.open(ROUTERS.question.textbookQuestionId(question._id), "_blank");
  };

  const isClassified = useMemo(() => {
    return question.inspection_status !== "none" || question.classification_status === "completed"
  }, [question.classification_status, question.inspection_status])

  const isFailed = useMemo(() => {
    return question.classification_status === "failed"
  }, [question.classification_status])

  
  return (
    <MaruModal
      id={modalId}
      size="xl"
      onClose={clear}
      customWidth="1184px"
    >
      <Heading className="block-select" id="draggable-handle">
        문항 자세히 보기
      </Heading>

      <Content>
        <QuestionModalContentStyle>
          <Row>
            <Item style={{ alignItems: "center" }}>
              <Field>문항 ID</Field>
              <Value style={{ fontWeight: "500" }}>{question._id}</Value>
            </Item>

            <Item style={{ alignItems: "center" }}>
              <Field>상태</Field>
              <Value>
                {isClassified ? (
                  <>
                    <InspectionStatusText status={question.inspection_status}>
                      {engToKor(INSPECTION_STATUS_ENG_KOR_MAPPING, question.inspection_status)}
                    </InspectionStatusText>
                    {
                      question?.classification_consistency &&
                      <Badge>{engToKor(CLASSIFICATION_CONSISTENCY_ENG_KOR_MAPPING, question!.classification_consistency)}</Badge>
                    }
                  </>
                ) : (
                  <span style={{ color: "#999 " }}>{isFailed ? "분류실패" : "분류대기"}</span>
                )}
              </Value>
            </Item>
          </Row>

          <Row style={{ marginTop: "4px" }}>
            <Item>
              <Field>시험지 ID</Field>
              <Value>
                {question.pocCode}
              </Value>
            </Item>
              <Item>
              {
                isClassified &&
                <>
                  <Field>최종분류근거</Field>
                  <Value>
                    <em className="text-info">{engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, question.checker_type!)}</em>
                    <em style={{ marginLeft: "4px", color: "#484848", opacity: "0.8" }}>
                      {question.score && `(${scoreToPercent(question.score)})`}
                    </em>
                  </Value>
                </>
              }
              </Item>
          </Row>

          <Row style={{ marginTop: "4px" }}>
            <Item>
              <Field>시험정보</Field>
              <Value>
                {questionList.questionInfo(question)}
              </Value>
            </Item>
              <Item>
              {
                isClassified && question.modifier_email &&
                  <>
                    <Field>최종수정</Field>
                    <Value>{question.modifier_name} ({utcToLocal(question.modified_dt, "YYYY-MM-DD HH:mm")})</Value>
                  </>
              }
              </Item>
          </Row>

          {
            question.classification_textbookId &&
            <Row style={{ marginTop: "4px" }}>
              <Item style={{ margin: "0" }}>
                <Field>교과서 ID</Field>
                <Value>
                  {question.classification_textbookId}
                </Value>
              </Item>
            </Row>
          }

          {
            isClassified &&
            <Row style={{ marginTop: "4px" }}>
              <Item style={{ margin: "0" }}>
                <Field>교과서</Field>
                <Value>{question.textbookSb}</Value>
              </Item>
            </Row>
          }
          {
            question.unit_name &&
            <Row style={{ marginTop: "4px" }}>
              <Item style={{ margin: "0" }}>
                <Field>최종 분류 결과</Field>
                <Value>
                  {question.unit_name}
                </Value>
              </Item>
            </Row>
          }
          {
            !!result.length &&
            <Row style={{ marginTop: "8px" }}>
              <Item style={{ marginLeft: "0" }}>
                <Field>자동 분류결과</Field>
                <Value style={{ flexGrow: "1", display: "flex" }}>
                  <TableWrapper>
                    <TableHeader>
                      <TableCell style={{ width: "80px" }}>순위</TableCell>
                      <TableCell style={{ width: 572 }}>결과단원</TableCell>
                      <TableCell style={{ width: "160px" }}>결과페이지</TableCell>
                      <TableCell style={{ width: "100px" }}>검색결과</TableCell>
                    </TableHeader>

                    <TableBody className="scrollbar">
                      {
                        result.map((r, i) =>
                          <TableItem>
                            <TableCell style={{ width: "80px" }}>{i+1}</TableCell>
                            <TableCell style={{ display: "block", width: 572 }}>{r.unit_name}</TableCell>
                            <TableCell style={{ width: "160px" }}>{makePage(r.page)}</TableCell>
                            <TableCell style={{ width: "100px" }}>
                              <em onClick={async () => await handleModalOpenClick(r)}>
                                {engToKor(CLASSIFICATION_TYPE_ENG_KOR_MAPPING, r.classification_type!)}
                              </em>
                              <em>
                                {`(${scoreToPercent(r.score)})`}
                              </em>
                            </TableCell>
                          </TableItem>
                        )
                      }
                    </TableBody>
                  </TableWrapper>

                </Value>
              </Item>
            </Row>
          }

          <SubHeading className="block-select">
            문항내용
            {question.is_abnormal && <em>문제 수정 필요</em>}
          </SubHeading>
          <LevelsWrapper className="scrollbar">
            <Levels level1={question.level1} level2={question.level2} level3={question.level3} />
          </LevelsWrapper>
        </QuestionModalContentStyle>

        <ButtonWrapper>
          <SearchUnitButton onClick={handleSearchUnitButtonClick}>단원검색</SearchUnitButton>
          {!isClassified && (
            <AutoClassificationButton onClick={handleAutoClassificationButtonClick}>
              <FilterIcon />
              자동분류
            </AutoClassificationButton>
          )}
        </ButtonWrapper>
      </Content>
    </MaruModal>
  );
});

const Heading = styled.h1`
  margin-bottom: 16px;
  padding: 16px 0;

  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.text.BLACK};
`;

const Content = styled.div`
  position: relative;
  border-radius: 4px;
`;

const QuestionModalContentStyle = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0 16px;

  min-height: 25px;
`;
const Item = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1;
  flex-shrink: 0;
  min-height: 20px;

  font-size: 13px;

  &:last-child {
    margin-left: 64px;
  }
`;
const Field = styled.div`
  width: 112px;
  flex-shrink: 0;

  color: ${(props) => props.theme.text.FOCUS};
  line-height: normal;
`;
const Value = styled.div`
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.text.BLACK};
  line-height: normal;

  > em.text-info {
    color: ${(props) => props.theme.text.INFO} !important;
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS} !important;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER} !important;
  }
`;
const Badge = styled.div`
  margin: 0 4px;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #eaeaea;

  font-size: 10px;
  color: ${(props) => props.theme.text.GREY};
`;

const TableWrapper = styled.div`
  flex-grow: 1;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.MAIN};

  font-size: 13px !important;
  color: ${(props) => props.theme.text.BLACK} !important;
`;
const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  border-bottom: 2px solid black;
`;
const TableBody = styled.div`
  height: 200px;
  padding: 0 16px;
`;
const TableItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  border-bottom: 1px solid ${(props) => props.theme.border.MAIN};
`;
const TableCell = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1rem;

  > em:first-child {
    margin-right: 4px;
    color: ${(props) => props.theme.text.INFO};
    cursor: pointer;
  }

  > em:last-child {
    color: ${(props) => props.theme.text.MAIN};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: -4px;
  right: 60px;

  display: flex;
`;

const SearchUnitButton = styled(Button)`
  height: 32px;
  margin-right: 8px;
  padding: 0 8px;
`;
const AutoClassificationButton = styled(Button)`
  display: flex;
  align-items: center;

  height: 32px;
  padding: 0 8px;

  border: 2px solid ${(props) => props.theme.border.INFO};
  background-color: white;

  color: ${(props) => props.theme.text.INFO};

  svg {
    transform: translateY(1px);
  }
`;

const SubHeading = styled.h2`
  margin: 12px 0;

  font-size: 15px;
  font-weight: 500;
  color: ${(props) => props.theme.text.BLACK};
  line-height: 1rem;

  em {
    margin-left: 8px;
    font-size: 14px;
    color: ${(props) => props.theme.text.IDLE};
  }
`;
const LevelsWrapper = styled.div`
  max-height: 280px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.border.DIM};
`;

export default QuestionModal;
