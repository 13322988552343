import _ from "lodash";
import {makeAutoObservable} from "mobx";
import repository from "repositories/Question";
import {Facet, StoreState} from "types/common";
import {Question} from "types/question/model"
import {QuestionFilterOptions, QuestionSemanticsSearchRequest} from "../types/question/schema";


const QuestionSimulationStorage = {
  SIMULATION: "simulation"
} as const;

type QUESTION_SIMULATION_STORAGE = typeof QuestionSimulationStorage[keyof typeof QuestionSimulationStorage]


export default class QuestionSearch {
  facetsFields: string[] = [
    "course_name.keyword",
    "subjectNm.keyword",
    "textbookSb.keyword",
    "publisher.keyword",
    "testTypeNm.keyword",
    "year",
    "revised_year",
    "grade_semester_test.keyword"
  ];
  state: StoreState = "none";
  downloadState: StoreState = "none";
  level3: string = "";
  searchedLevel3?: string;
  storage?: QUESTION_SIMULATION_STORAGE;
  storageState: StoreState = "none";
  facets: Facet[] = [];
  questions?: Question[] = [];
  fileType: string = "excel";
  filters: QuestionFilterOptions = {};
  searchedPayload?: QuestionSemanticsSearchRequest;
  hasNext: boolean = true;
  total?: number;
  page: number = 1;
  size: number = 20;

  constructor() {
    makeAutoObservable(this);
  }


  async setFilterStorage(storage: QUESTION_SIMULATION_STORAGE) {
    localStorage.setItem(`${storage}Filter`, JSON.stringify(this.filters))
  }

  async getFilterStorage(storage: QUESTION_SIMULATION_STORAGE) {
    const item = localStorage.getItem(`${storage}Filter`)
    return item ? JSON.parse(item) : {}
  }

  async getFilterFromStorage(storage: QUESTION_SIMULATION_STORAGE, key: string) {
    const filter = await this.getFilterStorage(storage);
    if (_.isEmpty(filter)) return;
    if (Object.keys(filter).some(k => k === key)) return filter[key]
    else return;
  }

  async readyStorage(storage?: QUESTION_SIMULATION_STORAGE, clear?: boolean) {
    if (storage) {
      this.storageState = "pending";
      this.storage = storage
      if (!clear) {
        this.filters = await this.getFilterStorage(storage);
      }
      this.storageState = "done";
    }
  }


  async readMany(facets?: string[]) {
    try {

      if (facets) this.facetsFields = facets;

      await this.readFacets();

      if (this.storage) {
        await this.setFilterStorage(this.storage);
      }

      if (_.isUndefined(this.searchedLevel3)) {
        this.questions = [];
        this.searchedLevel3 = "";
        this.state = "ready";
        return;
      } else this.searchedLevel3 = this.level3;

      this.state = "pending";

      this.searchedPayload = {
        level3: this.level3,
        filters: this.filters
      }

      const response = await repository.readSemanticsMany(this.searchedPayload);

      this.questions = response.data;
      this.total = this.questions!.length;
      this.hasNext = this.questions!.length !== this.total

      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async readFacets() {
    try {
      const response = await repository.readSemanticsFacets({fields: this.facetsFields});
      this.facets = response.data.facets
    } catch (e) {
      throw e;
    }
  }

  getFacet(field: string) {
    const facet = this.facets.filter(f => field === f.field)[0]
    if (!facet) return []
    return facet.facet
  }

  async pushFilter(field: string, item?: any) {
    if (_.isUndefined(item) || _.isNull(item) || item === "") {
      delete this.filters[field];
      return;
    }
    this.filters[field] = item["value"]?item["value"]:item;
  }

  async popFilter(field: string) {
    delete this.filters[field];
  }

  getFilter(field: string) {
    return !_.isUndefined(this.filters[field]) ? this.filters[field] : undefined;
  }

  setText(text: string, field: string) {
    if (!text) {
      delete this.filters[field];
      return;
    }
    this.filters[field] = text;
  }

  async setLevel3(level3: string) {
    this.level3 = level3;
  }

  async download() {
    this.downloadState = "pending";
    try {
      const response = await repository.downloadSemantics(
        this.fileType,
        this.searchedPayload!
      );
      this.downloadState = "none"
      return response;
    } catch (e) {
      this.downloadState = "error";
      throw e;
    }
  }

  async reset() {
    this.filters = {};
    this.page = 1;
    this.level3 = "";
    this.searchedLevel3 = undefined;
  }

  async setFileType(fileType: string) {
    this.fileType = fileType;
  }

  async clearFileType() {
    this.fileType = "excel";
  }

  async clear() {
    this.state = "none";
    this.total = undefined;
    this.storageState = "none";
    this.downloadState = "none";
    this.storage = undefined;
    this.fileType = "excel";
    this.questions = [];
    this.level3 = "";
    this.searchedLevel3 = undefined;
    this.hasNext = true;
    this.facets = [];
    this.filters = {};
    this.searchedPayload = undefined;
    this.page = 1;
    this.size = 20;
  }

  async search() {
    this.page = 1;
    this.state = "none";
  }
}
