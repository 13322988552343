import {makeAutoObservable} from "mobx";
import repository from "repositories/MathRule"
import {StoreState} from "types/common";
import {Rule} from "types/rule/model"
import {
  RuleCreate,
  RuleSortingOptions,
  RuleUpdate
} from "types/rule/schema";
import {MathCurriculumUnit} from "types/unit/math/model";
import {removeNone} from "utils";


export default class MathRuleList {
  state: StoreState = "none";
  rules: Rule[] = [];
  createMode?: boolean = true;
  payload?: RuleCreate | RuleUpdate;
  rule?: Rule;
  searchMode: "textbook" | "question" = "textbook";
  sorting: RuleSortingOptions = {
    id: "desc"
  };
  page: number = 1;
  size: number = 20;
  total: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  async readMany(unitCode: string)
  {
    this.state = "pending";
    try {
      const response = await repository.readMany({
        unitCode: unitCode,
        sorting: this.sorting,
        page: this.page,
        size: this.size
      })
      this.rules = response.data.rules;
      this.total = response.data.total;
      this.createMode = !this.rules.length;
      this.state = "done";
    } catch (e) {
      this.state = "error";
      throw e;
    }
  }

  async create(unitCode: string, payload: RuleCreate) {
    try {
      const response = await repository.create(unitCode, payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async delete(ruleId: string) {
    try {
      await repository.delete(ruleId);
    } catch (e) {
      throw e;
    }
  }

  async update(ruleId: string, payload: RuleUpdate) {
    try {
      const response = await repository.update(ruleId, payload);
      return response.data;
    } catch (e) {
      throw e;
    }
  }

  async setSorting(field: string) {
    this.sorting[field] = this.sorting[field]? this.sorting[field] === "desc" ? "asc" : undefined: "desc"
  }

  getSorting(field: string) {
    return this.sorting[field];
  }

  async setPayload(payload?: RuleCreate | RuleUpdate) {
    if (payload) removeNone(payload);
    this.payload = payload;
  }

  async setCreateMode(mode: boolean) {
    this.createMode = mode;
  }

  async setRule(rule?: Rule) {
    this.rule = rule;
  }

  async clearRule() {
    this.rule = undefined;
  }

  async setPage(page: number) {
    this.page = page;
  }

  async setSearchMode(mode: "textbook"| "question") {
    this.searchMode = mode;
  }

  getRulesExceptUnits(units?: MathCurriculumUnit[]) {
    if (!units) return [];
    const rules = this.rules.filter(r =>
      !units
         .map(u => u.rules)
         .reduce((acc, curr) => acc!.concat(curr!), [])!
         .map(r => r.id)
         .includes(r!.id)
    );
    return rules;
  }

  async clear() {
    this.state = "none";
    this.rules = [];
    this.createMode = true;
    this.payload = undefined;
    this.sorting = {
      id: "desc"
    }
    this.rule = undefined;
    this.searchMode = "textbook"
    this.page = 1;
    this.size = 20;
    this.total = 0;
  }

  async search() {
    this.state = "none";
  }
}
