import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import useStores from "hooks/useStores";
import { MaruModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

import ModalAddon from "components/ModalAddon";

const PostponeCurriculumInspectionModal = observer(() => {
  const {mathQuestionList, activeMathCurriculumUnit} = useStores();

  const modalId = "postponeCurriculumInspectionModal";
  const toast = useToast();
  const handler = useErrorHandler();

  //* Event methods
  const handleOkayClick = async () => {
    if (mode === 3) return;
    if (
      await handler.handle(
        mathQuestionList.updateMany(
          {
            question_ids: mathQuestionList.questionStorage.map(q => q._id),
            values: {
              inspection_status: "hold"
            }
          }
        )
      )
    ) return;
    toast.fireToast({
      type: "postpone",
      content: toastMessage.postpone.saveInspection,
      duration: 5000,
    });
    await activeMathCurriculumUnit.reSet();
    await mathQuestionList.search();
  };

  const mode = useMemo(() => {
    if (mathQuestionList.questionStorage.every(u => u.inspection_status === "waiting"))
      return 1
    if (mathQuestionList.questionStorage.every(u => u.inspection_status === "hold"))
      return 3
    return 2
    // eslint-disable-next-line
  }, [mathQuestionList.questionStorage.length])

  return (
    <MaruModal
      id={modalId}
      title="검수보류"
      buttonText="확인"
      warning={
        mode === 2 && (
          <>
            보류 후에는 결과
            <em style={{ color: "#e9397a" }}> 복원이 불가</em>합니다.
          </>
        )
      }
      withCancel={mode !== 3}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {mode === 1 && <OnlyUnchecked count={mathQuestionList.questionStorage.length}/>}
        {mode === 2 && <Mixed />}
        {mode === 3 && <OnlyChecked />}
      </ModalInnerPanel>

      <ModalAddon mode="postpone" />
    </MaruModal>
  );
});

interface PostponeCurriculumInspectionProps {
  count: number;
}

const OnlyUnchecked = ({count}: PostponeCurriculumInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b>을 <b>검수보류</b> 처리합니다.
  </Paragraph>
);
const Mixed = () => (
  <Paragraph>
    선택한 문항 중 <em className="text-success">검수완료</em> 처리한
    문항이 있습니다. <br />
    이대로 검수보류로 상태를 변경하시겠습니까?
  </Paragraph>
);
const OnlyChecked = () => (
  <Paragraph>
    이미 <b>검수보류</b> 처리된 문항입니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO};
  }
`;

export default PostponeCurriculumInspectionModal;
