import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";

const RegexAlertModal = observer(() => {
  const modalId = "regexAlertModal";

  return (
    <MaruModal
      id={modalId}
      title="정규표현식 오류"
      customWidth="500px"
    >
      <ModalInnerPanel>
        <Paragraph>
          검색할 수 없는 정규표현식이 포함되어 있습니다. <br />
          정규표현식을 수정해보세요.
        </Paragraph>
      </ModalInnerPanel>
    </MaruModal>
  );
});

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO};
  }
`;

export default RegexAlertModal;
