import {useMemo} from "react";
import {observer} from "mobx-react-lite";
import styled from "styled-components";

import { MaruModal } from "hooks/useModal";
import useStores from "hooks/useStores";
import { useToast } from "hooks/useToast";
import {useErrorHandler} from "hooks/useErrorHandler";

import { toastMessage } from "messages";

import ModalAddon from "components/ModalAddon";


const CancelEachCurriculumInspectionModal = observer(() => {
  const {
    similarMathQuestionList,
    mathCurriculumUnitSelect,
    mathQuestionList,
    activeMathCurriculumUnit,
    activeMathQuestion
  } = useStores();
  const modalId = "cancelEachCurriculumInspectionModal";
  const toast = useToast();
  const handler = useErrorHandler();

  const handleOkayClick = async () => {
    if (mode === 3) return;
    if (!mathCurriculumUnitSelect.selected) return;
    if (
      await handler.handle(
        mathQuestionList.updateMany(
          {
            question_ids: similarMathQuestionList.questionStorage.map(q => q._id),
            values: {
              inspection_status: "waiting"
            }
          }
        )
      )
    ) return;
    toast.fireToast({
      type: "fail",
      content: toastMessage.fail.cancelInspection,
      duration: 5000,
    });
    await activeMathCurriculumUnit.setCurriculumDetailCode(mathCurriculumUnitSelect.selected!.unitCode)
    await mathCurriculumUnitSelect.clear();
    await similarMathQuestionList.clear();
    await activeMathQuestion.clear();
    await activeMathCurriculumUnit.clear();
  };

  const mode = useMemo(() => {
    if (similarMathQuestionList.questionStorage.some(u => u.inspection_status === "waiting"))
      return 3
    else return 1
    // eslint-disable-next-line
  }, [similarMathQuestionList.questionStorage.length])

  return (
    <MaruModal
      id={modalId}
      title="검수취소"
      buttonColor={mode !== 3 ? "#e9397a" : ""}
      buttonText={mode === 3 ? "확인" : "검수취소"}
      warning={
        <>
          취소 후에는 결과
          <em style={{ color: "#e9397a" }}> 복원이 불가</em>합니다.
        </>
      }
      withCancel={mode !== 3}
      onOkayClick={handleOkayClick}
    >
      <ModalInnerPanel>
        {mode === 1 && <OnlyChecked count={similarMathQuestionList.questionStorage.length}/>}
        {mode === 3 && <OnlyUnchecked />}
      </ModalInnerPanel>

      <ModalAddon mode="fail" />
    </MaruModal>
  );
});

interface CancelEachCurriculumInspectionProps {
  count: number;
}

const OnlyChecked = ({count}: CancelEachCurriculumInspectionProps) => (
  <Paragraph>
    선택한 <b>{count}개 문항</b>을 <em className="text-danger">검수취소</em>{" "}
    처리합니다.
  </Paragraph>
);

const OnlyUnchecked = () => (
  <Paragraph>
    검수대기 문항에 대해서는 <em className="text-danger">검수취소</em>를 할 수
    없습니다.
  </Paragraph>
);

const ModalInnerPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 16px;
  padding: 24px;

  border-radius: 4px;
  background: ${(props) => props.theme.background.DIM};
`;

const Paragraph = styled.p`
  font-size: 13px;
  color: #666666;
  text-align: center;
  line-height: 1.5rem;

  > b {
    font-weight: 500;
    color: ${(props) => props.theme.text.BLACK};
  }

  > em {
    font-weight: 500;
  }

  > em.text-danger {
    color: ${(props) => props.theme.text.DANGER};
  }

  > em.text-success {
    color: ${(props) => props.theme.text.SUCCESS};
  }

  > em.text-info {
    color: ${(props) => props.theme.text.INFO};
  }
`;

export default CancelEachCurriculumInspectionModal;
